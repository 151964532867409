import {useEffect, useState, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import './faculty.css'
import 'primereact/resources/themes/tailwind-light/theme.css'
import 'primereact/resources/primereact.min.css'
import clsx from 'clsx'
import {
  useAddFacultyMutation,
  useLazyFacultyQuery,
  useLazyGetCertificationQuery,
  useLazyPublisherQuery,
  useLazyGetFacultyDetailsQuery,
} from '../../Api/allApi'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {setFaculty} from '../../redux/FacultySlice'
import Swal from 'sweetalert2'
import moment from 'moment'
import CreatableSelect from 'react-select'
import {setCertification, setPublisher} from '../../redux/achivementSlice'
import {useSelector} from 'react-redux'
import {Calendar} from 'primereact/calendar'
import {Button} from 'primereact/button'
import AddIcon from '@mui/icons-material/Add'
import {Steps} from 'primereact/steps'
import {Divider} from 'primereact/divider'

import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import {InputSwitch, InputSwitchChangeEvent} from 'primereact/inputswitch'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown, DropdownChangeEvent} from 'primereact/dropdown'

import {Nullable} from 'primereact/ts-helpers'
import {Checkbox} from 'primereact/checkbox'
// import 'primereact/resources/themes/arya-blue'

const Faculty = Yup.object().shape({
  firstName: Yup.string().required('firstName is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('lastName is required'),
  title: Yup.string().required('title is required'),
  highest_education: Yup.string().required('highest education is required'),
  years_of_experience: Yup.string().required('years_of_experience is required'),
  about: Yup.string().required('about is required'),
  facultyEnrollementId: Yup.string()
    .required('facultyEnrollementId is required')
    .matches(/^[a-zA-Z0-9]*$/, 'faculty Enrollement Id should only contain A-Z or 0-9 characters'),
  dob: Yup.date().required('Date of birth is required'),
  gender: Yup.string().required('Gender is required'),
  bloodGroup: Yup.string().required('Blood group is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  countryCode: Yup.string().required('Country Code is required'),
  contactNo: Yup.string()
    .matches(/^[0-9]+$/, 'Contact number must contain only digits')
    .min(10, 'Contact number must be at least 10 characters')
    .max(10, 'Contact number must not exceed 15 characters')
    .required('Contact number is required'),
})

// export function YearPicker() {
//   const [selectedYear, setSelectedYear] = useState<Date | null>(null)
//   const handleYearChange = (date: Date | null) => {
//     setSelectedYear(date)
//   }
//   return (
//     <div>

//     </div>
//   )
// }
/* eslint-disable jsx-a11y/anchor-is-valid */
export default function AddFaculty({id}) {
  // get data from redux
  const {publisher} = useSelector((state: any) => state.achievementSlice)
  const {certification} = useSelector((state: any) => state.achievementSlice)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const today = new Date().toISOString().split('T')[0]
  // const today = new Date()
  const [loading, setLoading] = useState(false)
  const articleImg = useRef<HTMLInputElement>(null)
  const credentialRefImg = useRef<HTMLInputElement>(null)
  const [array, setArray]: any = useState({
    articles: {
      title: '',
      articleImg: '',
      articleLink: '',
      articleDate: null,
      shortDescr: '',
    },
    awards: {
      title: '',
      awardYear: null,
      shortDescr: '',
    },
    certification: {
      certificationName: '',
      issuerDetails: '',
      issuerName: '',
      issueDate: null,
      certificateDoesNotExpire: false,
      expirationDate: '',
      credentialImg: '',
      credentialID: '',
      shortDescr: '',
      credentialURL: '',
    },
    publication: {
      mediumIcon: '',
      publicationDate: null,
      publicationLink: '',
      shortDescr: '',
      title: '',
    },
    facultyArticles: [],
    facultyAwards: [],
    facultyCertification: [],
    facultyPublication: [],
  })
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    title: '',
    highest_education: '',
    years_of_experience: '',
    facultyEnrollementId: '',
    about: '',
    middleName: '',
    dob: '',
    gender: '',
    bloodGroup: '',
    email: '',
    countryCode: '',
    contactNo: '',
  })
  // const [filterData, setFilterData]: any = useState({
  //   stream: [],
  //   streamId: 0,
  //   selectedStream: {value: '', label: ''},
  //   class: [],
  //   classId: 0,
  //   selectedClass: {value: '', label: ''},
  //   semester: [],
  //   semesterId: 0,
  //   selectedSemester: {value: '', label: ''},
  //   subject: [],
  //   subjectId: 0,
  // })
  const [index, setIndex]: any = useState({
    publication: null,
    Articles: null,
    certificate: null,
    Awards: null,
  })
  const [buttonValue, SetButtonValue]: any = useState({
    publication: 'Add Item',
    Articles: 'Add Article',
    certificate: 'Add Certification',
    Awards: 'Add Award',
  })
  const [list, setList] = useState({
    publisher: [],
    certification: [],
  })
  const [flag, setFlag] = useState({
    facultyArticles: false,
    facultyAwards: false,
    facultyCertification: false,
    facultyPublication: false,
    story: false,
    publication: false,
    Articles: false,
    certificate: false,
    Awards: false,
  })

  // onchange for publication
  const onPublicationChange: any = (e) => {
    const currentDate: any = new Date()
    if (e.target.name === 'publicationDate') {
      const selectedDate: any = moment(e.target.value).format('YYYY-MM-DD')
      console.log(selectedDate)
      if (selectedDate > moment(currentDate).format('YYYY-MM-DD')) {
        // Handle the validation error, for example, display an error message
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "You can't select publication data grater than today's date",
          showConfirmButton: false,
          timer: 1500,
        })
      } else {
        setArray({
          ...array,
          publication: {
            ...array.publication,
            publicationDate: e.value,
          },
        })
      }
    } else {
      setArray({
        ...array,
        publication: {
          ...array?.publication,
          [e.target.name]: e.target?.value,
        },
      })
    }
    setFlag({...flag, facultyPublication: false})
  }
  // onchange for Articles
  const onArticlesChange: any = (e) => {
    if (e.target.name === 'articleDate') {
      setArray({
        ...array,
        articles: {
          ...array?.articles,
          [e.target.name]: e.target.value,
        },
      })
    } else {
      setArray({
        ...array,
        articles: {
          ...array?.articles,
          [e.target.name]: e.target?.value,
        },
      })
    }
    setFlag({...flag, facultyArticles: false})
  }
  // onchange for certification
  const onCertificationChange: any = (e) => {
    if (e.target.name === 'expirationDate' && e.target.name === 'issueDate') {
      const updatedArray = {...array}

      const selectedData = moment(e.target?.value).format('YYYY-DD-MM')
      updatedArray.certification[e.target.name] = e.target?.value

      setArray(updatedArray)
    }

    setArray({
      ...array,
      certification: {
        ...array?.certification,
        [e.target.name]: e.target?.value,
      },
    })
    setFlag({...flag, facultyCertification: false})
  }
  // onchange for Awards
  const onAwardsChange: any = (e) => {
    setArray({
      ...array,
      awards: {
        ...array?.awards,
        [e.target.name]: e.value ? e.value : e.target?.value,
      },
    })
    setFlag({...flag, facultyAwards: false})
  }
  const [images, setImages]: any = useState({})

  // change image to base64
  const onHandleChangeFile: any = (e: any) => {
    // console.log(articleImg)
    const file = e.target.files[0]
    // console.log(e.target.name)
    // console.log(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      setImages({
        ...images,
        [e.target.name]: base64String,
      })
      if (e.target.name === 'articleImg') {
        setArray({
          ...array,
          articles: {
            ...array?.articles,
            [e.target.name]: base64String,
          },
        })
      }
      if (e.target.name === 'credentialImg') {
        setArray({
          ...array,
          certification: {
            ...array?.certification,
            [e.target.name]: base64String,
          },
        })
      }
      if (e.target.name === 'issueByImg') {
        setArray({
          ...array,
          certification: {
            ...array?.certification,
            [e.target.name]: base64String,
          },
        })
      }
      if (e.target.name === 'mediumIcon') {
        setArray({
          ...array,
          publication: {
            ...array?.publication,
            [e.target.name]: base64String,
          },
        })
      }
    }
    if (file) {
      reader.readAsDataURL(file)
      setImages({
        ...images,
        [e.target.name]: file,
      })
    }
  }
  // filter api
  // const [filterTrigger, filterResult] = useLazyAcademicFilterQuery()
  // const {isSuccess: isFilter, isFetching: isNotFilter} = filterResult
  // add Faculty
  const [addFaculty, result] = useAddFacultyMutation()
  const {isSuccess, isLoading, isError, error} = result
  // all faculty trigger
  const [facultyTrigger, facultyResult] = useLazyFacultyQuery()
  const {isSuccess: isASuccess, isFetching} = facultyResult
  // publisher api
  const [PublisherTrigger, PublisherResult] = useLazyPublisherQuery()
  const {isSuccess: isPublisher, isFetching: isNotPublisher} = PublisherResult
  // certificate api
  const [CertificationTrigger, CertificateResult] = useLazyGetCertificationQuery()
  const {isSuccess: isCertification, isFetching: isNotCertification} = CertificateResult

  const [facultyDetailsTrigger, facultyDetailsResult] = useLazyGetFacultyDetailsQuery() // Fetch faculty details
  const {isSuccess: isFacultyDetailsSuccess, isFetching: isFacultyDetailsFetching} =
    facultyDetailsResult // Get API query status
  // publisher api
  const [activeStep, setActiveStep] = useState(0)

  const itemRenderer = (item, itemStep) => {
    console.log(item)
    console.log(activeStep)
    const isActiveItem = activeStep === itemStep
    const backgroundColor = isActiveItem
      ? 'var(--yellow-300) !important'
      : itemStep < activeStep
      ? 'var(--green-400) !important'
      : 'var(--surface-b)'
    const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)'

    return (
      <span className='d-flex flex-column align-items-center'>
        <span
          className={`inline-flex align-items-center justify-content-center align-items-center border border-1 cursor-pointer rounded-circle h-4rem w-4rem p-4 z-5`}
          style={{
            backgroundColor: backgroundColor,
            color: textColor,
            marginTop: '-25px',
            zIndex: '5',
            borderColor: isActiveItem
              ? 'var(--yellow-300) !important'
              : itemStep < activeStep
              ? 'var(--green-400) !important'
              : 'var(--gray-500) !important',
            borderStyle: 'solid',
          }}
          onClick={() => setActiveStep(itemStep)}
        >
          {/* <i className={`${item.icon} text-xl`} /> */}
        </span>
        <span className='col text-wrap'>{item.label}</span>
      </span>
    )
  }

  // call when all data fill well
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Faculty,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload: any = {}
        payload = {
          dbId: Number(id),
          firstName: values?.firstName,
          lastName: values?.lastName,
          title: values?.title,
          facultyEnrollementId: values.facultyEnrollementId,
          highestEducation: values?.highest_education,
          yearsOfExperience: values?.years_of_experience,
          facultyImg: images?.facultyimage,
          about: values?.about,
          facultyArticles: array?.facultyArticles,
          facultyAwards: array?.facultyAwards,
          facultyCertification: array?.facultyCertification,
          facultyPublications: array?.facultyPublication,
        }
        addFaculty(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  useEffect(() => {
    CertificationTrigger({})
    PublisherTrigger({})
  }, [PublisherTrigger, CertificationTrigger])

  useEffect(() => {
    if (id) {
      facultyDetailsTrigger(id)
    } // Fetch faculty details with 'id'
  }, [facultyDetailsTrigger, id])

  useEffect(() => {
    if (isFacultyDetailsSuccess && !isFacultyDetailsFetching) {
      setInitialValues((v) => {
        return {
          ...v,
          facultyEnrollementId: facultyDetailsResult?.data?.facultyEnrollementId,
          firstName: facultyDetailsResult?.data?.firstName,
          lastName: facultyDetailsResult?.data?.lastName,
          title: facultyDetailsResult?.data?.title,
          highest_education: facultyDetailsResult?.data?.highestEducation,
          years_of_experience: facultyDetailsResult?.data?.yearsOfExperience,
          about: facultyDetailsResult?.data?.about,
        }
      })
    }
  }, [isFacultyDetailsSuccess, isFacultyDetailsFetching, facultyDetailsResult])

  // call when api calling done successfully
  useEffect(() => {
    // Effect for Faculty Trigger
    if (isSuccess && !isLoading) {
      facultyTrigger({})
    }

    // Effect for Faculty Success and Navigation
    if (isASuccess && !isFetching && isSuccess) {
      dispatch(setFaculty(facultyResult?.data?.facultyList))
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: result?.data?.detail ? result?.data?.detail : 'Faculty Added successfully',
        showConfirmButton: false,
        timer: 1500,
      })
      navigate('/faculty')
    }

    // Effect for Publisher
    if (isPublisher && !isNotPublisher) {
      dispatch(setPublisher(PublisherResult?.data?.publisherList))
    }

    // Effect for Certification
    if (isCertification && !isNotCertification) {
      dispatch(setCertification(CertificateResult?.data?.certificateIssuerList))
    }
  }, [
    isSuccess,
    isLoading,
    result?.data?.detail,
    facultyTrigger,
    isASuccess,
    isFetching,
    dispatch,
    navigate,
    facultyResult,
    isPublisher,
    isNotPublisher,
    PublisherResult,
    isCertification,
    isNotCertification,
    CertificateResult,
  ])
  // useEffect(() => {
  //   if (isFilter && !isNotFilter) {
  //     const data = filterResult?.data
  //     let stream = data.stream?.map((item) => {
  //       return {value: item?.dbId, label: item?.name}
  //     })
  //     setFilterData((prevData) => {
  //       return {...prevData, stream: stream ? stream : []}
  //     })
  //   }
  // }, [isFilter, isNotFilter, filterResult?.data])
  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error?.error])
  useEffect(() => {
    let ArrayPub: any = []
    let array: any = []
    if (publisher?.length !== 0) {
      publisher?.map((item: any, index) => {
        ArrayPub.push({value: item?.dbId, label: item?.publishingMedium})
        if (publisher?.length === index + 1) {
          ArrayPub.push({value: 0, label: 'other'})
        }
        return item
      })
    }
    if (certification?.length !== 0) {
      certification?.map((item: any, index) => {
        array.push({value: item?.dbId, label: item?.issuerName})
        if (certification?.length === index + 1) {
          array.push({value: 0, label: 'other'})
        }
        return item
      })
    }
    setList((oldList) => ({
      ...oldList,
      certification: array,
      publisher: ArrayPub,
    }))
  }, [publisher, certification])
  // submit for publications

  // useEffect(() => {
  //   if (issueYearRef.current) issueYearRef.current?.datepicker({minViewMode: 2, format: 'yyyy'})
  // }, [])
  const AddPublication = () => {
    console.log(array?.publication)
    console.log(index.publication)

    if (
      array?.publication?.publisher !== '' &&
      array?.publication?.title !== '' &&
      array?.publication?.publicationLink !== '' &&
      array?.publication?.publicationDate !== null &&
      array?.publication?.publicationDate !== ''
    ) {
      const selectedDate: any = moment(array?.publication?.publicationDate).format('YYYY-MM-DD')
      if (index?.publication === null) {
        setArray({
          ...array,
          facultyPublication: [
            ...array?.facultyPublication,
            {
              title: array?.publication?.title,
              publicationDate: selectedDate,
              publisherId: array?.publication?.publisherId,
              publisherDetails: {
                publishingMedium: array?.publication?.publisherDetails?.publishingMedium
                  ? array?.publication?.publisherDetails?.publishingMedium
                  : '',
              },
              publicationLink: array?.publication?.publicationLink,
              shortDescr: array?.publication?.shortDescr,
              mediumIcon: array?.publication?.mediumIcon,
            },
          ],
          publication: {
            mediumIcon: '',
            publicationDate: null,
            publicationLink: '',
            shortDescr: '',
            title: '',
          },
        })
      } else {
        SetButtonValue({
          ...buttonValue,
          publication: 'Add Publicationn',
        })
        let data = array?.facultyPublication
        data[index?.publication] = array?.publication
        setArray({
          ...array,
          facultyPublication: data,
          publication: {
            medium: '',
            mediumIcon: '',
            publicationDate: null,
            publicationLink: '',
            shortDescr: '',
            title: '',
          },
        })
        setIndex({
          ...index,
          publication: null,
        })
      }
    }
  }
  // submit for Articles
  const AddArticles = () => {
    // console.log(articleImg)
    if (
      array?.articles?.title !== '' &&
      array?.articles?.articleImg !== '' &&
      array?.articles?.articleLink !== '' &&
      array?.articles?.articleDate !== '' &&
      array?.articles?.articleDate !== null &&
      array?.articles?.shortDescr !== ''
    ) {
      const selectedArticleDate = moment(array?.articles?.articleDate).format('YYYY-DD-MM')
      if (index?.Articles === null) {
        if (articleImg.current) articleImg.current.value = ''
        setArray({
          ...array,
          facultyArticles: [
            ...array?.facultyArticles,
            {
              title: array?.articles?.title,
              articleDate: selectedArticleDate,
              shortDescr: array?.articles?.shortDescr,
              articleImg: array?.articles?.articleImg,
              articleLink: array?.articles?.articleLink,
            },
          ],
          articles: {
            title: '',
            articleImg: null,
            articleLink: '',
            articleDate: null,
            shortDescr: '',
          },
        })
      } else {
        if (articleImg.current) articleImg.current.value = ''
        SetButtonValue({
          ...buttonValue,
          Articles: 'Add Article',
        })
        let data: any = array?.facultyArticles
        data[index?.Articles] = array?.articles
        setArray({
          ...array,
          facultyArticles: data,
          articles: {
            title: '',
            articleImg: '',
            articleLink: '',
            articleDate: null,
            shortDescr: '',
          },
        })
        setIndex({
          ...index,
          Articles: null,
        })
      }
    }
  }

  // submit for certification
  const AddCertificate = () => {
    if (
      array?.certification?.certificationName !== '' &&
      array?.certification?.shortDescr !== '' &&
      array?.certification?.issueDate !== '' &&
      array?.certification?.issueDate !== null &&
      array?.certification?.credentialImg !== '' &&
      array?.certification?.credentialID !== '' &&
      array?.certification?.credentialURL !== ''
    ) {
      const selectedIssueDate = moment(array?.certification?.issueDate).format('YYYY-DD-MM')
      const selectedExpirationDate = moment(array?.certification?.expirationDate).format(
        'YYYY-DD-MM'
      )
      if (index?.certificate === null) {
        setArray({
          ...array,
          facultyCertification: [
            ...array?.facultyCertification,
            {
              certificationName: array?.certification?.certificationName,
              issuerId: array?.certification?.issuerId,
              issuerDetails: {
                issuerName: array?.certification?.issuerName,
              },
              issueDate: selectedIssueDate,
              expirationDate: selectedExpirationDate,
              credentialId: array?.certification?.credentialID,
              credentialUrl: array?.certification?.credentialURL,
              credentialImg: array?.certification?.credentialImg,
              shortDescr: array?.certification?.shortDescr,
              certificateDoesNotExpire: array?.certification?.certificateDoesNotExpire
                ? true
                : null,
            },
          ],
          certification: {
            certificationName: '',
            issueByImg: '',
            shortDescr: '',
            issueDate: null,
            expirationDate: null,
            credentialImg: '',
            credentialID: '',
            credentialURL: '',
          },
        })
      } else {
        SetButtonValue({
          ...buttonValue,
          certificate: 'Add Certification',
        })
        let data = array?.facultyCertification
        data[index?.certificate] = array?.certification
        setArray({
          ...array,
          facultyCertification: data,
          certification: {
            certificationName: '',
            issueByImg: '',
            shortDescr: '',
            issueDate: null,
            expirationDate: null,
            credentialImg: '',
            credentialID: '',
            credentialURL: '',
          },
        })
        setIndex({
          ...index,
          certificate: null,
        })
      }
      if (credentialRefImg.current) credentialRefImg.current.value = ''
    }
  }
  // submit for certification
  const AddAwards = () => {
    if (
      array?.awards?.title !== '' &&
      array?.awards?.awardYear !== '' &&
      array?.awards?.awardYear !== null &&
      array?.awards?.shortDescr !== ''
    ) {
      if (index?.Awards === null) {
        setArray({
          ...array,
          facultyAwards: [
            ...array?.facultyAwards,
            {
              title: array?.awards?.title,
              awardYear: Number(new Date(array?.awards?.awardYear).getFullYear().toString()),
              shortDescr: array?.awards?.shortDescr,
            },
          ],
          awards: {
            title: '',
            awardYear: null,
            shortDescr: '',
          },
        })
      } else {
        SetButtonValue({
          ...buttonValue,
          Awards: 'Add Award',
        })
        let data = array?.facultyAwards
        data[index?.Awards] = array?.awards
        setArray({
          ...array,
          facultyAwards: data,
          awards: {
            title: '',
            awardYear: null,
            shortDescr: '',
          },
        })
        setIndex({
          ...index,
          Awards: null,
        })
      }
      // Swal.fire({
      //   position: 'center',
      //   icon: 'success',
      //   title: 'Your details have been Saved',
      //   showConfirmButton: false,
      //   timer: 1500,
      // })
    }
  }
  const stepModel = [
    {
      icon: 'pi pi-user',
      template: (item) => itemRenderer(item, 0),
      content: () => (
        <>
          <div className='row'>
            <div className='col-4 mb-5'>
              <label className='form-label'>
                First Name<span className='text-danger'>*</span>
              </label>
              <InputText
                type='text'
                name='firstName'
                {...formik.getFieldProps('firstName')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                  {
                    'is-valid': formik.touched.firstName && !formik.errors.firstName,
                  }
                )}
              />
            </div>
            <div className='col-md-4 col-12 mb-5'>
              <label className='form-label'>Middle Name</label>
              <InputText
                type='text'
                name='name'
                placeholder='Middle Name'
                {...formik.getFieldProps('middleName')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.middleName && formik.errors.middleName},
                  {
                    'is-valid': formik.touched.middleName && !formik.errors.middleName,
                  }
                )}
              />
            </div>
            <div className='col-4 mb-5'>
              <label className='form-label'>
                Last Name<span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                name='lastName'
                {...formik.getFieldProps('lastName')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                  {
                    'is-valid': formik.touched.lastName && !formik.errors.lastName,
                  }
                )}
              />
            </div>
            <div className='col-md-4 mb-5 position-relative'>
              <label className='form-label'>
                Date Of Birth<span className='text-danger'>*</span>
              </label>
              <Calendar
                name='dob'
                id='dob'
                {...formik.getFieldProps('dob')}
                inputStyle={{
                  width: '88.1%',
                }}
                dateFormat='yy-mm-dd'
                className={clsx(
                  'form-control bg-transparent p-0 w-100',
                  {'is-invalid': formik.touched.dob && formik.errors.dob},
                  {
                    'is-valid': formik.touched.dob && !formik.errors.dob,
                  }
                )}
                showIcon
              />
            </div>
            <div className='col-md-4  mb-5 '>
              <label className='form-label'>
                Gender<span className='text-danger'>*</span>
              </label>
              <Dropdown
                placeholder='Select Gender'
                options={[
                  {value: 'male', label: 'Male'},
                  {value: 'female', label: 'Female'},
                ]}
                value={formik.values.gender || null}
                onChange={(selectedOption: any) =>
                  formik.setFieldValue('gender', selectedOption.value)
                }
                className='w-100'
              />
            </div>
            <div className='col-md-4  mb-5 '>
              <label className='form-label'>
                Blood Group<span className='text-danger'>*</span>
              </label>
              <Dropdown
                placeholder='Select Blood Group'
                value={formik.values.bloodGroup || null}
                options={[
                  {value: 'A+', label: 'A+'},
                  {value: 'A-', label: 'A-'},
                  {value: 'B+', label: 'B+'},
                  {value: 'B-', label: 'B-'},
                  {value: 'AB+', label: 'AB+'},
                  {value: 'AB-', label: 'AB-'},
                  {value: 'O+', label: 'O+'},
                  {value: 'O-', label: 'O-'},
                ]}
                onChange={(selectedOption: any) =>
                  formik.setFieldValue('bloodGroup', selectedOption.value)
                }
                className='w-100'
              />
            </div>
            <div className='col-md-4  mb-5 '>
              <label className='form-label'>
                Email<span className='text-danger'>*</span>
              </label>
              <InputText
                type='email'
                name='email'
                placeholder='Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {'is-valid': formik.touched.email && !formik.errors.email}
                )}
              />
            </div>
            <div className='col-md-4  mb-5 '>
              <label className='form-label'>
                Country Code<span className='text-danger'>*</span>
              </label>
              <Dropdown
                placeholder='Select Country Code'
                value={formik.values.countryCode || null}
                options={[{value: '+91', label: '🇮🇳 +91'}]}
                onChange={(selectedOption: any) =>
                  formik.setFieldValue('countryCode', selectedOption.value)
                }
                className='w-100'
              />
            </div>
            <div className='col-md-4  mb-5 '>
              <label className='form-label'>
                Contact No<span className='text-danger'>*</span>
              </label>
              <InputText
                type='text'
                name='contactNo'
                placeholder='Contact No'
                {...formik.getFieldProps('contactNo')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.contactNo && formik.errors.contactNo},
                  {'is-valid': formik.touched.contactNo && !formik.errors.contactNo}
                )}
              />
            </div>
            <div className='col-4 mb-5'>
              <label className='form-label'>
                Faculty Enrollment ID<span className='text-danger'>*</span>
              </label>
              <InputText
                type='text'
                name='facultyEnrollementId'
                {...formik.getFieldProps('facultyEnrollementId')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.facultyEnrollementId && formik.errors.facultyEnrollementId,
                  },
                  {
                    'is-valid':
                      formik.touched.facultyEnrollementId && !formik.errors.facultyEnrollementId,
                  }
                )}
              />
            </div>
            <div className='col-4 mb-5'>
              <label className='form-label'>
                Position<span className='text-danger'>*</span>
              </label>
              <InputText
                type='text'
                name='title'
                {...formik.getFieldProps('title')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.title && formik.errors.title},
                  {
                    'is-valid': formik.touched.title && !formik.errors.title,
                  }
                )}
              />
            </div>
            <div className='col-4 mb-5'>
              <label>
                About<span className='text-danger'>*</span>
              </label>
              <InputTextarea
                name='about'
                placeholder='About'
                {...formik.getFieldProps('about')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.about && formik.errors.about,
                  },
                  {
                    'is-valid': formik.touched.about && !formik.errors.about,
                  }
                )}
                rows={3}
              />
            </div>
            <div className='col-4 mb-5'>
              <label className='form-label'>
                Faculty Image<span className='text-danger'>*</span>
              </label>
              <input
                type='file'
                name='facultyImage'
                onChange={onHandleChangeFile}
                className={clsx('form-control bg-transparent', {
                  'is-valid': images?.facultyimage,
                })}
              />
              {/* {images?.facultyimage ? (
                <img
                  src={images?.facultyimage}
                  className='w-50px h-50px mt-2 rounded'
                  alt='credential'
                />
              ) : null} */}
            </div>

            <div className='col-4 mb-5'>
              <label className='form-label'>
                Highest Qualification<span className='text-danger'>*</span>
              </label>
              <InputText
                type='text'
                name='highest_education'
                {...formik.getFieldProps('highest_education')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.highest_education && formik.errors.highest_education,
                  },
                  {
                    'is-valid':
                      formik.touched.highest_education && !formik.errors.highest_education,
                  }
                )}
              />
            </div>
            <div className='col-4 mb-5'>
              <label className='form-label'>
                Experience<span className='text-danger'>*</span>
              </label>
              <InputText
                name='years_of_experience'
                type='text'
                placeholder='5+ years'
                {...formik.getFieldProps('years_of_experience')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.years_of_experience && formik.errors.years_of_experience,
                  },
                  {
                    'is-valid':
                      formik.touched.years_of_experience && !formik.errors.years_of_experience,
                  }
                )}
              />
            </div>
          </div>

          {/* <div className='row'></div> */}
        </>
      ),
      label: 'Basic Details',
    },
    {
      icon: 'pi pi-calendar',
      template: (item) => itemRenderer(item, 1),
      content: () => (
        <div className='row'>
          <div className='col-5 p-0'>
            <div className='col-12'>
              <div
                className={`card card-custom ${
                  flag?.facultyPublication ? 'border border-danger border-2' : ''
                }`}
              >
                <div className='card-body px-0 ps-3 py-0'>
                  <div className='row'>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Title<span className='text-danger'>*</span>
                      </label>
                      <InputText
                        type='text'
                        name='title'
                        value={array?.publication?.title}
                        onChange={(e) => {
                          onPublicationChange(e)
                        }}
                        className={clsx('form-control bg-transparent')}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Publisher<span className='text-danger'>*</span>
                      </label>
                      <Dropdown
                        placeholder='Select Publisher'
                        value={array.publication.publisherId}
                        options={list?.publisher}
                        onChange={(e: any) => {
                          console.log(e)
                          console.log(publisher)
                          if (e.value !== 0) {
                            const filterData = publisher.find((item) => item?.dbId === e?.value)
                            setArray({
                              ...array,
                              publication: {
                                ...array?.publication,
                                publisherId: e.value,
                                publisherName: e.label || filterData.publishingMedium,
                                mediumIcon: filterData?.publisherImg,
                              },
                            })
                          } else {
                            setArray({
                              ...array,
                              publication: {
                                ...array?.publication,
                                publisherId: 0,
                                publisherName: 'other',
                                mediumIcon: PublisherResult?.data?.defaultPublisherImg,
                              },
                            })
                          }
                        }}
                        className='w-100'
                      />

                      {/* <CreatableSelect
                        isClearable={false}
                        onChange={(e: any) => {
                          if (e.value !== 'other') {
                            const filterData = publisher.find((item) => item?.dbId === e?.value)
                            setArray({
                              ...array,
                              publication: {
                                ...array?.publication,
                                publisherId: e.value,
                                publisherName: e.label,
                                mediumIcon: filterData?.publisherImg,
                              },
                            })
                          } else {
                            setArray({
                              ...array,
                              publication: {
                                ...array?.publication,
                                publisherId: null,
                                publisherName: e.label,
                                mediumIcon: PublisherResult?.data?.defaultPublisherImg,
                              },
                            })
                          }
                        }}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            height: '100%',
                            width: '100%',
                          }),
                          option: (base) => ({
                            ...base,
                            cursor: 'pointer',
                            margin: '0px',
                            boxShadow: 'unset',
                            color: '#7e8299',
                            fontWeight: 'bold',
                            background: 'white',
                            ':hover': {
                              color: '#009ef7',
                              backgroundColor: '#f1faff',
                            },
                          }),
                        }}
                        options={list?.publisher}
                      /> */}
                    </div>

                    {
                      <div className='col-12 d-flex flex-column mb-5'>
                        {array.publication.publisherName &&
                          array?.publication?.publisherName === 'other' && (
                            <label className='form-label'>
                              Medium Image<span className='text-danger'>*</span>
                            </label>
                          )}
                        {array?.publication?.mediumIcon ? (
                          <img
                            src={array?.publication?.mediumIcon}
                            className='w-50px h-50px mt-2 rounded'
                            alt='credential'
                          />
                        ) : null}
                      </div>
                    }
                    {array?.publication?.publisherName === 'other' && (
                      <>
                        <div className='col-12 mb-5'>
                          <label className='form-label'>
                            Publishing Medium<span className='text-danger'>*</span>
                          </label>
                          <InputText
                            type='text'
                            name='publishingMedium'
                            value={array?.publication?.publisherDetails?.publishingMedium}
                            onChange={(e) => {
                              console.log(e)
                              setArray({
                                ...array,
                                publication: {
                                  ...array?.publication,
                                  publisherDetails: {
                                    ...array?.publication?.publisherDetails,
                                    publishingMedium: e.target.value,
                                  },
                                },
                              })
                            }}
                            className={clsx('form-control bg-transparent')}
                            // id='exampleFormControlTextarea1'
                          />
                        </div>
                        <div className='col-12 mb-5'>
                          <label className='form-label'>
                            Short Description <span className='text-danger'>*</span>
                          </label>
                          <InputTextarea
                            name='shortDescr'
                            value={array?.publication?.shortDescr}
                            onChange={(e) => {
                              onPublicationChange(e)
                            }}
                            className={clsx('form-control bg-transparent')}
                            id='exampleFormControlTextarea1'
                            rows={3}
                          />
                        </div>
                      </>
                    )}
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Publication Link<span className='text-danger'>*</span>
                      </label>
                      <InputText
                        name='publicationLink'
                        type='text'
                        value={array?.publication?.publicationLink}
                        onChange={(e) => {
                          onPublicationChange(e)
                        }}
                        className={clsx('form-control bg-transparent')}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Publication Date<span className='text-danger'>*</span>
                      </label>
                      <Calendar
                        dateFormat='yy-mm-dd'
                        inputStyle={{
                          width: '90.8%',
                        }}
                        name='publicationDate'
                        id='publicationDate'
                        maxDate={new Date(today)}
                        value={array?.publication?.publicationDate || null}
                        onChange={(e) => {
                          console.log(e)
                          onPublicationChange(e)
                        }}
                        showIcon
                        className={clsx('form-control bg-transparent p-0')}
                      />
                      {/* <input
                        type='date'
                        name='publicationDate'
                        id='publicationDate'
                        value={array?.publication?.publicationDate}
                        onChange={(e) => {
                          onPublicationChange(e)
                        }}
                        className={clsx('form-control bg-secondary p-0')}
                        max={today}
                      />
                      <div
                        className='position-absolute form-label'
                        style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                      >
                        <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                        {array?.publication?.publicationDate
                          ? array?.publication?.publicationDate
                          : 'Publication Date*'}
                      </div> */}
                    </div>

                    <div className='col-12 text-end mb-0'>
                      <Button
                        label={buttonValue?.publication}
                        rounded
                        // outlined
                        severity='success'
                        icon={<AddIcon />}
                        style={{
                          borderRadius: '2rem',
                          // width: '15%',
                        }}
                        className='align-self-center my-2'
                        onClick={AddPublication}
                        disabled={
                          array?.publication?.publisher !== '' &&
                          array?.publication?.title !== '' &&
                          array?.publication?.publicationLink !== '' &&
                          array?.publication?.publicationDate !== '' &&
                          array?.publication?.publicationDate !== null
                            ? false
                            : true
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col p-0'>
            <Divider layout='vertical' className='mx-0' />
          </div>
          <div className='col-6 p-0'>
            {array?.facultyPublication?.length !== 0 && (
              <div>
                <div className='card border mb-5 card-custom'>
                  {/* <div className='card-header '>
                    <div
                      className='card-toolbar w-100  text-end'
                      style={{margin: 'auto', display: 'unset'}}
                    >
                      <i
                        className={`${
                          flag?.publication ? 'fa-solid fa-check text-success' : 'fa-solid fa-pen'
                        } fs-2`}
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          flag?.publication
                            ? setFlag({...flag, publication: false})
                            : setFlag({...flag, publication: true})
                        }}
                      ></i>
                    </div>
                  </div> */}
                  <div className='card-body card-scroll h-400px py-5'>
                    <div className='row'>
                      {array?.facultyPublication?.map((item, index) => {
                        return (
                          <div key={index} className='col-12 mb-5 border-bottom'>
                            <div className='d-flex align-items-baseline justify-content-between'>
                              <div className='d-md-flex' style={{gap: '20px'}}>
                                <div className=' mb-5'>
                                  <img
                                    src={item?.mediumIcon}
                                    className='text-center rounded symbol-badge symbol-35px'
                                    alt=''
                                    style={{
                                      height: '50px',
                                      width: '50px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                </div>
                                <div className=''>
                                  <h3 className='m-0 fs-5'>{item?.title}</h3>
                                  <div className='fs-7'>{item?.publicationDate}</div>
                                  <div className='fw-bold text-muted fs-8'>{item?.shortDescr}</div>
                                  <div className='text-muted fw-bold fs-8'>
                                    medium: {item?.medium}
                                  </div>
                                </div>
                              </div>
                              <div>
                                {flag?.publication ? (
                                  <div className='d-flex align-items-center'>
                                    <button
                                      className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                      type='button'
                                    >
                                      <i
                                        className='fa-solid fa-pen fs-5 me-3 '
                                        onClick={() => {
                                          SetButtonValue({
                                            ...buttonValue,
                                            publication: 'Edit',
                                          })
                                          setArray({
                                            ...array,
                                            publication: item,
                                          })
                                          setIndex({
                                            ...index,
                                            publication: index,
                                          })
                                        }}
                                      ></i>
                                    </button>
                                    <i
                                      className='ki-duotone ki-trash-square fs-1 text-danger'
                                      style={{cursor: 'pointer'}}
                                      onClick={() => {
                                        Swal.fire({
                                          title: 'Confirm deletion?',
                                          showDenyButton: false,
                                          showCancelButton: true,
                                          confirmButtonText:
                                            "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                                          confirmButtonColor: '#009EF7',
                                          cancelButtonText:
                                            '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                                        }).then((result) => {
                                          /* Read more about isConfirmed, isDenied below */
                                          if (result.isConfirmed) {
                                            let data = array?.facultyPublication
                                            data.splice(index, 1)
                                            setArray({
                                              ...array,
                                              facultyPublication: data,
                                            })
                                          } else if (result.isDenied) {
                                            Swal.fire('Record is not deleted', '', 'error')
                                          }
                                        })
                                      }}
                                    >
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>
                                  </div>
                                ) : (
                                  <div className='d-flex align-items-center'>
                                    <a
                                      href={item?.publicationLink}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                    >
                                      <i className='fa-solid fa-arrow-up-right-from-square p-0 m-2 fs-1'></i>
                                    </a>
                                    <i
                                      className='ki-duotone ki-trash-square fs-1 text-danger'
                                      style={{cursor: 'pointer'}}
                                      onClick={() => {
                                        Swal.fire({
                                          title: 'Confirm deletion?',
                                          showDenyButton: false,
                                          showCancelButton: true,
                                          confirmButtonText:
                                            "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                                          confirmButtonColor: '#009EF7',
                                          cancelButtonText:
                                            '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                                        }).then((result) => {
                                          /* Read more about isConfirmed, isDenied below */
                                          if (result.isConfirmed) {
                                            let data = array?.facultyPublication
                                            data.splice(index, 1)
                                            setArray({
                                              ...array,
                                              facultyPublication: data,
                                            })
                                          } else if (result.isDenied) {
                                            Swal.fire('Record is not deleted', '', 'error')
                                          }
                                        })
                                      }}
                                    >
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ),
      label: 'Publication Details',
    },
    {
      icon: 'pi pi-calendar',
      template: (item) => itemRenderer(item, 2),
      content: () => (
        <div className='row'>
          <div className='col-5 p-0'>
            <div className='col-12'>
              <div
                className={`card card-custom ${
                  flag?.facultyCertification ? 'border border-danger border-2' : ''
                }`}
              >
                <div className='card-body px-0 ps-3 py-0'>
                  <div className='row'>
                    <div className='col-12 mb-5'>
                      <div className='col-12 mb-5'>
                        <label className='form-label'>
                          Title<span className='text-danger'>*</span>
                        </label>
                        <InputText
                          type='text'
                          name='certificationName'
                          onChange={(e) => {
                            onCertificationChange(e)
                          }}
                          value={array?.certification?.certificationName}
                          className={clsx('form-control bg-transparent')}
                        />
                      </div>
                      <div className='col-12 mb-5'>
                        <label className='form-label'>
                          Issuing Organisation<span className='text-danger'>*</span>
                        </label>
                        <Dropdown
                          placeholder='select issuing organisation'
                          className='w-100'
                          options={list?.certification}
                          value={array?.certification.issuerId || null}
                          onChange={(e: any) => {
                            if (e.label !== 0) {
                              const filterData = certification.find(
                                (item) => item?.dbId === e?.value
                              )
                              setArray({
                                ...array,
                                certification: {
                                  ...array?.certification,
                                  issuerId: e.value,
                                  issuerDetails: {
                                    issuerName: e.label || filterData.certificationMedium,
                                  },
                                  issuerName: e.label || filterData.certificationMedium,
                                },
                              })
                            } else {
                              setArray({
                                ...array,
                                certification: {
                                  ...array?.certification,
                                  issuerId: null,
                                  issuerName: '',
                                  issuerDetails: {
                                    issuerName: '',
                                  },
                                },
                              })
                            }
                          }}
                        />
                        {/* <CreatableSelect
                          options={list?.certification}
                          isClearable={false}
                          onChange={(e: any) => {
                            if (e.label !== 0) {
                              const filterData = certification.find(
                                (item) => item?.dbId === e?.value
                              )
                              setArray({
                                ...array,
                                certification: {
                                  ...array?.certification,
                                  issuerId: e.value,
                                  issuerDetails: {
                                    issuerName: e.label || filterData.certificationMedium,
                                  },
                                  issuerName: e.label || filterData.certificationMedium,
                                },
                              })
                            } else {
                              setArray({
                                ...array,
                                certification: {
                                  ...array?.certification,
                                  issuerId: null,
                                  issuerName: '',
                                  issuerDetails: {
                                    issuerName: '',
                                  },
                                },
                              })
                            }
                          }}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              height: '100%',
                              width: '100%',
                            }),
                            option: (base) => ({
                              ...base,
                              cursor: 'pointer',
                              margin: '0px',
                              boxShadow: 'unset',
                              color: '#7e8299',
                              fontWeight: 'bold',
                              background: 'white',
                              ':hover': {
                                color: '#009ef7',
                                backgroundColor: '#f1faff',
                              },
                            }),
                          }}
                        /> */}
                      </div>
                    </div>
                    {array?.certification?.issuerId === null && (
                      <div className='col-12 mb-5'>
                        <label className='form-label'>
                          Issuer<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          name='issuerName'
                          onChange={(e) => {
                            onCertificationChange(e)
                          }}
                          className={clsx('form-control bg-transparent')}
                        />
                      </div>
                    )}

                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Short Description <span className='text-danger'>*</span>
                      </label>
                      <InputTextarea
                        name='shortDescr'
                        onChange={(e) => {
                          onCertificationChange(e)
                        }}
                        value={array?.certification?.shortDescr}
                        className={clsx('form-control bg-transparent')}
                        rows={3}
                      />
                    </div>

                    <div className='col-md-6 mb-5 position-relative'>
                      <label className='form-label'>
                        Issue Date<span className='text-danger'>*</span>
                      </label>
                      <Calendar
                        dateFormat='yy-mm-dd'
                        inputStyle={{
                          width: '80.7%',
                        }}
                        name='issueDate'
                        id='issueDate'
                        maxDate={new Date(today)}
                        value={array?.certification?.issueDate || null}
                        onChange={(e) => {
                          console.log(e)
                          onCertificationChange(e)
                        }}
                        showIcon
                        className={clsx('form-control bg-transparent p-0')}
                      />
                      {/* <input
                        name='issueDate'
                        id='issueDate'
                        type='date'
                        onChange={(e) => {
                          onCertificationChange(e)
                        }}
                        value={array?.certification?.issueDate}
                        className={clsx('form-control bg-secondary p-0')}
                        max={today}
                      />
                      <div
                        className='position-absolute form-label'
                        style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                      >
                        <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                        {array?.certification?.issueDate
                          ? array?.certification?.issueDate
                          : 'Issue Date*'}
                      </div> */}
                    </div>

                    {!array?.certification?.certificateDoesNotExpire && (
                      <div className='col-md-6 mb-5 position-relative'>
                        <label className='form-label'>Expiry Date</label>
                        <Calendar
                          dateFormat='yy-mm-dd'
                          inputStyle={{
                            width: '80.7%',
                          }}
                          name='expirationDate'
                          id='expirationDate'
                          minDate={array?.certification?.issueDate}
                          value={array?.certification?.expirationDate || null}
                          onChange={(e) => {
                            console.log(e)
                            onCertificationChange(e)
                          }}
                          showIcon
                          className={clsx('form-control bg-transparent p-0')}
                        />
                        {/* <input
                          name='expirationDate'
                          id='expirationDate'
                          type='date'
                          onChange={(e) => {
                            onCertificationChange(e)
                          }}
                          value={array?.certification?.expirationDate}
                          className='form-control bg-secondary p-0'
                          min={array?.certification?.issueDate}
                        />
                        <div
                          className='position-absolute form-label'
                          style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                        >
                          <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                          {array?.certification?.expirationDate
                            ? array?.certification?.expirationDate
                            : 'Expiry Date*'}
                        </div> */}
                      </div>
                    )}

                    <div className='col-12 mb-5 d-flex align-items-center'>
                      <label className='form-check-label me-4'>Credential does not expire</label>
                      <Checkbox
                        name='certificateDoesNotExpire'
                        checked={array.certification.certificateDoesNotExpire}
                        onChange={() =>
                          setArray({
                            ...array,
                            certification: {
                              ...array.certification,
                              certificateDoesNotExpire:
                                !array.certification.certificateDoesNotExpire,
                            },
                          })
                        }
                      />
                      {/* <input
                        type='checkbox'
                        className='form-check-input'
                        name='certificateDoesNotExpire'
                        onChange={() =>
                          setArray({
                            ...array,
                            certification: {
                              ...array.certification,
                              certificateDoesNotExpire:
                                !array.certification.certificateDoesNotExpire,
                            },
                          })
                        }
                        checked={array.certification.certificateDoesNotExpire}
                      /> */}
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>Credential Reference Image</label>
                      <input
                        type='file'
                        name='credentialImg'
                        ref={credentialRefImg}
                        onChange={(e) => {
                          onHandleChangeFile(e)
                        }}
                        className={clsx('form-control bg-transparent py-3 ps-3')}
                      />
                      {/* {array?.certification?.credentialImg ? (
                        <img
                          src={array?.certification?.credentialImg}
                          className='w-50px h-50px mt-2 rounded'
                          alt='credential'
                        />
                      ) : null} */}
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>Credential ID</label>
                      <InputText
                        type='text'
                        name='credentialID'
                        onChange={(e) => {
                          onCertificationChange(e)
                        }}
                        value={array?.certification?.credentialID}
                        className={clsx('form-control bg-transparent')}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>Credential URL</label>
                      <InputText
                        type='text'
                        name='credentialURL'
                        value={array?.certification?.credentialURL}
                        onChange={(e) => {
                          onCertificationChange(e)
                        }}
                        className={clsx('form-control bg-transparent')}
                      />
                    </div>
                    <div className='col-12 text-end'>
                      <Button
                        label={buttonValue?.certificate}
                        rounded
                        // outlined
                        severity='success'
                        icon={<AddIcon />}
                        style={{
                          borderRadius: '2rem',
                          // width: '15%',
                        }}
                        className='align-self-center my-2'
                        onClick={AddCertificate}
                        disabled={
                          array?.certification?.certificationName !== '' &&
                          array?.certification?.issueDate !== '' &&
                          array?.certification?.credentialImg !== '' &&
                          array?.certification?.credentialID !== '' &&
                          array?.certification?.credentialURL !== ''
                            ? false
                            : true
                        }
                      />
                      {/* <button
                              className='btn btn-primary'
                              disabled={
                                array?.certification?.certificationName !== '' &&
                                array?.certification?.issueDate !== '' &&
                                array?.certification?.credentialImg !== '' &&
                                array?.certification?.credentialID !== '' &&
                                array?.certification?.credentialURL !== ''
                                  ? false
                                  : true
                              }
                              onClick={AddCertificate}
                            >
                              <i
                                className={`ki-duotone ki-${
                                  buttonValue?.certificate === 'Add Certification'
                                    ? 'message-add'
                                    : 'message-edit'
                                } fs-1`}
                              >
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                              {buttonValue?.certificate}
                            </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col p-0'>
            <Divider layout='vertical' className='mx-0' />
          </div>
          <div className='col-6 p-0'>
            {array?.facultyCertification?.length !== 0 && (
              <div className='card card-custom shadow border mb-5'>
                <div className='card-header '>
                  <div className='card-toolbar w-100 m-auto text-end' style={{display: 'unset'}}>
                    <i
                      className={`${
                        flag?.certificate ? 'fa-solid fa-check text-success' : 'fa-solid fa-pen'
                      } fs-2`}
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.certificate
                          ? setFlag({...flag, certificate: false})
                          : setFlag({...flag, certificate: true})
                      }}
                    ></i>
                  </div>
                </div>
                <div className='card-body card-scroll h-200px  py-5'>
                  <div className='row'>
                    {array?.facultyCertification?.map((item, index) => {
                      return (
                        <div key={index} className='col-12 mb-5 border-bottom'>
                          <div className='d-flex align-items-baseline justify-content-between'>
                            <div className='d-md-flex' style={{gap: '20px'}}>
                              <div className=' mb-5'>
                                <img
                                  src={item?.credentialImg}
                                  className='text-center rounded symbol-badge symbol-35px'
                                  alt=''
                                  style={{
                                    height: '50px',
                                    width: '50px',
                                    objectFit: 'cover',
                                  }}
                                />
                              </div>
                              <div className=''>
                                <h3 className='m-0 fs-5'>{item?.certificationName}</h3>
                                <div className='fs-7'>
                                  {item?.expirationDate
                                    ? `${item?.issueDate} - ${item?.expirationDate}`
                                    : `${item?.issueDate} `}
                                </div>
                                <div className='fw-bold text-muted fs-8'>{item?.issuedBy}</div>
                                <div className='text-muted fw-bold fs-8'>
                                  Credential ID {item?.credentialID}
                                </div>
                                <a
                                  href={item?.credentialURL}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <button
                                    className='btn fs-8 mt-2 mb-5 btn-outline btn-outline-solid btn-outline-default text-muted fw-bold me-2 mb-2 py-1'
                                    style={{borderRadius: '20px'}}
                                  >
                                    Show Credential
                                    <i className='fa-solid fa-arrow-up-right-from-square  m-2'></i>
                                  </button>
                                </a>
                              </div>
                            </div>
                            <div>
                              {flag?.certificate ? (
                                <>
                                  {/* <button
                                                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                                    type='button'
                                                  >
                                                    <i
                                                      className='fa-solid fa-pen fs-5 me-3 '
                                                      onClick={() => {
                                                        setArray({...array, certification: item})
                                                        setIndex({
                                                          ...index,
                                                          certificate: index,
                                                        })
                                                        SetButtonValue({
                                                          ...buttonValue,
                                                          certificate: 'Edit',
                                                        })
                                                      }}
                                                    ></i>
                                                  </button> */}
                                  <i
                                    className='ki-duotone ki-trash-square fs-1 text-danger'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      Swal.fire({
                                        title: 'Confirm deletion?',
                                        showDenyButton: false,
                                        showCancelButton: true,
                                        confirmButtonText:
                                          "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                                        confirmButtonColor: '#009EF7',
                                        cancelButtonText:
                                          '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                                      }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (result.isConfirmed) {
                                          let data = array?.facultyCertification
                                          data.splice(index, 1)
                                          setArray({
                                            ...array,
                                            facultyCertification: data,
                                          })
                                        } else if (result.isDenied) {
                                          Swal.fire('Record is not deleted', '', 'error')
                                        }
                                      })
                                    }}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ),
      label: 'License & Certification',
    },
    {
      icon: 'pi pi-check',
      template: (item) => itemRenderer(item, 3),
      content: () => (
        <div className='row'>
          <div className='col-5 p-0'>
            <div className='col-12 '>
              <div
                className={`card card-custom ${
                  flag?.facultyArticles ? 'border border-danger border-2' : ''
                }`}
              >
                <div className='card-body px-0 ps-3 py-0'>
                  <div className='row'>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Title<span className='text-danger'>*</span>
                      </label>
                      <InputText
                        type='text'
                        name='title'
                        onChange={(e) => {
                          onArticlesChange(e)
                        }}
                        value={array?.articles?.title}
                        className={clsx('form-control bg-transparent')}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Article Reference Image<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='file'
                        ref={articleImg}
                        name='articleImg'
                        onChange={(e) => {
                          onHandleChangeFile(e)
                        }}
                        className={clsx('form-control bg-transparent')}
                      />
                      {/* {array?.articles?.articleImg ? (
                        <img
                          src={array?.articles?.articleImg}
                          className='w-50px h-50px mt-2 rounded'
                          alt='credential'
                        />
                      ) : null} */}
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Article Link<span className='text-danger'>*</span>
                      </label>
                      <InputText
                        type='text'
                        name='articleLink'
                        onChange={(e) => {
                          onArticlesChange(e)
                        }}
                        value={array?.articles?.articleLink}
                        className={clsx('form-control bg-transparent')}
                      />
                    </div>

                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Article Date<span className='text-danger'>*</span>
                      </label>
                      <Calendar
                        dateFormat='yy-mm-dd'
                        inputStyle={{
                          width: '90.8%',
                        }}
                        name='articleDate'
                        id='articleDate'
                        maxDate={new Date(today)}
                        value={array?.articles?.articleDate || null}
                        onChange={(e) => {
                          console.log(e)
                          onArticlesChange(e)
                        }}
                        showIcon
                        className={clsx('form-control bg-transparent p-0')}
                      />
                      {/* <input
                        type='date'
                        name='articleDate'
                        id='articleDate'
                        onChange={(e) => {
                          onArticlesChange(e)
                        }}
                        value={array?.articles?.articleDate}
                        className={clsx('form-control bg-secondary p-0')}
                        max={today}
                      />
                      <div
                        className='position-absolute form-label'
                        style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                      >
                        <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                        {array?.articles?.articleDate
                          ? array?.articles?.articleDate
                          : 'Article Date*'}
                      </div> */}
                    </div>
                    <div className='col-12 mb-5'>
                      <label>
                        Short Description<span className='text-danger'>*</span>
                      </label>
                      <InputTextarea
                        name='shortDescr'
                        onChange={(e) => {
                          onArticlesChange(e)
                        }}
                        value={array?.articles?.shortDescr}
                        className={clsx('form-control bg-transparent')}
                        id='exampleFormControlTextarea1'
                        rows={3}
                      />
                    </div>
                    <div className='col-12 text-end'>
                      <Button
                        label={buttonValue?.Articles}
                        rounded
                        // outlined
                        severity='success'
                        icon={<AddIcon />}
                        style={{
                          borderRadius: '2rem',
                          // width: '15%',
                        }}
                        className='align-self-center my-2'
                        disabled={
                          array?.articles?.title !== '' &&
                          array?.articles?.articleImg !== '' &&
                          array?.articles?.articleLink !== '' &&
                          array?.articles?.articleDate !== '' &&
                          array?.articles?.shortDescr !== ''
                            ? false
                            : true
                        }
                        onClick={AddArticles}
                      />
                      {/* <button
                              className='btn btn-primary'
                              type='button'
                              disabled={
                                array?.articles?.title !== '' &&
                                array?.articles?.articleImg !== '' &&
                                array?.articles?.articleLink !== '' &&
                                array?.articles?.articleDate !== '' &&
                                array?.articles?.shortDescr !== ''
                                  ? false
                                  : true
                              }
                              onClick={AddArticles}
                            >
                              <i
                                className={`ki-duotone ki-${
                                  buttonValue?.Articles === 'Add Article'
                                    ? 'message-add'
                                    : 'message-edit'
                                } fs-1`}
                              >
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                              {buttonValue?.Articles}
                            </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col p-0'>
            <Divider layout='vertical' className='mx-0 py-0' />
          </div>
          <div className='col-6 p-0'>
            {array?.facultyArticles?.length !== 0 && (
              <div className='card border card-custom'>
                {/* <div className='card-header '>
                  <div className='card-toolbar w-100 m-auto text-end' style={{display: 'unset'}}>
                    <i
                      className={`${
                        flag?.Articles ? 'fa-solid fa-check text-success' : 'fa-solid fa-pen'
                      } fs-2`}
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.Articles
                          ? setFlag({...flag, Articles: false})
                          : setFlag({...flag, Articles: true})
                      }}
                    ></i>
                  </div>
                </div> */}
                <div className='card-body card-scroll h-500px  py-5'>
                  <div className='row'>
                    {array?.facultyArticles?.map((item, index) => {
                      return (
                        <div key={index} className='col-12 mb-5 border-bottom'>
                          <div className='d-flex align-items-baseline justify-content-between'>
                            <div className='d-md-flex' style={{gap: '20px'}}>
                              <div className=' mb-5'>
                                <img
                                  src={item?.articleImg}
                                  className='text-center rounded symbol-badge symbol-35px'
                                  alt=''
                                  style={{
                                    height: '100px',
                                    width: '100px',
                                    objectFit: 'cover',
                                  }}
                                />
                              </div>
                              <div className=''>
                                <h3 className='m-0 fs-5'>{item?.title}</h3>
                                <div className='fs-7'>{item?.articleDate}</div>
                                <div className='fw-bold text-muted fs-8'>{item?.shortDescr}</div>
                              </div>
                            </div>
                            <div>
                              {flag?.Articles ? (
                                <div className='d-flex align-items-center'>
                                  <button
                                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                    type='button'
                                  >
                                    <i
                                      className='fa-solid fa-pen fs-5 me-3 '
                                      onClick={() => {
                                        SetButtonValue({
                                          ...buttonValue,
                                          Articles: 'Edit',
                                        })
                                        setArray({...array, articles: item})
                                        setIndex({
                                          ...index,
                                          Articles: index,
                                        })
                                      }}
                                    ></i>
                                  </button>
                                  <i
                                    className='ki-duotone ki-trash-square fs-1 text-danger'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      let data = array?.facultyArticles
                                      data.splice(index, 1)
                                      setArray({
                                        ...array,
                                        facultyArticles: data,
                                      })
                                    }}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center'>
                                  <a
                                    href={item?.articleLink}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    {/* <button
                                      className='btn fs-8 mt-2 mb-5 btn-outline btn-outline-solid btn-outline-default text-muted fw-bold me-2 mb-2 py-1'
                                      style={{borderRadius: '20px'}}
                                    > */}
                                    <i className='fa-solid fa-arrow-up-right-from-square m-2 fs-1'></i>
                                    {/* </button> */}
                                  </a>
                                  <i
                                    className='ki-duotone ki-trash-square fs-1 text-danger'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      let data = array?.facultyArticles
                                      data.splice(index, 1)
                                      setArray({
                                        ...array,
                                        facultyArticles: data,
                                      })
                                    }}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ),
      label: 'Articles',
    },
    {
      icon: 'pi pi-check',
      template: (item) => itemRenderer(item, 4),
      content: () => (
        <div className='row'>
          <div className='col-5 p-0'>
            <div className='col-12'>
              <div
                className={`card card-custom ${
                  flag?.facultyAwards ? 'border border-danger border-2' : ''
                }`}
              >
                <div className='card-body px-0 ps-3 py-0'>
                  <div className='row'>
                    <div className='col-12'></div>

                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Award Title<span className='text-danger'>*</span>
                      </label>
                      <InputText
                        type='text'
                        name='title'
                        onChange={onAwardsChange}
                        value={array?.awards?.title}
                        className={clsx('form-control bg-transparent')}
                      />
                    </div>
                    <div className='col-12 mb-5 position-relative'>
                      <label className='form-label'>
                        Receiving Year<span className='text-danger'>*</span>
                      </label>
                      <Calendar
                        dateFormat='yy'
                        view='year'
                        inputStyle={{
                          width: '90.8%',
                        }}
                        name='awardYear'
                        minDate={new Date('2000-01-01')}
                        maxDate={new Date(`${new Date().getFullYear()}-12-31`)}
                        value={array.awards.awardYear || null}
                        onChange={onAwardsChange}
                        showIcon
                        className={clsx('form-control bg-transparent p-0')}
                      />
                      {/* <Calendar
                        name='awardYear'
                        value={array.awards.awardYear}
                        onChange={onAwardsChange}
                        view='year'
                        dateFormat='yy'
                        className='form-control bg-secondary p-0 cursor-pointer'
                        inputClassName='bg-secondary border-0 form-control cursor-pointer'
                        readOnlyInput={true}
                        inputStyle={{
                          color: 'transparent',
                        }}
                        minDate={new Date('2000-01-01')}
                        maxDate={new Date(`${new Date().getFullYear()}-12-31`)}
                      />
                      <div
                        className='position-absolute form-label cursor-pointer'
                        style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                      >
                        <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                        {array?.awards?.awardYear ? array?.awards?.awardYear : 'Receiving Year*'}
                      </div> */}

                      {/* <select
                                name='awardYear'
                                onChange={onAwardsChange}
                                value={array.awards.awardYear}
                                className='form-control bg-transparent'
                              >
                                {generateYearOptions()}
                              </select> */}
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Short Description <span className='text-danger'>*</span>
                      </label>
                      <InputTextarea
                        name='shortDescr'
                        onChange={(e) => {
                          onAwardsChange(e)
                        }}
                        value={array?.awards?.shortDescr}
                        className={clsx('form-control bg-transparent')}
                        rows={3}
                      />
                    </div>
                    <div className='col-12 text-end'>
                      <Button
                        label={buttonValue?.Awards}
                        rounded
                        // outlined
                        severity='success'
                        icon={<AddIcon />}
                        style={{
                          borderRadius: '2rem',
                          // width: '15%',
                        }}
                        className='align-self-center my-2'
                        disabled={
                          array?.awards?.title !== '' &&
                          array?.awards?.awardYear !== '' &&
                          array?.awards?.awardYear !== null &&
                          array?.awards?.shortDescr !== ''
                            ? false
                            : true
                        }
                        onClick={AddAwards}
                      />
                      {/* <button
                              className='btn btn-primary'
                              disabled={
                                array?.awards?.title !== '' &&
                                array?.awards?.awardYear !== '' &&
                                array?.awards?.shortDescr !== ''
                                  ? false
                                  : true
                              }
                              onClick={AddAwards}
                            >
                              <i
                                className={`ki-duotone ki-${
                                  buttonValue?.Awards === 'Add Award'
                                    ? 'message-add'
                                    : 'message-edit'
                                } fs-1`}
                              >
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>

                              {buttonValue?.Awards}
                            </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col p-0'>
            <Divider layout='vertical' className='mx-0' />
          </div>
          <div className='col-6 p-0'>
            {array?.facultyAwards?.length !== 0 && (
              <div className='card border card-custom'>
                <div className='card-header '>
                  <div className='card-toolbar w-100 m-auto text-end' style={{display: 'unset'}}>
                    <i
                      className={`${
                        flag?.Awards ? 'fa-solid fa-check text-success' : 'fa-solid fa-pen'
                      } fs-2`}
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.Awards
                          ? setFlag({...flag, Awards: false})
                          : setFlag({...flag, Awards: true})
                      }}
                    ></i>
                  </div>
                </div>
                <div className='card-body card-scroll h-200px py-5'>
                  <div className='row'>
                    {array?.facultyAwards?.map((item, index) => {
                      return (
                        <div key={item._id} className='col-12 mb-5 border-bottom'>
                          <div className='d-flex align-items-baseline justify-content-between'>
                            <div className='d-md-flex' style={{gap: '20px'}}>
                              <div className=''>
                                <h3 className='m-0 fw-bold fs-5'>{item?.title}</h3>
                                <div className='fs-7'>{item?.awardYear}</div>
                                <div className='fw-bold text-muted fs-8'>{item?.shortDescr}</div>
                              </div>
                            </div>
                            <div>
                              {flag?.Awards ? (
                                <div className='d-flex align-items-center'>
                                  <button
                                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                    type='button'
                                  >
                                    <i
                                      className='fa-solid fa-pen fs-5  '
                                      onClick={() => {
                                        setArray({...array, awards: item})
                                        setIndex({
                                          ...index,
                                          Awards: index,
                                        })
                                        SetButtonValue({
                                          ...buttonValue,
                                          Awards: 'Edit',
                                        })
                                      }}
                                    ></i>
                                  </button>
                                  <i
                                    className='fa-solid fs-5 text-danger fa-trash-can'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      let data = array?.facultyAwards
                                      data.splice(index, 1)
                                      setArray({
                                        ...array,
                                        facultyAwards: data,
                                      })
                                    }}
                                  ></i>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ),
      label: 'Awards',
    },
  ]
  console.log(initialValues)
  console.log(formik.values)
  return (
    <>
      <div className='card pt-4'>
        {/* <div className='col-12 rounded'> */}
        {/* <div className='card-header'> */}
        <Steps model={stepModel} activeIndex={activeStep} readOnly={false} className='pt-4' />
        <div className='card-title mb-3 mt-4 ms-0'>
          <h4>{stepModel[activeStep].label}</h4>
        </div>
        {stepModel[activeStep].content()}
      </div>
      <div className='col mt-4 text-end'>
        <Button
          label={activeStep < stepModel.length - 1 ? 'Save & Next' : 'Add Faculty'}
          rounded
          // outlined
          severity='info'
          // icon={<AddIcon />}
          style={{
            borderRadius: '2rem',
            // width: '15%',
          }}
          className='align-self-center my-2'
          loading={loading}
          onClick={() => {
            if (activeStep < 4) {
              setActiveStep((n) => n + 1)
              // return
            }

            if (activeStep === 4 && images?.facultyimage && formik.isValid) {
              formik.handleSubmit()
              setFlag({
                ...flag,
                facultyPublication: true,
                facultyArticles: true,
                facultyAwards: true,
                facultyCertification: true,
              })
            }
          }}
        />
        {/* <button
          type='submit'
          className='btn btn-primary'
          onClick={() => {
            console.log(array)
            console.log(images)
            console.log(formik)
            if (images?.facultyimage && formik.isValid) {
              formik.handleSubmit()
              setFlag({
                ...flag,
                facultyPublication: true,
                facultyArticles: true,
                facultyAwards: true,
                facultyCertification: true,
              })
            }
          }}
        >
          {!loading && (
            <span className='indicator-label d-flex gap-2'>
              <i className='ki-duotone ki-message-add fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>{' '}
              Add Faculty
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button> */}
      </div>
    </>
  )
}
// Generate an array of year options
export function generateYearOptions() {
  const currentYear = new Date().getFullYear()
  const yearOptions: any = []
  for (let year = 1900; year <= currentYear + 1; year++) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    )
  }
  return yearOptions
}
