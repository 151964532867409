import {createSlice} from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
  // variables
  course: [],
  author: [],
  category: [],
  academic: [],
  subjectFilter: {
    // streamId: 0,
    // classId: 0,
    semester_id: [0],
  },
}

export const CourseSlice = createSlice({
  name: 'CourseSlice',
  initialState,
  reducers: {
    setCourse: (state, action) => {
      state.course = action.payload
    },
    setAuthor: (state, action) => {
      state.author = action.payload
    },
    setCategory: (state, action) => {
      state.category = action.payload
    },
    setAcademic: (state, action) => {
      state.academic = action.payload
    },
    setSubjectFilter: (state, action) => {
      state.subjectFilter = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setCourse, setAuthor, setCategory, setAcademic, setSubjectFilter} =
  CourseSlice.actions
export default CourseSlice.reducer
