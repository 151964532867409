import React, {useEffect, useRef, useState} from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {setNotice} from '../../../redux/AcademicSlice'
import {useSelector} from 'react-redux'
import {Link, useSearchParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import {
  useAddNoticeMutation,
  useLazyAcademicFilterQuery,
  useLazyGetNoticeDetailsQuery,
} from '../../../Api/allApi'
import Select from 'react-select'
import {langs, noticeOption} from '../../SunEditorConfiguration/config'
import {InputText} from 'primereact/inputtext'
import {Calendar} from 'primereact/calendar'
import {Editor, EditorTextChangeEvent} from 'primereact/editor'
import {MultiSelect, MultiSelectChangeEvent} from 'primereact/multiselect'
import {InputSwitch} from 'primereact/inputswitch'
const subjectOptionsTemplate = (option) => {
  return (
    <div className='d-flex align-items-start'>
      <div>{option.label}</div>
    </div>
  )
}
const noticeValidationSchema = Yup.object().shape({
  title: Yup.string().required('Notice Title is required'),
  start_date: Yup.date().required('Start Date is required'),
  end_date: Yup.date()
    .required('End Date is required')
    .min(Yup.ref('start_date'), 'End Date must be after Start Date'),
  start_time: Yup.string(),
  end_time: Yup.string().test({
    name: 'endTime',
    test: function (value) {
      const startTime = this.parent.startTime // Access the value of startTime

      // Check if endTime is required based on the presence of startTime
      if (startTime && startTime.trim() !== '') {
        return !!value && value.trim() !== ''
      }

      return true // No validation needed if startTime is not provided
    },
    message: 'End Time is required when Start Time is specified',
  }),
  classId: Yup.array()
    .of(Yup.number().typeError('Available for classes must be a number'))
    .required('Available for classes is required')
    .min(1, 'Atleast 1 class is required'),
  // noticeType: Yup.string().required('Notice Type is required'),
})

export default function AddNotice({id}) {
  console.log(id)
  const editor: any = useRef()

  // Options for dropdown selects
  const [optionsData, setOptionData]: any = useState({
    stream: [],
    streamId: 0,
    selectedStream: {value: '', label: ''},
    class: [],
    classId: [],
    selectedClass: [],
  })
  console.log('hello')
  // get data from redux
  const {notice} = useSelector((state: any) => state.AcademicSlice)
  console.log(notice)

  const [disableSelection, setDisableSelection] = useState(false)

  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues]: any = useState({
    notice_id: 0,
    classId: [],
    title: '',
    attachments: [],
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    show_to_all: false,
    noticeHTML: '',
  })
  // Query data for filtering
  const [filterTrigger, fResult] = useLazyAcademicFilterQuery()
  const {isSuccess: isFSuccess, isFetching: isFFetching} = fResult
  // add notice api
  const [addNotice, result] = useAddNoticeMutation()
  const {isSuccess, isLoading, isError, error} = result
  // get notice details api
  const [noticeDetail, noticeDetailResult] = useLazyGetNoticeDetailsQuery()
  const {isSuccess: isDetails, isFetching: isNotDetails} = noticeDetailResult
  const [classArray, setClassArray] = useState([])
  // Trigger filter and faculty data loading
  useEffect(() => {
    filterTrigger({})
  }, [filterTrigger])
  // event details api
  useEffect(() => {
    console.log('id')
    if (id) {
      noticeDetail(Number(id))
    }
  }, [id, noticeDetail])

  // call when all data fill well
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: noticeValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        const startDate = moment(values.start_date).format('YYYY-MM-DD')
        const endDate = moment(values.end_date).format('YYYY-MM-DD')
        const startTime = moment(values.start_time).format('H:mm:ss')
        const endTime = moment(values.end_time).format('H:mm:ss')
        let payload = {
          notice_id: id ? Number(id) : null,
          class_ids: values?.classId,
          title: values?.title,
          content: values?.noticeHTML,
          attachments: values?.noticePDF,
          start_date: startDate,
          end_date: endDate,
          start_time: startTime,
          end_time: endTime,
          show_to_all: disableSelection,
        }
        addNotice(payload)
        setSubmitting(false)
        setLoading(false)
      } catch (error) {
        console.error('Error:', error)
        setStatus('Failed to perform the operation')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  console.log(id)
  // call when api returns status 200
  useEffect(() => {
    if (isSuccess && !isLoading) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Notice updated successfully!',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isSuccess, isLoading])
  useEffect(() => {
    console.log('fresult usestate')
    let stream: any = []
    if (isFSuccess && !isFFetching) {
      const refinedData = fResult.data.stream
        .filter((stream) => fResult.data.class[stream.dbId])
        .map((stream) => ({
          label: stream.name,
          code: stream.dbId,
          items: fResult.data.class[stream.dbId].map((cls) => ({
            label: cls.name,
            value: cls.dbId,
          })),
        }))
      setClassArray(refinedData)
      // if (id) {
      //   const findData = notice.find((item) => item?.notice_id === Number(id))
      //   console.log(findData)
      //   if (findData) {
      //     console.log(fResult)
      //     const selectedStream = fResult?.data?.stream.find(
      //       (item) => item?.dbId === findData?.notice_id
      //     )
      //     console.log(selectedStream)
      //     const classArray = fResult?.data?.class[selectedStream?.dbId]?.map((item) => {
      //       return {label: item?.name, value: item?.dbId}
      //     })
      //     console.log(classArray)
      //     const selectedClass = findData.class_ids.map((clId) =>
      //       fResult?.data?.class[selectedStream?.dbId]?.find((item) => item?.dbId === clId)
      //     )
      //     console.log(selectedClass)
      //     setOptionData((prevData) => {
      //       console.log(prevData)
      //       return {
      //         ...prevData,
      //         stream: stream,
      //         class: classArray,
      //         selectedStream: {label: selectedStream?.name, value: selectedStream?.dbId},
      //         streamId: selectedStream?.dbId,
      //         selectedClass: {label: selectedClass?.name, value: selectedClass?.dbId},
      //         classId: selectedClass?.dbId,
      //       }
      //     })
      //     formik.setFieldValue('classId', selectedClass?.dbId)
      //     formik.setFieldValue('streamId', selectedStream?.dbId)
      //   }
      // }
    }
  }, [isFSuccess, isFFetching, fResult.data])
  useEffect(() => {
    if (isDetails && !isNotDetails) {
      console.log(noticeDetailResult?.data)
    }
  }, [isDetails, isNotDetails, noticeDetailResult?.data])

  // call when API returns error
  useEffect(() => {
    console.log('error usestate')
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error])

  useEffect(() => {
    if (disableSelection) {
      formik.setFieldValue('classId', [0])
    } else formik.setFieldValue('classId', [])
  }, [disableSelection])

  const handleStreamSelect = (e) => {
    formik.setFieldValue('streamId', e.value)
    let classObj = []
    const classData = fResult.data.class[e.value]
    if (classData && classData.length > 0) {
      classObj = classData.map((item) => ({
        value: item.dbId,
        label: item.name,
      }))
      let data = {
        ...optionsData,
        streamId: e.value,
        selectedStream: {label: e.label, value: e.value},
        class: classObj,
        classId: [],
        selectedClass: [],
      }
      setOptionData(data)
    } else {
      let data = {
        ...optionsData,
        streamId: e.value,
        selectedStream: {label: e.label, value: e.value},
        class: [],
        classId: [],
        selectedClass: [],
      }
      setOptionData(data)
    }
  }
  const handleCLassSelect = (selectedClasses) => {
    let data = {
      ...optionsData,
      selectedClass: selectedClasses,
      classId: selectedClasses.map((classObj) => classObj.value),
    }

    setOptionData(data)
    // Assuming formik.values.classId is an array
    formik.setFieldValue('classId', data.classId)
  }
  const handleCheckboxChange = () => {
    setDisableSelection(!disableSelection)
    let stream: any = []
    fResult?.data?.stream?.map((item) => {
      stream.push({value: item?.dbId, label: item?.name})
      return item
    })
    setOptionData({
      stream: stream,
      streamId: 0,
      selectedStream: {value: '', label: ''},
      class: [],
      classId: [],
      selectedClass: [],
    })
  }
  const handlePdfUpload = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        formik.setFieldValue('noticePDF', [reader.result])
      }
      reader.readAsDataURL(file)
    }
  }
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor
  }
  const handelEditorCHange = (content: any) => {
    const escapedContent = content.replace(/"/g, '/"').replace(/'/g, "/'")
    formik.setFieldValue('noticeHTML', escapedContent)
  }
  const groupedItemTemplate = (option) => {
    return (
      <div className='d-flex align-items-center'>
        <div className='fw-bold'>{option.label}</div>
      </div>
    )
  }
  const handleClassSelect = (e: MultiSelectChangeEvent) => {
    console.log(e.value)
    // const classIds = e.value.map((item) => item.code)
    // console.log(classIds)
    // const data = {
    //   ...formValues,
    //   available_for_classes: e.value,
    // }
    // setformValues(data)
    formik.setFieldValue('classId', e.value)
  }
  console.log(formik)
  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='notice'>
            <div className='row'>
              {/* notice */}
              <div className='col-12 mb-5'>
                <label className='form-label'>Notice*</label>
                <InputText
                  id='title'
                  {...formik.getFieldProps('title')}
                  placeholder='Add Notice'
                  // className='w-100'
                  name='title'
                  className={clsx(
                    'w-100 form-control bg-transparent',
                    {'is-invalid': formik.touched.title && formik.errors.title},
                    {
                      'is-valid': formik.touched.title && !formik.errors.title,
                    }
                  )}
                  // value={formValues.type || ''}
                  // onChange={(e) => {
                  //   // setformValues({...formValues, type: e.target.value})
                  //   formik.setFieldValue('type', e.target.value)
                  // }}
                  // invalid={formik.touched.type && formik.errors.type}
                />

                {/* <input
                  type='text'
                  placeholder='Add Notice'
                  {...formik.getFieldProps('title')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.title && formik.errors.title},
                    {
                      'is-valid': formik.touched.title && !formik.errors.title,
                    }
                  )}
                /> */}
              </div>
              {/* startDate */}
              <div className='col-md-6 col-12 mb-5 position-relative'>
                <label htmlFor='start_date' className='form-label'>
                  Start Date*
                </label>
                <Calendar
                  showButtonBar
                  {...formik.getFieldProps('start_date')}
                  name='start_date'
                  inputId='start_date'
                  dateFormat='yy-mm-dd'
                  maxDate={formik.values.end_Date}
                  inputStyle={{
                    width: '85%',
                  }}
                  className={clsx(
                    'form-control bg-transparent p-0 border-0',
                    {
                      'is-invalid': formik.touched.start_date && formik.errors.start_date,
                    },
                    {
                      'is-valid': formik.touched.start_date && !formik.errors.start_date,
                    }
                  )}
                  showIcon
                />
              </div>
              {/* endDate */}
              <div className='col-md-6 col-12 mb-5  position-relative'>
                <label htmlFor='end_date' className='form-label'>
                  End Date*
                </label>
                <Calendar
                  showButtonBar
                  {...formik.getFieldProps('end_date')}
                  name='end_date'
                  inputId='end_date'
                  dateFormat='yy-mm-dd'
                  maxDate={formik.values.end_Date}
                  inputStyle={{
                    width: '85%',
                  }}
                  // inputClassName='w-100'
                  className={clsx(
                    'form-control bg-transparent p-0 border-0',
                    {
                      'is-invalid': formik.touched.end_date && formik.errors.end_date,
                    },
                    {
                      'is-valid': formik.touched.end_date && !formik.errors.end_date,
                    }
                  )}
                  showIcon
                  minDate={formik.values?.start_date}
                />
              </div>
              {/* startTime */}
              <div className='col-md-6 mb-5 position-relative'>
                <label htmlFor='start_time' className='form-label'>
                  Start Time
                </label>
                <Calendar
                  {...formik.getFieldProps('start_time')}
                  name='start_time'
                  // invalid={formik.touched.start_time && formik.errors.start_time}
                  showButtonBar
                  className={clsx(
                    'form-control bg-transparent p-0 border-0',
                    {
                      'is-invalid': formik.touched.start_time && formik.errors.start_time,
                    },
                    {
                      'is-valid': formik.touched.start_time && !formik.errors.start_time,
                    }
                  )}
                  inputId='start_time'
                  inputStyle={{
                    width: '85%',
                  }}
                  showTime
                  hourFormat='12'
                  // value={formValues.startTime}
                  onChange={(e) => {
                    // setformValues({...formValues, startTime: e.value})
                    formik.setFieldValue('start_time', e.value)
                  }}
                  showIcon
                  timeOnly
                />
              </div>
              {/* endTime */}
              <div className='col-md-6 mb-5 position-relative'>
                <label htmlFor='end_time' className='form-label'>
                  End Time
                </label>
                <Calendar
                  {...formik.getFieldProps('end_time')}
                  name='end_time'
                  // invalid={formik.touched.start_time && formik.errors.start_time}
                  showButtonBar
                  className={clsx(
                    'form-control bg-transparent p-0 border-0',
                    {
                      'is-invalid': formik.touched.end_time && formik.errors.end_time,
                    },
                    {
                      'is-valid': formik.touched.end_time && !formik.errors.end_time,
                    }
                  )}
                  inputId='end_time'
                  inputStyle={{
                    width: '85%',
                  }}
                  showTime
                  hourFormat='12'
                  // value={formValues.startTime}
                  onChange={(e) => {
                    // setformValues({...formValues, startTime: e.value})
                    formik.setFieldValue('end_time', e.value)
                  }}
                  showIcon
                  timeOnly
                />
              </div>
              {/* rich editor */}
              <div className='mb-5 col-12'>
                <label htmlFor='noticeHTML'>Description:</label>
                <Editor
                  {...formik.getFieldProps('noticeHTML')}
                  id='noticeHTML'
                  name='noticeHTML'
                  // value={formValues.details}
                  onTextChange={(e: EditorTextChangeEvent | any) => {
                    const escapedContent = e.htmlValue.replace(/"/g, '/"').replace(/'/g, "/'")
                    // setformValues({...formValues, details: e.htmlValue})
                    formik.setFieldValue('noticeHTML', escapedContent)
                  }}
                  placeholder='Event Details'
                  style={{height: '175px'}}
                />
              </div>
              {/* show to all */}
              <div className='col-12 d-flex flex-column mb-5'>
                {/* <div className='mb-3 form-check'> */}
                <label className='form-check-label' htmlFor='disableSelectionCheckbox'>
                  Show to all
                </label>
                <InputSwitch
                  checked={disableSelection}
                  onChange={handleCheckboxChange}
                  id='disableSelectionCheckbox'
                  // className='form-check-input'
                />
              </div>
              {!disableSelection && (
                <div className='col-12 mb-5'>
                  <label htmlFor='classId' className='form-label'>
                    Available For Classes
                  </label>
                  <MultiSelect
                    {...formik.getFieldProps('classId')}
                    name='classId'
                    // invalid={
                    //   formik.touched.available_for_classes && formik.errors.available_for_classes
                    // }
                    value={formik.values.classId.length > 0 ? formik.values.classId : null}
                    options={classArray}
                    onChange={handleClassSelect}
                    optionLabel='label'
                    optionGroupLabel='label'
                    optionGroupChildren='items'
                    optionGroupTemplate={groupedItemTemplate}
                    selectedItemsLabel={
                      formik.values.classId &&
                      formik.values.classId.length &&
                      classArray.flatMap((cls: any) => cls.items).length ===
                        formik.values.classId.length
                        ? 'All Classes Selected'
                        : `${formik.values.classId.length} Classes Selected`
                    }
                    selectAllLabel='Select All Classes'
                    maxSelectedLabels={0}
                    itemTemplate={subjectOptionsTemplate}
                    className='w-100'
                    inputId='classId'
                    filter
                    // disabled={!filterData?.selectedSemester.value}
                  />
                </div>
              )}

              {/* attachment */}
              <div className='col-12 mb-5'>
                <label className='form-label'>PDF File</label>
                <input
                  type='file'
                  accept='.pdf,image/*'
                  onChange={(e) => handlePdfUpload(e)}
                  className='form-control'
                />
              </div>
              {/* submit */}
              <div className='col-12 text-end mt-5 mb-5'>
                <button
                  type='submit'
                  className='btn btn-primary '
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && (
                    <span className='indicator-label d-flex gap-2'>
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>{' '}
                      {id ? 'Save' : 'Add'} Notice
                    </span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
