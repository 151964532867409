import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useAddAuthorMutation, useLazyAllAuthorQuery} from '../../Api/allApi'
import Swal from 'sweetalert2'
import {setAuthor} from '../../redux/authorSlice'
import {useDispatch} from 'react-redux'
const Author = Yup.object().shape({
  authorName: Yup.string().required('Name is required'),
  authorImg: Yup.string().required('Image is required'),
  about: Yup.string()
    .required('About is required')
    .max(500, 'About must be at most 500 characters'),
})
/* eslint-disable jsx-a11y/anchor-is-valid */
const AuthorModel = ({name, data}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({authorName: '', about: '', authorImg: ''})
  // add author api
  const [addAuthor, result] = useAddAuthorMutation()
  const {isSuccess, isLoading, isError, error} = result
  // get author list api
  const [authorList, authorResult] = useLazyAllAuthorQuery()
  const {isSuccess: isAuthor, isFetching: isNotAuthor} = authorResult
  // when user submit form
  const formik = useFormik({
    initialValues,
    enableReinitialize: data,
    validationSchema: Author,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          dbId: data?.dbId || null,
          authorName: values?.authorName,
          about: values?.about,
          authorImg: values?.authorImg,
        }
        addAuthor(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  // call when api calling done successfully
  useEffect(() => {
    if (isSuccess && !isLoading) {
      authorList({})
    }
  }, [isSuccess, isLoading, authorList])

  useEffect(() => {
    if (isAuthor && !isNotAuthor) {
      dispatch(setAuthor(result?.data?.blogAuthorList))
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your record has been Added',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isAuthor, isNotAuthor, dispatch, result?.data?.blogAuthorList])
  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error])
  // change image to base64
  const onHandleChangeFile: any = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      formik.setFieldValue('authorImg', base64String)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setInitialValues({
        authorName: data?.authorName,
        about: data?.about,
        authorImg: data?.authorImg,
      })
    }
  }, [data])
  return (
    <div className='modal-dialog modal-dialog-centered mw-1000px'>
      <div className='modal-content'>
        <div className='modal-header py-7 d-flex justify-content-between'>
          <h2>
            {data?.authorName !== '' ? 'Edit' : 'Add'} {name}
          </h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
          </div>
        </div>
        <div className='modal-body scroll-y '>
          <div className='stepper stepper-links d-flex flex-column' id={name}>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='Author'>
                  <div className='row'>
                    <div className='col-12 mb-7'>
                      <div className='fs-6 text-muted'>
                        <span className='text-danger'>*</span> indicates required{' '}
                      </div>
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Author Name<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        placeholder='Add Author Name'
                        {...formik.getFieldProps('authorName')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.authorName && formik.errors.authorName,
                          },
                          {
                            'is-valid': formik.touched.authorName && !formik.errors.authorName,
                          }
                        )}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        About<span className='text-danger'>*</span>
                      </label>
                      <textarea
                        maxLength={500}
                        placeholder='Add About'
                        {...formik.getFieldProps('about')}
                        className={clsx(
                          'form-control bg-transparent',
                          {'is-invalid': formik.touched.about && formik.errors.about},
                          {
                            'is-valid': formik.touched.about && !formik.errors.about,
                          }
                        )}
                        rows={3}
                      ></textarea>
                      <span className='fs-7 text-danger'>{`${
                        500 - formik.values.about.length
                      } characters remaining`}</span>
                      {formik?.touched?.about && formik?.errors?.about && (
                        <span className='fs-7 text-danger'>{formik?.errors?.about}</span>
                      )}
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Author Image<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='file'
                        onChange={onHandleChangeFile}
                        className={clsx(
                          'form-control bg-transparent',
                          {'is-invalid': formik.touched.authorImg && formik.errors.authorImg},
                          {
                            'is-valid': formik.touched.authorImg && !formik.errors.authorImg,
                          }
                        )}
                      />
                      {formik?.values?.authorImg ? (
                        <img
                          src={formik?.values?.authorImg}
                          className='w-50px h-50px mt-2 rounded'
                          alt='credential'
                        />
                      ) : null}
                    </div>
                    <div className='col-12 border-top text-end'>
                      <button
                        type='submit'
                        data-bs-dismiss={'modal'}
                        className='btn btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className='indicator-label d-flex align-items-center gap-2'>
                            {data?.authorName !== '' ? (
                              <i className='ki-duotone ki-message-edit fs-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                            ) : (
                              <i className='ki-duotone ki-message-add fs-1'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                            )}
                            {data?.authorName !== '' ? 'Save' : 'Add'} Author
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthorModel}
