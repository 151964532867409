import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
// import Select from 'react-select'
// import {WithContext as ReactTags} from 'react-tag-input'
import '../Achievement.css'
import clsx from 'clsx'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {useAddOrganizationMutation, useLazyAdminOrganizationQuery} from '../../../Api/allApi'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {setOrganization} from '../../../redux/achivementSlice'
import Swal from 'sweetalert2'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import {Button} from 'primereact/button'
const Companies = Yup.object().shape({
  organisationName: Yup.string().required('organisationName is required'),
  industry: Yup.string().required('industry is required'),
  headquarterLocation: Yup.string().required('headquarterLocation is required'),
  employeeCount: Yup.number().required('employeeCount is required'),
})
export default function AddCompony() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // get data from redux
  const {organization}: any = useSelector((state: any) => state?.achievementSlice)
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues]: any = useState({
    organisationName: '',
    industry: '',
    headquarterLocation: '',
    employeeCount: 0,
  })
  const [images, setImages]: any = useState({})
  // add api
  const [addOrganizationTrigger, result] = useAddOrganizationMutation()
  const {isSuccess, isLoading, isError, error} = result
  // organization api
  const [organizationTrigger, aResult] = useLazyAdminOrganizationQuery()
  const {isSuccess: isASuccess, isFetching} = aResult

  // change image to base64
  const onHandleChangeFile: any = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      setImages({
        ...images,
        [e.target.name]: base64String,
      })
    }
    if (file) {
      reader.readAsDataURL(file)
      setImages({
        ...images,
        [e.target.name]: file,
      })
    }
  }

  console.log(id)
  // call when all data fill well
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Companies,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload: any = {}
        payload = {
          organisationName: values?.organisationName,
          industry: values?.industry,
          headquarterLocation: values?.headquarterLocation,
          employeeCount: values?.employeeCount,
          organisationImg: images?.ComponyImg,
        }
        if (id) {
          payload = {
            ...payload,
            dbId: initialValues?.dbId,
          }
        } else {
          payload = {
            ...payload,
            dbId: null,
          }
        }

        addOrganizationTrigger(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  // call when data added successfully
  useEffect(() => {
    if (isSuccess && !isLoading) {
      organizationTrigger({})
    }
  }, [isSuccess, isLoading, organizationTrigger])
  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error])
  useEffect(() => {
    if (isASuccess && !isFetching) {
      dispatch(setOrganization(result?.data?.organisationList))
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your data has been saved',
        showConfirmButton: false,
        timer: 1500,
      })
      navigate('/achievements/Organizations')
    }
  }, [isASuccess, isFetching, result, dispatch, result?.data?.organisationList, navigate])
  useEffect(() => {
    if (id) {
      let filter = organization?.find((item) => item?.dbId === Number(id))
      console.log(filter)
      setInitialValues(filter)
      if (filter) setImages({ComponyImg: filter.organisationImg})
    }
  }, [id, organization])
  return (
    <div className='card card-custom mb-5'>
      <div className='card-header d-flex justify-content-between'>
        <h2 className='card-title fs-2 fw-bold'>
          <Link to='/achievements/Organizations'>
            <button
              className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              aria-label='Back'
              data-bs-original-title='Back'
              data-kt-initialized='1'
            >
              <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </button>
          </Link>
          {id ? 'Edit' : 'Add'} Organisation
        </h2>
      </div>
      <div className='card-body'>
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='certificate'>
          <div className='row'>
            <div className='col-12 mb-7'>
              <div className='fs-6 text-muted'>
                <span className='text-danger'>*</span> indicates required{' '}
              </div>
            </div>
            <div className='col-12 mb-5'>
              <label className='form-label'>
                Organisation Name<span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                name='organisationName'
                {...formik.getFieldProps('organisationName')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.organisationName && formik.errors.organisationName},
                  {
                    'is-valid': formik.touched.organisationName && !formik.errors.organisationName,
                  }
                )}
                placeholder='Ex: Hindustan Aeronautics Limited'
              />
            </div>
            <div className='col-12 mb-5'>
              <label className='form-label'>
                Organisation Logo<span className='text-danger'>*</span>
              </label>
              <input
                type='file'
                name='ComponyImg'
                onChange={onHandleChangeFile}
                className={clsx('form-control bg-transparent', {
                  'is-valid': images?.ComponyImg,
                })}
              />
              {images?.ComponyImg ? (
                <img
                  src={images?.ComponyImg}
                  className='w-50px h-50px mt-2 rounded'
                  alt='credential'
                />
              ) : null}
            </div>
            <div className='col-12 mb-5'>
              <label className='form-label'>
                Industry<span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                name='industry'
                placeholder='Ex: Defence Manufacturing'
                {...formik.getFieldProps('industry')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.industry && formik.errors.industry},
                  {
                    'is-valid': formik.touched.industry && !formik.errors.industry,
                  }
                )}
              />
            </div>
            <div className='col-12 mb-5'>
              <label className='form-label'>
                Headquarter Location<span className='text-danger'>*</span>
              </label>
              <input
                name='headquarterLocation'
                type='text'
                placeholder='Ex: Bangalore'
                {...formik.getFieldProps('headquarterLocation')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.headquarterLocation && formik.errors.headquarterLocation,
                  },
                  {
                    'is-valid':
                      formik.touched.headquarterLocation && !formik.errors.headquarterLocation,
                  }
                )}
              />
            </div>
            <div className='col-12 mb-5'>
              <label className='form-label'>Total Employees</label>
              <input
                name='employeeCount'
                type='number'
                {...formik.getFieldProps('employeeCount')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.employeeCount && formik.errors.employeeCount,
                  },
                  {
                    'is-valid': formik.touched.employeeCount && !formik.errors.employeeCount,
                  }
                )}
              />
            </div>
            <div className='col-12 border-top text-end'>
              <Button
                type='submit'
                label={id ? 'Save Organisation' : 'Add Organisation'}
                rounded
                severity='info'
                loading={loading}
                icon={<DomainAddIcon />}
                style={{
                  borderRadius: '2rem',
                }}
                disabled={formik.isSubmitting || !formik.isValid}
                className='align-self-center my-5 gap-2'
              />
              {/* <button
                type='submit'
                className='btn mt-5 btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && (
                  <span className='indicator-label d-flex gap-2'>
                    {id ? (
                      <i className='ki-duotone ki-message-edit fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    ) : (
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    )}
                    {id ? 'Save' : 'Add'} Organisation
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
