import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'

import * as Yup from 'yup'
import {useLazyAcademicFilterQuery, useAddStudentMutation} from '../../../Api/allApi'
import {Button} from 'primereact/button'
import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import {InputSwitch, InputSwitchChangeEvent} from 'primereact/inputswitch'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {Dropdown, DropdownChangeEvent} from 'primereact/dropdown'
import {Calendar} from 'primereact/calendar'
import {Nullable} from 'primereact/ts-helpers'
function formatDateToYyyyMmDd(date: Nullable<Date>): string | null {
  if (!date) return null

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
const StudentSchema = Yup.object().shape({
  enrollmentId: Yup.string().required('Enrollment Id is required'),
  admissionYear: Yup.string().required('Admission Year is required'),
  rollNumber: Yup.string().required('Roll Number is required'),
  image: Yup.string().required('Image is required'),
  firstName: Yup.string().required('First Name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last Name is required'),
  dob: Yup.date().required('Date of birth is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  contactNo: Yup.string()
    .matches(/^[0-9]+$/, 'Contact number must contain only digits')
    .min(10, 'Contact number must be at least 10 characters')
    .max(10, 'Contact number must not exceed 15 characters')
    .required('Contact number is required'),
  gender: Yup.string().required('Gender is required'),
  bloodGroup: Yup.string().required('Blood group is required'),
  classId: Yup.string().required('class is required'),
  divisionId: Yup.string().required('division is required'),
  streamId: Yup.string().required('stream is required'),
  countryCode: Yup.string().required('Country Code is required'),
  about: Yup.string(),
  fatherName: Yup.string().required(`father name is required`),
  fatherCountryCode: Yup.string().required(`father Country Code is required`),
  fatherContactNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Contact number must contain only digits')
    .min(10, 'Contact number must be at least 10 characters')
    .max(10, 'Contact number must not exceed 15 characters')
    .required('Contact number is required'),
  motherName: Yup.string().required(`mother name is required`),
  motherCountryCode: Yup.string().required(`mother Country Code is required`),
  motherContactNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Contact number must contain only digits')
    .min(10, 'Contact number must be at least 10 characters')
    .max(10, 'Contact number must not exceed 15 characters')
    .required('Contact number is required'),

  tempAddressLine1: Yup.string(),
  tempAddressLine2: Yup.string(),
  tempCity: Yup.string(),
  tempState: Yup.string(),
  tempCountry: Yup.string(),
  tempLandmark: Yup.string(),
  tempPincode: Yup.string(),
  permanentAddressLine1: Yup.string().required('Address Line 1 is required'),
  permanentAddressLine2: Yup.string().required('Address Line 2 is required'),
  permanentCity: Yup.string().required('City is required'),
  permanentState: Yup.string().required('State is required'),
  permanentCountry: Yup.string().required('Country is required'),
  permanentLandmark: Yup.string(),
  permanentPincode: Yup.string().required('pincode is required'),
})

export default function StudentLists() {
  const [initialValues] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    email: '',
    contactNo: '',
    gender: '',
    bloodGroup: '',
    classId: '',
    streamId: '',
    divisionId: '',
    enrollmentId: '',
    countryCode: '',
    about: '',
    image: '',
    fatherName: '',
    fatherCountryCode: '',
    fatherContactNumber: '',
    motherName: '',
    motherCountryCode: '',
    motherContactNumber: '',
    tempAddressLine1: '',
    tempAddressLine2: '',
    tempCity: '',
    tempState: '',
    tempCountry: '',
    tempLandmark: '',
    tempPincode: '',
    permanentAddressLine1: '',
    permanentAddressLine2: '',
    permanentCity: '',
    permanentState: '',
    permanentCountry: '',
    permanentLandmark: '',
    permanentPincode: '',
    admissionYear: '',
    rollNumber: '',
  })
  const [filterData, setFilterData]: any = useState({
    stream: [],
    streamId: 0,
    selectedStream: null,
    class: [],
    classId: 0,
    selectedClass: {value: '', label: ''},
    division: [],
    divisionId: 0,
    selectedDivision: {value: '', label: ''},
  })
  const [loading, setLoading] = useState(false)
  const [studentImage, setStudentImage] = useState<any>('')

  const [addStudent, addStudentResult] = useAddStudentMutation()
  const {isLoading, isSuccess: isAddStudentSuccess} = addStudentResult

  // filter api
  const [filterTrigger, result] = useLazyAcademicFilterQuery()
  const {isSuccess, isFetching} = result
  const [sameAsAbove, setSameAsAbove] = useState<boolean>(false)
  useEffect(() => {
    filterTrigger({})
  }, [filterTrigger])

  // when api calling is done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      const data = result?.data
      console.log(data)
      let streams = data.stream?.map((item) => {
        return {value: item?.dbId, label: item?.name}
      })
      setFilterData((prevData) => {
        return {
          ...prevData,
          stream: streams,
        }
      })
    }
  }, [isSuccess, isFetching, result?.data])

  useEffect(() => {
    if (!isLoading && isAddStudentSuccess) {
      console.log(addStudentResult.data)
    }
  }, [isLoading, isAddStudentSuccess, addStudentResult.data])

  // call when all data fill well
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: StudentSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let payload = {
          student_id: null,
          enrollment_id: values.enrollmentId,
          admission_year: values.admissionYear,
          roll_number: values.rollNumber,
          first_name: values?.firstName,
          middle_name: values.middleName,
          last_name: values?.lastName,
          dob: formatDateToYyyyMmDd(values?.dob as any),
          email_id: values?.email,
          phone_number: values?.contactNo,
          gender: values?.gender,
          blood_group: values?.bloodGroup,
          class_id: values?.classId,
          stream_id: values?.streamId,
          division: values?.divisionId,
          country_code: values.countryCode,
          about: values.about,
          profile_img: values.image,
          father_name: values.fatherName,
          mother_name: values.motherName,
          father_country_code: values.fatherCountryCode,
          father_phone_number: values.fatherContactNumber,
          mother_country_code: values.motherCountryCode,
          mother_phone_number: values.motherContactNumber,

          per_address_line_1: values.permanentAddressLine1,
          per_address_line_2: values.permanentAddressLine2,
          per_address_city: values.permanentCity,
          per_address_state: values.permanentState,
          per_address_country: values.permanentCountry,
          per_address_landmark: values.permanentLandmark,
          per_address_pincode: values.permanentPincode,
          temp_address_line_1: values.tempAddressLine1,
          temp_address_line_2: values.tempAddressLine2,
          temp_address_city: values.tempCity,
          temp_address_state: values.tempState,
          temp_address_country: values.tempCountry,
          temp_address_landmark: values.tempLandmark,
          temp_address_pincode: values.tempPincode,
        }
        console.log(payload)
        addStudent(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleStreamSelect = (e) => {
    console.log(e)
    const streamData: any = filterData?.stream?.find((_: any) => _?.value === e.value)
    const classData = result.data.class[e.value]
    const classObj =
      classData && classData.length > 0
        ? classData.map((item) => ({value: item.dbId, label: item.name}))
        : []

    const data = {
      ...filterData,
      streamId: e.value,
      selectedStream: {label: streamData.label, value: e.value},
      class: classObj,
      classId: 0,
      selectedClass: {value: '', label: ''},
      division: [],
      divisionId: 0,
      selectedDivision: {value: '', label: ''},
    }
    console.log(data)
    setFilterData(data as any)
    formik.setFieldValue('streamId', e.value)
  }

  const handleClassSelect = (e) => {
    const classData = filterData.class.find((_) => _.value === e.value)
    const divisionData = result.data.division[e.value]
    const division =
      divisionData && divisionData.length > 0
        ? divisionData.map((item) => ({value: item.dbId, label: item.division}))
        : []
    console.log(e)
    const data = {
      ...filterData,
      classId: e.value,
      selectedClass: {label: classData.label, value: e.value},
      division: division,
      divisionId: 0,
      selectedDivision: {value: '', label: ''},
    }
    console.log(data)
    setFilterData(data)
    formik.setFieldValue('classId', e.value)
  }

  const onHandleChangeFile: any = (e: any) => {
    // console.log(articleImg)
    const file = e.target.files[0]

    // console.log(e.target.name)
    console.log(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      setStudentImage(base64String)
      formik.setFieldValue('image', base64String)
    }
    if (file) {
      reader.readAsDataURL(file)
      // setImages({
      //   ...images,
      //   [e.target.name]: file,
      // })
    }
  }

  useEffect(() => {
    if (sameAsAbove) {
      formik.setFieldValue('permanentAddressLine1', formik.values.tempAddressLine1)
      formik.setFieldValue('permanentAddressLine2', formik.values.tempAddressLine2)
      formik.setFieldValue('permanentCity', formik.values.tempCity)
      formik.setFieldValue('permanentState', formik.values.tempState)
      formik.setFieldValue('permanentCountry', formik.values.tempCountry)
      formik.setFieldValue('permanentLandmark', formik.values.tempLandmark)
      formik.setFieldValue('permanentPincode', formik.values.tempPincode)
    }
  }, [sameAsAbove])
  console.log(formik.values)
  console.log(formik.errors)
  return (
    <>
      <div className='card mb-5'>
        <div className='card-header justify-content-end'>
          {/* <h3 className={`card-title align-items-start flex-column `}>
            <span className='card-label fw-bold fs-3 mb-1'>Student</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Manage your Students here</span>
          </h3> */}
          <div className='card-toolbar d-flex align-items-center gap-3'>
            <Button
              label='Upload Student Data'
              rounded
              severity='info'
              icon={<UploadIcon />}
              style={{
                borderRadius: '2rem',
              }}
              // onClick={() => {
              //   document?.getElementById('fileInput')?.click()
              // }}
              className='align-self-center my-5 me-2'
            />
            {/* <button className='btn btn-primary btn-sm'>Upload Student Data</button> */}
            <Button
              label='Download Sample Format'
              rounded
              severity='info'
              icon={<DownloadIcon />}
              style={{
                borderRadius: '2rem',
              }}
              // onClick={() => {
              //   sampleDownload({})
              // }}
              className='align-self-center my-5 me-2'
            />
            {/* <button className='btn btn-primary btn-sm'>Download Sample Format</button> */}
          </div>
        </div>
      </div>
      <div className='col-12 mb-5'>
        <div className=' card card-custom '>
          <div className='card-body py-3'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>
                    Enrollment ID<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='name'
                    placeholder='Enrollment ID'
                    {...formik.getFieldProps('enrollmentId')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.enrollmentId && formik.errors.enrollmentId},
                      {
                        'is-valid': formik.touched.enrollmentId && !formik.errors.enrollmentId,
                      }
                    )}
                  />
                </div>
              </div>
              <div className='row'>
                {/* stream */}
                <div className='mb-5 col-md-4'>
                  <label className='form-label'>
                    Select Stream<span className='text-danger'>*</span>
                  </label>
                  <Dropdown
                    value={filterData?.selectedStream?.value}
                    options={filterData?.stream}
                    onChange={(e: DropdownChangeEvent) => {
                      handleStreamSelect(e)
                    }}
                    optionLabel='label'
                    placeholder='Select a Stream'
                    className='w-100'
                    loading={!isSuccess}
                  />
                  {/* end::Menu 2 */}
                </div>
                {/* class */}
                <div className='col-md-4 mb-5'>
                  <label className='form-label'>
                    Select Class<span className='text-danger'>*</span>
                  </label>
                  <Dropdown
                    placeholder='Select a Class'
                    value={filterData?.selectedClass.value}
                    options={filterData?.class}
                    disabled={filterData?.streamId === 0 ? true : false}
                    onChange={(e: any) => {
                      handleClassSelect(e)
                    }}
                    className='w-100'
                    optionLabel='label'
                  />
                </div>
                <div className='col-md-4  mb-5 '>
                  <label className='form-label'>
                    Admission Year<span className='text-danger'>*</span>
                  </label>
                  <Dropdown
                    placeholder='Select Admission Year'
                    value={formik.values.admissionYear || null}
                    options={[
                      {value: '2019', label: '2019'},
                      {value: '2020', label: '2020'},
                      {value: '2021', label: '2021'},
                      {value: '2022', label: '2022'},
                      {value: '2023', label: '2023'},
                      {value: '2024', label: '2024'},
                    ]}
                    onChange={(selectedOption: any) =>
                      formik.setFieldValue('admissionYear', selectedOption.value)
                    }
                    optionLabel='label'
                    className='w-100'
                  />
                </div>
              </div>
              <div className='row'>
                {/* Division */}
                <div className='col-md-4 mb-5'>
                  <label className='form-label'>
                    Select Division<span className='text-danger'>*</span>
                  </label>
                  <Dropdown
                    placeholder='Select a Division'
                    className='w-100'
                    value={filterData?.selectedDivision.value}
                    options={filterData?.division}
                    disabled={filterData?.classId === 0 ? true : false}
                    onChange={(e: any) => {
                      console.log(filterData)
                      // const divData=filterData.
                      setFilterData({
                        ...filterData,
                        selectedDivision: {label: e.label, value: e.value},
                        divisionId: e.value,
                      })
                      formik.setFieldValue('divisionId', e.value ? 1 : 2)
                    }}
                  />
                  {/* <ReactSelect
                    placeholder='select Division'
                    value={
                      filterData?.selectedDivision?.label !== '' &&
                      filterData?.selectedDivision?.value !== ''
                        ? filterData?.selectedDivision
                        : null
                    }
                    options={filterData?.division}
                    isDisabled={filterData?.classId === 0 ? true : false}
                    styles={{
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    onChange={(e: any) => {
                      setFilterData({
                        ...filterData,
                        selectedDivision: {label: e.label, value: e.value},
                        divisionId: e.value,
                      })
                      formik.setFieldValue('divisionId', e.value ? e.value : 0)
                    }}
                  /> */}
                </div>
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>
                    Roll Number<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='rollNumber'
                    placeholder='Roll Number'
                    {...formik.getFieldProps('rollNumber')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.rollNumber && formik.errors.rollNumber},
                      {
                        'is-valid': formik.touched.rollNumber && !formik.errors.rollNumber,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    Upload Image<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='file'
                    name='studentImage'
                    onChange={onHandleChangeFile}
                    className={clsx('form-control bg-transparent', {
                      'is-valid': studentImage,
                    })}
                  />
                </div>
                {studentImage ? (
                  <img src={studentImage} className='w-75px h-75px mt-2 rounded' alt='credential' />
                ) : null}
              </div>
              <div className='row mb-5 border-top border-top-2 pt-6'>
                <h4 className={`card-title align-items-start flex-column `}>Basic Details</h4>
              </div>
              <div className='row'>
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>
                    First Name<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='name'
                    placeholder='First Name'
                    {...formik.getFieldProps('firstName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                      {
                        'is-valid': formik.touched.firstName && !formik.errors.firstName,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>Middle Name</label>
                  <InputText
                    type='text'
                    name='name'
                    placeholder='Middle Name'
                    {...formik.getFieldProps('middleName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.middleName && formik.errors.middleName},
                      {
                        'is-valid': formik.touched.middleName && !formik.errors.middleName,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>
                    Last Name<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='name'
                    placeholder='Last Name'
                    {...formik.getFieldProps('lastName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                      {
                        'is-valid': formik.touched.lastName && !formik.errors.lastName,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 mb-5 position-relative'>
                  <label className='form-label'>
                    Date Of Birth<span className='text-danger'>*</span>
                  </label>
                  <Calendar
                    name='publicationDate'
                    id='publicationDate'
                    {...formik.getFieldProps('dob')}
                    inputStyle={{
                      width: '88.25%',
                    }}
                    dateFormat='yy-mm-dd'
                    className={clsx(
                      'form-control bg-transparent p-0 w-100',
                      {'is-invalid': formik.touched.dob && formik.errors.dob},
                      {
                        'is-valid': formik.touched.dob && !formik.errors.dob,
                      }
                    )}
                    showIcon
                  />
                  {/* <input
                    type='date'
                    name='publicationDate'
                    id='publicationDate'
                    {...formik.getFieldProps('dob')}
                    className={clsx(
                      'form-control bg-transparent p-0',
                      {'is-invalid': formik.touched.dob && formik.errors.dob},
                      {
                        'is-valid': formik.touched.dob && !formik.errors.dob,
                      }
                    )}
                    dateFormat='yy-mm-dd'
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '50%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik?.values?.dob ? formik?.values?.dob : 'Date Of Birth'}
                  </div> */}
                </div>
                <div className='col-md-4  mb-5 '>
                  <label className='form-label'>
                    Gender<span className='text-danger'>*</span>
                  </label>
                  <Dropdown
                    placeholder='Select Gender'
                    options={[
                      {value: 'male', label: 'Male'},
                      {value: 'female', label: 'Female'},
                    ]}
                    value={formik.values.gender || null}
                    onChange={(selectedOption: any) =>
                      formik.setFieldValue('gender', selectedOption.value)
                    }
                    className='w-100'
                  />
                </div>
                <div className='col-md-4  mb-5 '>
                  <label className='form-label'>
                    Blood Group<span className='text-danger'>*</span>
                  </label>
                  <Dropdown
                    placeholder='Select Blood Group'
                    value={formik.values.bloodGroup || null}
                    options={[
                      {value: 'A+', label: 'A+'},
                      {value: 'A-', label: 'A-'},
                      {value: 'B+', label: 'B+'},
                      {value: 'B-', label: 'B-'},
                      {value: 'AB+', label: 'AB+'},
                      {value: 'AB-', label: 'AB-'},
                      {value: 'O+', label: 'O+'},
                      {value: 'O-', label: 'O-'},
                    ]}
                    onChange={(selectedOption: any) =>
                      formik.setFieldValue('bloodGroup', selectedOption.value)
                    }
                    className='w-100'
                  />
                </div>
                <div className='col-md-4  mb-5 '>
                  <label className='form-label'>
                    Email<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='email'
                    name='email'
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {'is-valid': formik.touched.email && !formik.errors.email}
                    )}
                  />
                </div>
                <div className='col-md-4  mb-5 '>
                  <label className='form-label'>
                    Country Code<span className='text-danger'>*</span>
                  </label>
                  <Dropdown
                    placeholder='Select Country Code'
                    value={formik.values.countryCode || null}
                    options={[{value: '+91', label: '🇮🇳 +91'}]}
                    onChange={(selectedOption: any) =>
                      formik.setFieldValue('countryCode', selectedOption.value)
                    }
                    className='w-100'
                  />
                </div>

                <div className='col-md-4  mb-5 '>
                  <label className='form-label'>
                    Contact No<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='contactNo'
                    placeholder='Contact No'
                    {...formik.getFieldProps('contactNo')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.contactNo && formik.errors.contactNo},
                      {'is-valid': formik.touched.contactNo && !formik.errors.contactNo}
                    )}
                  />
                </div>

                <div className='col-md-4 mb-5'>
                  <label className='form-label'>
                    About<span className='text-danger'>*</span>
                  </label>
                  <InputTextarea
                    rows={5}
                    name='about'
                    placeholder='About'
                    {...formik.getFieldProps('about')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.about && formik.errors.about},
                      {'is-valid': formik.touched.about && !formik.errors.about}
                    )}
                  />
                </div>
              </div>
              <div className='row mb-4 border-top border-top-2 pt-6'>
                <h4 className={`card-title align-items-start flex-column `}>Parents Details</h4>
              </div>
              <div className='row'>
                {/* Father Details */}
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>
                    Father Name<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='fatherName'
                    placeholder='Father Name'
                    {...formik.getFieldProps('fatherName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.fatherName && formik.errors.fatherName},
                      {
                        'is-valid': formik.touched.fatherName && !formik.errors.fatherName,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>
                    Country Code<span className='text-danger'>*</span>
                  </label>
                  <Dropdown
                    placeholder='Select Country Code'
                    value={formik.values.fatherCountryCode || null}
                    options={[{value: '+91', label: '🇮🇳 +91'}]}
                    onChange={(selectedOption: any) =>
                      formik.setFieldValue('fatherCountryCode', selectedOption.value)
                    }
                    className='w-100'
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    Contact No<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='contactNo'
                    placeholder='Contact No'
                    {...formik.getFieldProps('fatherContactNumber')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.fatherContactNumber && formik.errors.fatherContactNumber,
                      },
                      {
                        'is-valid':
                          formik.touched.fatherContactNumber && !formik.errors.fatherContactNumber,
                      }
                    )}
                  />
                </div>

                {/* Mother Details */}
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>
                    Mother Name<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='name'
                    placeholder='Mother Name'
                    {...formik.getFieldProps('motherName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.motherName && formik.errors.motherName},
                      {
                        'is-valid': formik.touched.motherName && !formik.errors.motherName,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>
                    Country Code<span className='text-danger'>*</span>
                  </label>
                  <Dropdown
                    placeholder='Select Country Code'
                    value={formik.values.motherCountryCode || null}
                    options={[{value: '+91', label: '🇮🇳 +91'}]}
                    onChange={(selectedOption: any) =>
                      formik.setFieldValue('motherCountryCode', selectedOption.value)
                    }
                    className='w-100'
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    Contact No<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='contactNo'
                    placeholder='Contact No'
                    {...formik.getFieldProps('motherContactNumber')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.motherContactNumber && formik.errors.motherContactNumber,
                      },
                      {
                        'is-valid':
                          formik.touched.motherContactNumber && !formik.errors.motherContactNumber,
                      }
                    )}
                  />
                </div>
              </div>
              <div className='row mb-4 border-top border-top-2 pt-6'>
                <h4 className={`card-title align-items-start flex-column `}>Temporary Address</h4>
              </div>
              <div className='row'>
                <div className='col col-12 mb-5 '>
                  <label className='form-label'>
                    Address Line 1<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='tempaddressLine1'
                    placeholder='Address Line 1'
                    {...formik.getFieldProps('tempAddressLine1')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.tempAddressLine1 && formik.errors.tempAddressLine1,
                      },
                      {
                        'is-valid':
                          formik.touched.tempAddressLine1 && !formik.errors.tempAddressLine1,
                      }
                    )}
                  />
                </div>
                <div className='col col-12 mb-5 '>
                  <label className='form-label'>
                    Address Line 2<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='tempAddressLine2'
                    placeholder='Address Line 2'
                    {...formik.getFieldProps('tempAddressLine2')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.tempAddressLine2 && formik.errors.tempAddressLine2,
                      },
                      {
                        'is-valid':
                          formik.touched.tempAddressLine2 && !formik.errors.tempAddressLine2,
                      }
                    )}
                  />
                </div>
                <div className='col-md-8 col-12 mb-5 '>
                  <label className='form-label'>
                    Landmark<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='tempLandmark'
                    placeholder='Landmark'
                    {...formik.getFieldProps('tempLandmark')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.tempLandmark && formik.errors.tempLandmark,
                      },
                      {
                        'is-valid': formik.touched.tempLandmark && !formik.errors.tempLandmark,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    Pincode<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='tempPincode'
                    placeholder='Pincode'
                    {...formik.getFieldProps('tempPincode')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.tempPincode && formik.errors.tempPincode,
                      },
                      {
                        'is-valid': formik.touched.tempPincode && !formik.errors.tempPincode,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    City<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='tempCity'
                    placeholder='City'
                    {...formik.getFieldProps('tempCity')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.tempCity && formik.errors.tempCity,
                      },
                      {
                        'is-valid': formik.touched.tempCity && !formik.errors.tempCity,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    State<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='tempState'
                    placeholder='State'
                    {...formik.getFieldProps('tempState')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.tempState && formik.errors.tempState,
                      },
                      {
                        'is-valid': formik.touched.tempState && !formik.errors.tempState,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    Country<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='tempCountry'
                    placeholder='Country'
                    {...formik.getFieldProps('tempCountry')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.tempCountry && formik.errors.tempCountry,
                      },
                      {
                        'is-valid': formik.touched.tempCountry && !formik.errors.tempCountry,
                      }
                    )}
                  />
                </div>
              </div>
              <div className='row mb-4 border-top border-top-2 pt-6'>
                <h4 className={`card-title align-items-start flex-column `}>Permanent Address</h4>
              </div>
              <div className='row'>
                <div className='col col-12 mb-5 '>
                  <label className='form-label'>Same as Above</label>
                  <div className='card d-flex justify-content-center'>
                    <InputSwitch
                      checked={sameAsAbove}
                      onChange={(e: InputSwitchChangeEvent) => setSameAsAbove(e.value)}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col col-12 mb-5 '>
                  <label className='form-label'>
                    Address Line 1<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='permanentaddressLine1'
                    placeholder='Address Line 1'
                    {...formik.getFieldProps('permanentAddressLine1')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.permanentAddressLine1 &&
                          formik.errors.permanentAddressLine1,
                      },
                      {
                        'is-valid':
                          formik.touched.permanentAddressLine1 &&
                          !formik.errors.permanentAddressLine1,
                      }
                    )}
                  />
                </div>
                <div className='col col-12 mb-5 '>
                  <label className='form-label'>
                    Address Line 2<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='permanentaddressLine2'
                    placeholder='Address Line 2'
                    {...formik.getFieldProps('permanentAddressLine2')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.permanentAddressLine2 &&
                          formik.errors.permanentAddressLine2,
                      },
                      {
                        'is-valid':
                          formik.touched.permanentAddressLine2 &&
                          !formik.errors.permanentAddressLine2,
                      }
                    )}
                  />
                </div>
                <div className='col-md-8 col-12 mb-5 '>
                  <label className='form-label'>
                    Landmark<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='permanentlandmark'
                    placeholder='Landmark'
                    {...formik.getFieldProps('permanentLandmark')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.permanentLandmark && formik.errors.permanentLandmark,
                      },
                      {
                        'is-valid':
                          formik.touched.permanentLandmark && !formik.errors.permanentLandmark,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    Pincode<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='permanentpincode'
                    placeholder='Pincode'
                    {...formik.getFieldProps('permanentPincode')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.permanentPincode && formik.errors.permanentPincode,
                      },
                      {
                        'is-valid':
                          formik.touched.permanentPincode && !formik.errors.permanentPincode,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    City<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='permanentcity'
                    placeholder='City'
                    {...formik.getFieldProps('permanentCity')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.permanentCity && formik.errors.permanentCity,
                      },
                      {
                        'is-valid': formik.touched.permanentCity && !formik.errors.permanentCity,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    State<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='permanentstate'
                    placeholder='State'
                    {...formik.getFieldProps('permanentState')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.permanentState && formik.errors.permanentState,
                      },
                      {
                        'is-valid': formik.touched.permanentState && !formik.errors.permanentState,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5 '>
                  <label className='form-label'>
                    Country<span className='text-danger'>*</span>
                  </label>
                  <InputText
                    type='text'
                    name='permanentcountry'
                    placeholder='Country'
                    {...formik.getFieldProps('permanentCountry')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.permanentCountry && formik.errors.permanentCountry,
                      },
                      {
                        'is-valid':
                          formik.touched.permanentCountry && !formik.errors.permanentCountry,
                      }
                    )}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='row'>
          <div className='col-6 border-top text-end'>
            <Button
              label='Add Student'
              rounded
              severity='info'
              loading={loading}
              icon={<PersonAddAltIcon />}
              style={{
                borderRadius: '2rem',
              }}
              // onClick={() => {
              //   sampleDownload({})
              // }}
              className='align-self-center my-5 me-2 gap-2'
              disabled={formik.isSubmitting || !formik.isValid}
              onClick={formik.handleSubmit}
            />
            {/* <button
              className='btn btn-primary mt-2'
              disabled={formik.isSubmitting || !formik.isValid}
              onClick={formik.handleSubmit}
            >
              {!loading && (
                <span className='indicator-label d-flex align-items-center justify-content-center gap-2'>
                  <i className='ki-duotone ki-message-add fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                  Add Student
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button> */}
          </div>
        </div>
      </div>
    </>
  )
}
