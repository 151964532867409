import React from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import AcademicCourseHeader from './AcademicCourseHeader'
import AcademicCourse from './Academic'
import AddAcademicsCourse from './AddAcademicsCourse'
import ViewDetails from './ViewDetails'

const admission: Array<PageLink> = [
  {
    title: 'Course',
    path: '/Courses/Academic/View',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export default function AcademicCoursePage() {
  return (
    <div>
      <Routes>
        <Route
          element={
            <>
              <AcademicCourseHeader />
              <Outlet />
            </>
          }
        >
          <Route
            path='View'
            element={
              <>
                <PageTitle breadcrumbs={admission}>Course View</PageTitle>
                <AcademicCourse />
              </>
            }
          />
          <Route index element={<Navigate to='/Courses/Academic/View' />} />
        </Route>
        <Route
          path='ViewDetails'
          element={
            <>
              <PageTitle breadcrumbs={admission}>Course DFetails</PageTitle>
              <ViewDetails />
            </>
          }
        />
        {/* <Route
          path='Add'
          element={
            <>
              <PageTitle breadcrumbs={admission}>Course Add</PageTitle>
              <AddAcademicsCourse />
            </>
          }
        /> */}
      </Routes>
    </div>
  )
}
