import React, {useEffect, useState} from 'react'
import DataTables from '../../DataTable/DataTables'
import {useSelector} from 'react-redux'
import Swal from 'sweetalert2'
// import Tooltip from 'rc-tooltip'
import {useDispatch} from 'react-redux'
import {setNotice} from '../../../redux/AcademicSlice'
import {Link} from 'react-router-dom'
import {
  useDeleteNoticeMutation,
  useGetNoticeMutation,
  useLazyAcademicFilterQuery,
} from '../../../Api/allApi'
import ReactSelect from 'react-select'
import {MultiSelect, MultiSelectChangeEvent} from 'primereact/multiselect'
import {FloatLabel} from 'primereact/floatlabel'
import {DataTable, DataTableFilterMeta} from 'primereact/datatable'
import {Column, ColumnFilterElementTemplateOptions} from 'primereact/column'
import AssignmentIcon from '@mui/icons-material/Assignment'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import CancelIcon from '@mui/icons-material/Cancel'
import {Tag} from 'primereact/tag'
import {Tooltip} from 'primereact/tooltip'
import {Sidebar} from 'primereact/sidebar'
import {Button} from 'primereact/button'
import AddIcon from '@mui/icons-material/Add'
import {Dialog} from 'primereact/dialog'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import AddNotice from './AddNotice'

function formatDateTime(inputDate, inputTime) {
  // Combine date and time into a single string
  const dateTimeString = `${inputDate}T${inputTime}`

  // Create a Date object
  const date = new Date(dateTimeString)

  // Array of month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  // Format date and time
  const formattedDate = `${monthNames[date.getMonth()]} ${date
    .getDate()
    .toString()
    .padStart(2, '0')}, ${date.getFullYear()}`
  const formattedTime = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    // hour12: true,
  })

  // Combine formatted date and time
  return `${formattedDate} at ${formattedTime}`
}

const groupedItemTemplate = (option) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='fw-bold'>{option.label}</div>
    </div>
  )
}

const classOptionsTemplate = (option) => {
  return (
    <div className='d-flex align-items-start'>
      <div>{option.label}</div>
    </div>
  )
}

export default function NoticePage() {
  // get data from redux
  const {notice} = useSelector((state: any) => state.AcademicSlice)
  const dispatch = useDispatch()

  // for filters
  const [filterData, setFilterData]: any = useState({
    classId: [],
  })
  const [classArray, setClassArray] = useState<any>([])
  // filter api
  const [filterTrigger, result] = useLazyAcademicFilterQuery()
  const {isSuccess, isFetching} = result
  // notice api
  const [noticeTrigger, noticeResult] = useGetNoticeMutation()
  const {isSuccess: isNotice, isLoading: isNotNotice} = noticeResult
  // Delete Notice
  const [deleteNoticeTrigger, deleteResult]: any = useDeleteNoticeMutation()
  const {isSuccess: isDelete, isLoading: isNotDelete, isError, error} = deleteResult
  const [visibleRight, setVisibleRight] = useState(false)
  const [sideBarContent, setSideBarContent] = useState({
    title: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    noticeDetails: '',
    showToAll: true,
    availableForClasses: [],
    attachments: [],
  })

  const [visible, setVisible] = useState(false)
  const [modalHeader, setModalHeader] = useState('')
  const [noticeId, setNoticeId] = useState(null)
  const show = () => {
    setVisible(true)
  }
  // filter trigger
  useEffect(() => {
    filterTrigger({})
  }, [filterTrigger])

  useEffect(() => {
    noticeTrigger({
      // streamId: filterData?.streamId,
      class_ids: filterData?.classId || [1, 2, 3, 4],
    })
  }, [filterData, noticeTrigger])
  // when api calling is done successfully
  // useEffect(() => {
  //   if (isSuccess && !isFetching) {
  //     const data = result?.data

  //     let classObj: any = []
  //     const classData = data.class[data?.stream[0]?.dbId]
  //     if (classData && classData.length > 0) {
  //       const classes = classData.map((item) => ({
  //         value: item.dbId,
  //         label: item.name,
  //       }))
  //       classObj = [...classObj, ...classes]
  //     }
  //     let streams = data.stream?.map((item) => {
  //       return {value: item?.dbId, label: item?.name}
  //     })
  //     setFilterData((prevData) => {
  //       return {
  //         ...prevData,
  //         stream: streams,
  //         streamId: [data?.stream[0]?.dbId],
  //         class: classObj,
  //       }
  //     })
  //   }
  // }, [isSuccess, isFetching, result?.data])
  useEffect(() => {
    if (isSuccess && !isFetching) {
      // Get a combined list of all classes from the provided JSON data
      console.log(result.data)
      const refinedData = result.data.stream
        .filter((stream) => result.data.class[stream.dbId])
        .map((stream) => ({
          label: stream.name,
          code: stream.dbId,
          items: result.data.class[stream.dbId].map((cls) => ({
            label: cls.name,
            value: cls.dbId,
          })),
        }))
      setClassArray(refinedData)
    }
  }, [isSuccess, isFetching, result.data])

  useEffect(() => {
    if (isNotice && !isNotNotice) {
      console.log(noticeResult.data)
      dispatch(setNotice(noticeResult?.data?.noticeList))
    }
  }, [isNotice, isNotNotice, noticeResult?.data, dispatch])

  useEffect(() => {
    if (isDelete && !isNotDelete) {
      noticeTrigger({
        class_ids: filterData?.classId,
      })
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDelete, isNotDelete, filterData?.classId, noticeTrigger, deleteResult?.data])

  // call when api returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error])
  // data

  console.log(notice)

  function getStreamClassName(classId) {
    for (const streamId in result.data.class) {
      const stream = result.data.stream.find((s) => s.dbId === parseInt(streamId))

      if (stream) {
        const classObj = result.data.class[streamId].find((cls) => cls.dbId === classId)

        if (classObj) {
          return `${stream.name} - ${classObj.name}`
        }
      }
    }

    return 'Class ID not found'
  }
  const actionBodyTemplate = (row) => {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <Tooltip target={`.view-notice${row.id}`} position='top' />
        <button
          type='button'
          className={`btn btn-sm me-3 view-notice${row.id} p-0`}
          data-pr-tooltip='View Notice'
          onClick={() => {
            const currentRowData = notice.find((_) => _.notice_id === row.id)
            console.log(currentRowData)
            setSideBarContent({
              title: currentRowData.title,
              startDate: currentRowData.start_date,
              startTime: currentRowData.start_time,
              endDate: currentRowData.end_date,
              endTime: currentRowData.end_time,
              noticeDetails: currentRowData.content,
              showToAll: currentRowData.show_to_all,
              availableForClasses: currentRowData.class_ids || [],
              attachments: currentRowData.attachments,
            })
            setVisibleRight(true)
          }}
        >
          <AssignmentIcon />
        </button>

        <Tooltip target={`.edit-notice${row.id}`} position='top' />
        {/* <Link
          to={`/academics/notices/notice?id=${row?.id}`}
          className={`me-3 edit-notice${row.id}`}
          data-pr-tooltip='Edit Notice'
        > */}
        <Button
          type='button'
          onClick={() => {
            setModalHeader('Edit Notice')
            setNoticeId(row.id)
            show()
          }}
          className='btn btn-sm'
        >
          <NoteAltIcon className={`edit-notice${row.id}`} data-pr-tooltip='Edit Notice' />
        </Button>
        {/* </Link> */}

        <Tooltip target={`.delete-notice${row.id}`} position='top' />
        <button
          type='button'
          data-pr-tooltip='Delete Notice'
          className={`btn btn-sm delete-notice${row.id} p-0`}
          onClick={() => {
            Swal.fire({
              title: 'Confirm deletion?',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText:
                '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
              confirmButtonColor: '#009EF7',
              cancelButtonText:
                '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                deleteNotice(row?.id)
              } else if (result.isDenied) {
                Swal.fire('Record is not deleted', '', 'error')
              }
            })
          }}
        >
          <CancelIcon />
        </button>
      </div>
    )
  }

  const tableValues = notice.map((_) => {
    return {
      id: _.notice_id,
      title: _.title,
      startDate: _.start_date,
      endDate: _.end_date,
      showToAll: _.show_to_all ? '✅' : '☑️',
      attachments: _.attachments.length ? '✅' : '☑️',
    }
  })

  const columns = [
    <Column
      field='title'
      showFilterMenu={false}
      align='center'
      sortable
      frozen
      header='Title'
      key='title'
    />,
    <Column
      field='startDate'
      align='center'
      showFilterMenu={false}
      sortable
      header='Start Date'
      key='startDate'
    />,
    <Column
      field='endDate'
      align='center'
      showFilterMenu={false}
      sortable
      header='End Date'
      key='endDate'
    />,
    <Column field='showToAll' align='center' header='Show To All' key='showToAll' />,
    <Column field='attachments' align='center' header='Attachments' key='attachments' />,
    <Column
      header='Actions'
      align='center'
      // headerClassName=''
      body={actionBodyTemplate}
      exportable={false}
      style={{minWidth: '12rem'}}
      key='actions'
    />,
  ]

  //   for delete operation
  const deleteNotice = (dbId) => {
    deleteNoticeTrigger(dbId)
  }

  const handleClassSelect = (e: MultiSelectChangeEvent) => {
    const data = {
      classId: e.value,
    }
    setFilterData(data)
  }
  return (
    <>
      {/* <div className='card mb-5'>
        <div className='card-header'>
          <h3 className={`card-title align-items-start flex-column `}>
            <span className='card-label fw-bold fs-3 mb-1'>Notice</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Check your Notice here</span>
          </h3>
        
        </div>
      </div> */}
      <div className='card align-items-end mb-6 py-5'>
        {/* <Link to='/academics/notices/notice' className='me-6'> */}
        <Button
          label='Add Notice'
          rounded
          severity='info'
          icon={<AddIcon />}
          style={{
            borderRadius: '2rem',
          }}
          className='me-6'
          onClick={() => {
            setModalHeader('Add Notice')
            setNoticeId(null)
            show()
          }}
          // className='align-self-center my-5'
        />
        {/* </Link> */}
      </div>
      <div className='card card-custom mb-5'>
        <div className='card-body py-3 pt-8'>
          <div className='col mb-5'>
            <FloatLabel>
              <MultiSelect
                name='available_for_classes'
                value={filterData.classId.length > 0 ? filterData.classId : null}
                options={classArray}
                onChange={handleClassSelect}
                optionLabel='label'
                optionGroupLabel='label'
                optionGroupChildren='items'
                optionGroupTemplate={groupedItemTemplate}
                selectedItemsLabel={
                  filterData.classId &&
                  filterData.classId.length &&
                  classArray.flatMap((cls) => cls.items).length === filterData.classId.length
                    ? 'All Classes Selected'
                    : `${filterData.classId.length} Classes Selected`
                }
                selectAllLabel='Select All Classes'
                maxSelectedLabels={0}
                itemTemplate={classOptionsTemplate}
                className='w-100'
                inputId='availableClasses'
                filter
                // disabled={!filterData?.selectedSemester.value}
              />
              <label htmlFor='availableClasses'>Available For Classes</label>
            </FloatLabel>
          </div>
          <div className='col mb-5'>
            <DataTable
              value={tableValues}
              showGridlines
              paginator
              rows={20}
              rowsPerPageOptions={[1, 2, 5, 10, 20, 25, 50, 100]}
              size='normal'
              tableStyle={{minWidth: '50rem'}}
              removableSort
              emptyMessage='No Notices have been added for the selected filters.'
              paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
              currentPageReportTemplate='Showing {first} to {last} of {totalRecords} notices'
              scrollable
            >
              {columns}
            </DataTable>
          </div>
        </div>
      </div>

      <Sidebar
        className='customSidebarWidth'
        visible={visibleRight}
        position='right'
        onHide={() => setVisibleRight(false)}
      >
        <h2>{sideBarContent.title}</h2>
        <h5>Start Date: {formatDateTime(sideBarContent.startDate, sideBarContent.startTime)}</h5>
        <h5>End Date: {formatDateTime(sideBarContent.endDate, sideBarContent.endTime)}</h5>
        <div dangerouslySetInnerHTML={{__html: sideBarContent.noticeDetails}} />
        <div className='d-flex gap-5'>
          {sideBarContent.showToAll
            ? '✅'
            : sideBarContent.availableForClasses.map((cl) => (
                <Tag severity='info' value={getStreamClassName(cl)} />
              ))}
        </div>
        <div>Attachments:{sideBarContent.attachments.length === 0 ? 'NA' : ''}</div>
      </Sidebar>

      <Dialog
        header={modalHeader}
        visible={visible}
        position='top'
        style={{width: '50vw'}}
        onHide={() => {
          if (!visible) return
          setVisible(false)
        }}
        draggable={false}
        resizable={false}
      >
        <AddNotice id={noticeId} />
      </Dialog>
    </>
  )
}
