/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link, useLocation} from 'react-router-dom'
import Filter from '../filter'

function ListAssignmentHeader() {
  const location = useLocation()
  return (
    // <div className={`card  mb-6`}>
    <Filter type='Browse Assignments' />
    // </div>
  )
}

export {ListAssignmentHeader}
