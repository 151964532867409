import React, {useEffect, useState, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useLazyAcademicFilterQuery, useLazyFacultyQuery} from '../../Api/allApi'
import {setFilter} from '../../redux/AssignmentSlice'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {memo} from 'react'
import {Dropdown, DropdownChangeEvent} from 'primereact/dropdown'
import {MultiSelect, MultiSelectChangeEvent} from 'primereact/multiselect'
import {FloatLabel} from 'primereact/floatlabel'
import './primeReactCustomCss.css'
import {Chip} from '@mui/material'
import {Button} from 'primereact/button'
import AddIcon from '@mui/icons-material/Add'
import {filter} from 'lodash'
import {cl} from '@fullcalendar/core/internal-common'

const Filter = (props: any) => {
  const {type} = props
  console.log(type)
  const dispatch = useDispatch()

  // filter api
  const [filterTrigger, result] = useLazyAcademicFilterQuery()
  const {isSuccess, data: filterResultData, isFetching} = result
  console.log(filterResultData)
  useEffect(() => {
    filterTrigger({})
  }, [])

  const [filterData, setFilterData]: any = useState({
    stream: [],
    streamId: 0,
    selectedStream: {name: '', code: ''},
    class: [],
    classId: 0,
    selectedClass: {label: '', code: ''},
    semester: [],
    division: [],
    semesterId: 0,
    divisionId: 0,
    selectedSemester: {label: '', value: ''},
    selectedDivision: {name: '', code: ''},
    subject: [],
    subjectId: [],
    selectedSubject: [],
  })
  const [selected, setSelected]: any = useState({
    streamId: 0,
    classId: 0,
    semesterId: 0,
    subjectId: [],
  })
  console.log(filterData)
  console.log(filterData.selectedSemester)
  console.log(filterData.selectedSubject)
  // when api calling is done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      const data = filterResultData
      let stream: any = []
      stream = data.stream?.map((item) => {
        return {code: item?.dbId, name: item?.name}
      })

      const get = localStorage.getItem('filterDataKey')
      let localStorageData = {
        ...filterData,
        stream: stream,
      }
      if (!get) {
        localStorage.setItem('filterDataKey', JSON.stringify(localStorageData))
        setFilterData(localStorageData)
      }
    }
  }, [isSuccess, isFetching, filterResultData, filterData])
  const handleStreamSelect = (e) => {
    let classObj = []
    const classData = filterResultData.class[e.value.code]
    if (classData && classData.length > 0) {
      classObj = classData.map((item) => ({
        code: item.dbId,
        label: item.name,
      }))
      console.log(classObj)
      console.log(filterResultData)
      const tempObj = classObj
        .map((cl: any) => {
          console.log(filterResultData.semester[cl.code])
          return filterResultData.semester[cl.code]
        })
        .filter((item) => item)

      console.log(tempObj)
      const semesterObj =
        tempObj.length &&
        tempObj?.map((item: any) =>
          item?.map((sem) => {
            return {label: sem.name, value: sem.dbId}
          })
        )
      console.log(classObj)
      console.log(semesterObj)
      if (semesterObj) {
        let data = {
          ...filterData,
          streamId: e.value.code,
          selectedStream: {name: e.value.name, code: e.value.code},
          class: classObj,
          classId: 0,
          selectedClass: {label: '', code: ''},
          semester: semesterObj,
          semesterId: 0,
          selectedSemester: {label: '', value: ''},
          divisionId: 0,
          division: [],
          selectedDivision: {name: '', code: ''},
          subject: [],
          subjectId: [],
          selectedSubject: [],
        }
        localStorage.setItem('filterDataKey', JSON.stringify(data))
        setFilterData(data)
      } else {
        let data = {
          ...filterData,
          streamId: e.value.code,
          selectedStream: {name: e.value.name, code: e.value.code},
          class: [],
          classId: 0,
          selectedClass: {label: '', code: ''},
          semester: [],
          semesterId: 0,
          selectedSemester: {label: '', value: ''},
          divisionId: 0,
          division: [],
          selectedDivision: {name: '', code: ''},
          subject: [],
          subjectId: [],
          selectedSubject: [],
        }
        localStorage.setItem('filterDataKey', JSON.stringify(data))
        setFilterData(data)
      }
    } else {
      let data = {
        ...filterData,
        streamId: e.value.code,
        selectedStream: {name: e.value.name, code: e.value.code},
        class: [],
        classId: 0,
        selectedClass: {label: '', code: ''},
        semester: [],
        semesterId: 0,
        selectedSemester: {label: '', value: ''},
        subject: [],
        subjectId: [],
        divisionId: 0,
        division: [],
        selectedDivision: {name: '', code: ''},
        selectedSubject: [],
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    }
  }
  const handleClassAndSemesterSelect = (e) => {
    const classAndSemesterData = semesterFilterOptions.find((cl) =>
      cl.items.some((item) => item.value === e.value)
    )
    console.log(classAndSemesterData)
    console.log(classAndSemesterData.items.find((item) => item.value === e.value))
    // const semesterData = filterResultData.semester[e.value]
    const divisionData = filterResultData.division[classAndSemesterData.code]
    console.log(divisionData)
    let divisionOptions = []

    if (divisionData && divisionData.length > 0) {
      divisionOptions = divisionData.map((item, index) => ({
        code: item.dbId ? item.dbId : index + 1,
        name: item.division,
        enabled: true,
      }))
    }

    let subject = []
    const subjectData = filterResultData.subject[e.value]
    if (subjectData && subjectData.length > 0) {
      subject = subjectData.map((item) => ({
        code: item.dbId,
        name: item.name,
      }))
    }
    const data = {
      ...filterData,
      classId: classAndSemesterData.code,
      selectedClass: {label: classAndSemesterData.label, code: classAndSemesterData.code},
      subject,
      subjectId: [],
      selectedSubject: [],
      semesterId: e.value,
      selectedSemester: classAndSemesterData.items.find((item) => item.value === e.value),
      divisionId: 1,
      division: divisionOptions,
      selectedDivision: {name: '', code: ''},
    }

    localStorage.setItem('filterDataKey', JSON.stringify(data))
    setFilterData(data)
  }
  const handleSemesterSelect = (e) => {
    let subject = []
    const subjectData = filterResultData.subject[e.value]
    if (subjectData && subjectData.length > 0) {
      subject = subjectData.map((item) => ({
        value: item.dbId,
        label: item.name,
      }))
      let data = {
        ...filterData,
        semesterId: e.value,
        selectedSemester: {label: e.label, value: e.value},
        subject: subject,
        subjectId: [],
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    } else {
      let data = {
        ...filterData,
        semesterId: e.value,
        selectedSemester: {label: e.label, value: e.value},
        subject: [],
        subjectId: [],
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    }
  }

  const handleSubjectSelect = (e: MultiSelectChangeEvent) => {
    const subjectIds = e.value.map((item) => item.code)
    const data = {
      ...filterData,
      subjectId: subjectIds,
      selectedSubject: e.value,
    }
    localStorage.setItem('filterDataKey', JSON.stringify(data))
    setFilterData(data)
  }
  const handleSelectAllSubjects = (selectAll) => {
    if (selectAll) {
      // Select all subjects by mapping through subject items and extracting their dbIds
      const allSubjectIds =
        filterResultData?.subject[selected?.semesterId]?.map((item) => item?.dbId) || []
      let data = {
        ...filterData,
        subjectId: allSubjectIds,
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    } else {
      // Deselect all subjects by setting an empty array for subjectId

      let data = {
        ...filterData,
        subjectId: [],
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    }
  }
  const handleDivisionSelect = (e) => {
    let data = {
      ...filterData,
      divisionId: e.value,
      selectedDivision: {label: e.label, value: e.value},
    }
    localStorage.setItem('filterDataKey', JSON.stringify(data))
    setFilterData(data)
  }

  useEffect(() => {
    dispatch(setFilter(selected))
  }, [selected, dispatch])

  useEffect(() => {
    const storedFilterData = localStorage.getItem('filterDataKey')
    if (storedFilterData) {
      setFilterData(JSON.parse(storedFilterData))
    }
  }, [])

  useEffect(() => {
    if (filterData?.streamId > 0) {
      localStorage.setItem('filterDataKey', JSON.stringify(filterData))
    }
  }, [filterData])

  useEffect(() => {
    setSelected({
      ...filterData,
    })
  }, [filterData])

  const semesterFilterOptions = useMemo(() => {
    return filterData.class
      .map((cl, i) => {
        return {...cl, items: filterData.semester[i]}
      })
      .filter((data) => data.items)
  }, [filterData.class, filterData.semester])
  console.log(semesterFilterOptions)
  return (
    <>
      {/* <div className='card mb-6'>
        <div
          className={` ${type === 'logs' && 'justify-content-end'} ${
            type === 'kpis' || type === 'Learner' || type === 'logs' ? '' : ' card-header py-5'
          }`}
        >
          {type !== 'kpis' && type !== 'Learner' && type !== 'logs' ? (
            type === 'Assessment' ? (
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'> {type}</span>
                <span className='text-muted mt-1 fw-semibold fs-7'> Review {type} status</span>
              </h3>
            ) : (
              <h3
                className={`align-items-start flex-column ${type === 'kpis' ? '' : 'card-title'}`}
              >
                <span className='card-label fw-bold fs-3 mb-1'>{type}</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>
                  {type === 'Browse Assignments'
                    ? 'Access all created assignments'
                    : 'Review all assignment submissions'}
                </span>
              </h3>
            )
          ) : (
            ''
          )}
        </div>
      </div> */}

      {type === 'Browse Assignments' && (
        <div className='card align-items-end mb-6 py-5'>
          <Link to='/assignment/AllAssignment/add' className='me-6'>
            <Button
              label='Add Assignment'
              rounded
              severity='info'
              icon={<AddIcon />}
              style={{
                borderRadius: '2rem',
              }}

              // className='align-self-center my-5'
            />
          </Link>
        </div>
      )}
      <div className='card align-items-center rounded-bottom-0'>
        <div
          className={`${
            type === 'kpis' || type === 'Learner' || type === 'logs'
              ? 'd-flex align-items-end'
              : `d-flex card-toolbar justify-content-center w-${
                  type === 'Browse Assignments' ? '100' : '75'
                } mt-10 flex-wrap`
          } `}
        >
          {type === 'Assessment' && (
            <Link to={`/assessments/Add`} className=' me-6 mb-5'>
              <button className='btn btn-sm btn-light-primary '>
                <i className='ki-duotone ki-message-add fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Add Assessment
              </button>
            </Link>
          )}

          {/* New Updated Stream Filter with Prime React */}
          <div className='me-3 col-3'>
            <FloatLabel>
              <Dropdown
                inputId='stream'
                value={
                  filterData?.selectedStream?.name !== '' && filterData?.selectedStream?.code !== ''
                    ? filterData?.selectedStream
                    : null
                }
                onChange={(e: DropdownChangeEvent) => {
                  console.log(e)
                  handleStreamSelect(e)
                }}
                options={filterData.stream}
                optionLabel='name'
                className='w-100'
                panelClassName='dropdownPanel'
              />
              <label htmlFor='stream'>Select Stream</label>
            </FloatLabel>
            {/* end::Menu 2 */}
          </div>

          {/* New Updated Class And Semester with Prime React */}
          <div className='me-3 col-3'>
            <FloatLabel>
              <Dropdown
                inputId='semesterOptions'
                value={
                  filterData?.selectedSemester?.label !== '' &&
                  filterData?.selectedSemester?.value !== ''
                    ? filterData?.selectedSemester.value
                    : null
                }
                onChange={(e: DropdownChangeEvent) => handleClassAndSemesterSelect(e)}
                options={semesterFilterOptions}
                optionGroupTemplate={groupedItemTemplate}
                optionGroupChildren='items'
                optionLabel='label'
                optionGroupLabel='label'
                className='w-100'
                disabled={!filterData?.selectedStream.code}
              />
              <label htmlFor='semesterOptions'>Semester</label>
            </FloatLabel>
          </div>

          {/* subject */}
          {type !== 'kpis' && type !== 'Learner' && type !== 'logs' && (
            <div className='me-3 col-3'>
              <FloatLabel>
                <MultiSelect
                  value={
                    filterData?.selectedSubject.length > 0 ? filterData?.selectedSubject : null
                  }
                  options={filterData.subject}
                  onChange={handleSubjectSelect}
                  optionLabel='name'
                  selectedItemsLabel={
                    filterData.selectedSubject &&
                    filterData.selectedSubject.length &&
                    filterData.subject.length === filterData.selectedSubject.length
                      ? 'All Subjects Selected'
                      : `${filterData.selectedSubject.length} Subjects Selected`
                  }
                  selectAllLabel='Select All Subjects'
                  maxSelectedLabels={0}
                  itemTemplate={subjectOptionsTemplate}
                  className='w-100'
                  inputId='subjectOptions'
                  filter
                  disabled={!filterData?.selectedSemester.value}
                />
                <label htmlFor='subjectOptions'>Subject</label>
              </FloatLabel>
            </div>
          )}
          {filterData.division.length > 0 && (
            // <div>
            <span className='d-flex flex-wrap justify-content-center gap-5 mt-3 w-75'>
              {filterData.division.map((div) => (
                <Chip
                  key={div.code}
                  label={'Division ' + div.name}
                  variant={div.enabled ? 'filled' : 'outlined'}
                  onClick={() => {
                    const index = filterData.division.findIndex(
                      (item) => item.code === div.code && item.name === div.name
                    )
                    let data = {...filterData}
                    data.division[index].enabled = !data.division[index].enabled
                    setFilterData(data)
                  }}
                />
              ))}
            </span>
            // </div>
          )}
          {/* end::Menu */}
        </div>
      </div>
    </>
  )
}

const groupedItemTemplate = (option) => {
  // console.log(option)
  return (
    <div className='d-flex align-items-center'>
      {/* <img
        alt={option.label}
        src='https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png'
        className={`mr-2 flag flag-${option.code.toLowerCase()}`}
        style={{width: '18px'}}
      /> */}
      <div className='fw-bold'>{option.label}</div>
    </div>
  )
}

const subjectOptionsTemplate = (option) => {
  return (
    <div className='d-flex align-items-start'>
      <div>{option.name}</div>
    </div>
  )
}
export default memo(Filter)
