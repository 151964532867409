import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLazyAcademicFilterQuery} from '../../../Api/allApi'
import Select from 'react-select'
import {useDispatch} from 'react-redux'
import {setSubjectFilter} from '../../../redux/CourseSlice'
import {Button} from 'primereact/button'
import AddIcon from '@mui/icons-material/Add'
import {Dropdown, DropdownChangeEvent} from 'primereact/dropdown'
import {FloatLabel} from 'primereact/floatlabel'
import {MultiSelect, MultiSelectChangeEvent} from 'primereact/multiselect'
import {Dialog} from 'primereact/dialog'
import AddAcademicsCourse from './AddAcademicsCourse'
const groupedItemTemplate = (option) => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='fw-bold'>{option.label}</div>
      </div>
      {option.items.length === 0 && (
        <div className='d-flex align-items-start fw-normal mt-4'>
          <div>No Semesters Available for this Class</div>
        </div>
      )}
    </>
  )
}

const semesterOptionsTemplate = (option) => {
  if (!option)
    return (
      <div className='d-flex align-items-start'>
        <div>No Semester Available for this class</div>
      </div>
    )
  return (
    <div className='d-flex align-items-start'>
      <div>{option.label}</div>
    </div>
  )
}
export default function AcademicCourseHeader() {
  const dispatch = useDispatch()
  // Query data for filtering
  const [filterTrigger, result] = useLazyAcademicFilterQuery()
  const {isSuccess, isFetching, data: filterResultData} = result
  const [filterData, setFilterData] = useState<any>({
    stream: [],
    streamId: 0,
    selectedStream: null,
    class: [],
    classId: 0,
    selectedClass: null,
    semester: [],
    semesterId: [],
    selectedSemester: [],
  })

  const [semesterArray, setSemesterArray] = useState<any>([])
  const [visible, setVisible] = useState(false)
  const [modalHeader, setModalHeader] = useState('Add Course')
  // const [courseId, setCourseId] = useState(null)
  const show = () => {
    setVisible(true)
  }
  useEffect(() => {
    filterTrigger({})
  }, [filterTrigger])

  useEffect(() => {
    if (isSuccess && !isFetching) {
      const data = filterResultData
      let stream: any = []
      stream = data.stream?.map((item) => {
        return {code: item?.dbId, name: item?.name}
      })
      setFilterData((v) => {
        return {...v, stream}
      })
    }
  }, [isSuccess, isFetching, filterResultData])

  useEffect(() => {
    if (filterData.streamId > 0) {
      const refinedData = filterData.class.map((cl) => {
        return {
          ...cl,
          items:
            (filterResultData.semester[cl.code] &&
              filterResultData.semester[cl.code].map((sem) => {
                return {label: sem.name, value: sem.dbId}
              })) ||
            [],
        }
      })
      setSemesterArray(refinedData)
    }
  }, [filterData.streamId, filterData.class, filterResultData])

  const handleStreamSelect = (e) => {
    console.log(e.value)
    let classObj = []
    const classData = filterResultData.class[e.value.code]
    console.log(classData)
    if (classData && classData.length > 0) {
      classObj = classData.map((item) => ({
        code: item.dbId,
        label: item.name,
      }))
      // console.log(classObj)
      // const semesterObj =
      //   classObj
      //     .map((cl: any) => filterResultData.semester[cl.code])
      //     .map((item) =>
      //       item.map((sem) => {
      //         return {label: sem.name, value: sem.dbId}
      //       })
      //     )
      console.log(classObj)
      // console.log(semesterObj)
      let data = {
        ...filterData,
        streamId: e.value.code,
        selectedStream: {name: e.value.name, code: e.value.code},
        class: classObj,
        classId: 0,
        selectedClass: null,
        semester: [],
        semesterId: [],
        selectedSemester: [],
      }
      // localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    } else {
      let data = {
        ...filterData,
        streamId: e.value.code,
        selectedStream: {name: e.value.name, code: e.value.code},
        class: [],
        classId: 0,
        selectedClass: null,
        semester: [],
        semesterId: [],
        selectedSemester: [],
      }

      setFilterData(data)
    }
  }

  const handleSemesterSelect = (e: MultiSelectChangeEvent) => {
    const data = {
      ...filterData,
      classId: 0,
      semesterId: e.value,
    }
    console.log(data)
    setFilterData(data)
  }

  useEffect(() => {
    if (filterData?.streamId && filterData?.semesterId.length > 0) {
      dispatch(
        setSubjectFilter({
          // streamId: filterData?.streamId,
          // classId: filterData?.classId,
          semester_id: filterData?.semesterId,
        })
      )
    } else {
      dispatch(
        setSubjectFilter({
          // streamId: filterData?.streamId,
          // classId: filterData?.classId,
          semester_id: [],
        })
      )
    }
  }, [filterData, dispatch])
  console.log(filterData)
  return (
    <>
      {/* <div className={`card  mb-6`}>
       
        <div className='card-header align-tels-center'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Academic Courses</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              Manage Academic Courses and Progress
            </span>
          </h3>
        </div>
      </div> */}
      <div className='card align-items-end mb-6 py-5'>
        <Button
          label='Add Course'
          rounded
          severity='info'
          icon={<AddIcon />}
          style={{
            borderRadius: '2rem',
          }}
          className='me-6'
          onClick={show}
        />
      </div>
      <div className='card flex-md-row rounded-bottom-0'>
        <div className='d-flex card-toolbar justify-content-center w-100 mt-10 flex-wrap'>
          {/* stream */}
          <div className='col-3 me-3 mb-3'>
            {/* <Select
              placeholder='select Stream'
              value={
                filterData?.selectedStream?.label !== '' && filterData?.selectedStream?.value !== ''
                  ? filterData?.selectedStream
                  : null
              }
              options={filterData?.stream}
              onChange={(e) => {
                handleStreamSelect(e)
              }}
            /> */}
            <FloatLabel>
              <Dropdown
                inputId='stream'
                value={filterData?.selectedStream}
                onChange={(e: DropdownChangeEvent) => {
                  console.log(e)
                  handleStreamSelect(e)
                }}
                options={filterData.stream}
                optionLabel='name'
                className='w-100'
                panelClassName='dropdownPanel'
              />
              <label htmlFor='stream'>Select Stream</label>
            </FloatLabel>
          </div>
          {/* class */}
          <div className='col-3 me-3 mb-3 disabled'>
            <FloatLabel>
              <MultiSelect
                name='availableSemesters'
                value={filterData.semesterId}
                options={semesterArray}
                onChange={handleSemesterSelect}
                optionLabel='label'
                optionGroupLabel='label'
                optionGroupChildren='items'
                optionGroupTemplate={groupedItemTemplate}
                selectedItemsLabel={
                  filterData.semesterId &&
                  filterData.semesterId.length &&
                  semesterArray.flatMap((cls) => cls.items).length === filterData.semesterId.length
                    ? 'All Semesters Selected'
                    : `${filterData.semesterId.length} Semesters Selected`
                }
                selectAllLabel='Select All Semesters'
                maxSelectedLabels={0}
                itemTemplate={semesterOptionsTemplate}
                className='w-100'
                inputId='availableSemesters'
                filter
                emptyMessage='No classes or semesters available for selected stream'
                disabled={filterData?.streamId === 0}
              />
              <label htmlFor='availableSemesters'>Select Semesters</label>
            </FloatLabel>
            {/* <Select
              placeholder='select Class'
              value={
                filterData?.selectedClass?.name !== '' && filterData?.selectedClass?.code !== ''
                  ? filterData?.selectedClass
                  : null
              }
              options={filterData?.class}
              isDisabled={filterData?.streamId === 0 ? true : false}
              onChange={(e) => {
                handleClassSelect(e)
              }}
            /> */}
          </div>
          {/* semester */}
          <div className='me-3 mb-3'>
            {/* <Select
              placeholder='select semester'
              value={filterData?.selectedSemester}
              isDisabled={filterData?.classId === 0 ? true : false}
              isMulti
              options={filterData?.semester}
              onChange={(selectedOptions) => {
                handleSemesterSelect(selectedOptions)
              }}
            /> */}
          </div>
        </div>
      </div>
      <Dialog
        header={modalHeader}
        visible={visible}
        position='top'
        style={{width: '50vw'}}
        onHide={() => {
          if (!visible) return
          setVisible(false)
        }}
        draggable={false}
        resizable={false}
      >
        <AddAcademicsCourse id={null} />
      </Dialog>
    </>
  )
}
