import {createSlice} from '@reduxjs/toolkit'
const initialState = {
  // variables
  allEvents: [],
  activeEvent: [],
  pastEvents: [],
  speaker: [],
  organiser: [],
  type: [],
}

export const EventSlice = createSlice({
  name: 'EventSlice',
  initialState,
  reducers: {
    setAllEvents: (state, action) => {
      state.allEvents = action.payload
    },
    setActiveEvents: (state, action) => {
      state.activeEvent = action.payload
    },
    setPastEvents: (state, action) => {
      state.pastEvents = action.payload
    },
    setSpeaker: (state, action) => {
      state.speaker = action.payload
    },
    setOrganiser: (state, action) => {
      state.organiser = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setActiveEvents, setPastEvents, setSpeaker, setOrganiser, setType, setAllEvents} =
  EventSlice.actions
export default EventSlice.reducer
