/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='fa-brands fa-elementor'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/Admission'
        icon='fa-solid fa-person-chalkboard'
        title='Admissions'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Academics</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/academics/allAcademics'
        icon='fa-solid fa-list'
        title='Manage Programs'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/faculty'
        icon='fa-solid fa-chalkboard-user'
        title='Manage Faculty'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/academics/student'
        icon='fa-solid fa-people-group'
        title='Manage Students'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/Courses/Academic/View'
        title='Manage Courses'
        icon='fa-solid fa-book'
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Student Engagement
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/academics/notices'
        icon='fa-solid fa-circle-exclamation  '
        title='Notice'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/assignment/AllAssignment/all'
        title='Assignment'
        icon='fa-solid fa-book'
        // hasBullet={true}
      />
      <SidebarMenuItem
        to='/assignment/Submission/all'
        title='Submissions'
        icon='fa-solid fa-book'
        // hasBullet={false}
      />
      <SidebarMenuItem
        to='/assessments'
        icon='fa-solid fa-list-check'
        title='Assessments'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/achievements'
        icon='fa-solid fa-award'
        title='Achievements'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/Attendance'
        icon='fa-solid fa-clipboard-user'
        title='Attendance'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/events'
        title='Events'
        icon='fa-solid fa-calendar-days'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/blogs/blog/all'
        title='Blogs'
        icon='fa-solid fa-file-pen'
        fontIcon='bi-app-indicator'
        // hasBullet={true}
      />

      {/* <SidebarMenuItemWithSub
        to='/academics'
        title='Academics'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem
          to='/academics/allAcademics'
          icon='fa-solid fa-list'
          title='All Academics'
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/academics/notices'
          icon='fa-solid fa-circle-exclamation  '
          title='Notice'
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/academics/student'
          icon='fa-solid fa-people-group'
          title='Student'
          fontIcon='bi-app-indicator'
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/assignment'
        title='Assignment'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/assignment/AllAssignment/all' title='Browse' hasBullet={true} />
        <SidebarMenuItem to='/assignment/Submission/all' title='Submissions' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/faculty'
        icon='fa-solid fa-chalkboard-user'
        title='Faculty'
        fontIcon='bi-app-indicator'
      /> */}

      {/* <SidebarMenuItemWithSub to='/courses' title='Courses' fontIcon='' icon='fa-solid fa-book'>
        <SidebarMenuItem
          to='/Courses/Academic/View'
          title='Academic Course'
          icon=''
          fontIcon='bi-layers'
          hasBullet={true}
        />
        <SidebarMenuItemWithSub
          to='/other'
          title='Other Course'
          fontIcon=''
          icon='fa-solid fa-list'
        >
          <SidebarMenuItem
            to='/Courses/other/ManageCourse/View'
            title='Manage Course'
            icon=''
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/Courses/other/Author'
            title='Course Author'
            icon=''
            fontIcon='bi-layers'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/Courses/other/Category'
            title='Course Category'
            icon=''
            fontIcon='bi-layers'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub> */}

      {/* <SidebarMenuItem
        to='/assessments'
        icon='fa-solid fa-list-check'
        title='Assessments'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/achievements'
        icon='fa-solid fa-award'
        title='Achievements'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/Attendance'
        icon='fa-solid fa-clipboard-user'
        title='Attendance'
        fontIcon='bi-app-indicator'
      /> */}

      {/* <SidebarMenuItemWithSub
        to='/blogs'
        title='Blog'
        fontIcon='bi-archive'
        icon='fa-solid fa-file-pen'
      >
        <SidebarMenuItem
          to='/blogs/blog/all'
          title='Manage Blogs'
          icon=''
          fontIcon='bi-layers'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Others</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/jobs/all'
        icon='fa-solid fa-briefcase'
        title='Jobs'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/Setting'
        icon='fa-solid fa-gears'
        title='Setting'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}

export {SidebarMenuMain}
