import React, {useEffect, useState, useRef} from 'react'
import './style.css'
import {
  useAddAssignmentMutation,
  useAssignmentDetailsMutation,
  useLazyAcademicFilterQuery,
  useLazyFacultyQuery,
} from '../../../../Api/allApi'
import Select from 'react-select'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import moment from 'moment'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import SaveIcon from '@mui/icons-material/Save'
import {Button} from 'primereact/button'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import UploadFileIcon from '@mui/icons-material/UploadFile'
// Define the main functional component
export default function AddAssignment() {
  // Define a Yup schema for validation
  const AssignmentSchema = Yup.object().shape({
    assignmentName: Yup.string()
      .matches(/^[A-Za-z,0-9 ]*$/, 'Please enter a valid name')
      .max(128, 'Please enter a valid name with a maximum of 40 characters')
      .required('Assignment Name is required')
      .min(2),
    description: Yup.string()
      .matches(/^[\s\S]*$/, 'Please enter a valid Description')
      .max(200, 'Please enter a valid description with a maximum of 200 characters')
      .required('Description is required'),

    assignmentDate: Yup.string()
      .test(
        'is-after-or-equal-to-current-date',
        'Upload Date must be greater than or equal to the current date',
        function (value) {
          // Use moment to compare dates
          return moment(value).isSameOrAfter(moment(), 'day')
        }
      )
      .required('Assignment Date is required'),
    assignmentDueDate: Yup.string()
      .test(
        'is-after-or-equal-to-current-date',
        'Due Date must be greater than or equal to the current date',
        function (value) {
          // Use moment to compare dates
          return moment(value).isSameOrAfter(moment(), 'day')
        }
      )
      .test(
        'is-after-or-equal-to-upload-date',
        'Due Date must be greater than or equal to the Upload Date',
        function (value) {
          const uploadDate = formik.values.assignmentDate
          return moment(value).isSameOrAfter(uploadDate, 'day')
        }
      )
      .required('Due Date is required'),
  })
  const navigate = useNavigate()
  const [searchparams] = useSearchParams()
  // Extract 'assignmentId' from URL query parameters
  const id = searchparams.get('assignmentId')
  const today = new Date().toISOString().split('T')[0]

  // States for loading and preview
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState(false)
  // state for pdf
  const [pdf, setPdf]: any = useState()
  // Initial form values
  const [initialValues, setInitialValues] = useState({
    assignmentName: '',
    description: '',
    assignmentDate: '',
    assignmentDueDate: '',
  })
  // Options for dropdown selects
  const [optionsData, setOptionData]: any = useState({
    stream: [],
    streamId: 0,
    selectedStream: {value: '', label: ''},
    class: [],
    classId: 0,
    selectedClass: {value: '', label: ''},
    semester: [],
    semesterId: 0,
    selectedSemester: {value: '', label: ''},
    subject: [],
    subjectId: 0,
    selectedSubject: {value: '', label: ''},
    faculty: [],
    facultyId: 0,
    selectedFaculty: {value: '', label: ''},
    assignmentDate: '',
    assignmentDueDate: '',
  })
  // Mutation hook for adding an assignment
  const [Assignment, result] = useAddAssignmentMutation()
  const {isSuccess, isLoading, isError, error} = result

  // Mutation hook for listing assignments
  const [assignmentTrigger, assignmentResult] = useAssignmentDetailsMutation()
  const {isSuccess: isAssignment, isLoading: isNot} = assignmentResult
  // Query data for filtering
  const [filterTrigger, fResult] = useLazyAcademicFilterQuery()
  const {isSuccess: isFSuccess, isFetching: isFFetching} = fResult

  // Query data for faculty
  const [facultyTrigger, facultyResult] = useLazyFacultyQuery()
  const {isSuccess: isFaculty, isFetching: isNotFaculty} = facultyResult

  // Trigger filter and faculty data loading
  useEffect(() => {
    filterTrigger({})
    facultyTrigger({})
    if (id) {
      assignmentTrigger(id)
    }
  }, [filterTrigger, facultyTrigger, id, assignmentTrigger])

  // Call when adding assignment API call is successful
  useEffect(() => {
    if (isSuccess && !isLoading) {
      assignmentTrigger(id)
    }
  }, [isSuccess, isLoading, assignmentTrigger, id])
  useEffect(() => {
    if (isAssignment && !isNot && id) {
      setInitialValues({
        assignmentName: assignmentResult?.data?.assignmentName,
        description: assignmentResult?.data?.description,
        assignmentDate: assignmentResult?.data?.assignmentDate,
        assignmentDueDate: assignmentResult?.data?.assignmentDueDate,
      })
      if (assignmentResult?.data?.assignmentLink !== null) {
        setPdf(assignmentResult?.data?.assignmentLink)
        setPreview(true)
      }
      if (isFSuccess && !isFFetching) {
        const updateData = assignmentResult?.data

        if (updateData) {
          const {streamId, classId, semesterId, subjectId, facultyId} = updateData

          if (fResult?.data?.stream?.length !== 0 && fResult?.data?.streamId !== 0) {
            const streamOption = fResult?.data?.stream?.map((item) => {
              return {
                label: item?.name,
                value: item?.dbId,
              }
            })
            const selectedStream = fResult?.data?.stream?.find((item) => item?.dbId === streamId)
            if (selectedStream) {
              const classOption = fResult?.data?.class[streamId]?.map((item) => {
                return {
                  label: item?.name,
                  value: item?.dbId,
                }
              })
              const selectedClass = fResult?.data?.class[streamId]?.find(
                (item) => item?.dbId === classId
              )
              if (selectedClass) {
                const semesterOption = fResult?.data?.semester[classId]?.map((item) => {
                  return {
                    label: item?.name,
                    value: item?.dbId,
                  }
                })
                const selectedSemester = fResult?.data?.semester[classId]?.find(
                  (item) => item?.dbId === semesterId
                )
                if (selectedSemester) {
                  const subjectOption = fResult?.data?.subject[semesterId]?.map((item) => {
                    return {
                      label: item?.name,
                      value: item?.dbId,
                    }
                  })
                  const selectedSubject = fResult?.data?.subject[semesterId]?.find(
                    (item) => item?.dbId === subjectId
                  )
                  const facultyOption = facultyResult?.data?.facultyList?.map((item) => {
                    return {
                      value: item?.dbId,
                      label: (
                        <div className='d-flex align-items-center'>
                          <img
                            src={item?.facultyImg}
                            className='h-40px w-40px me-5'
                            alt={item?.firstName}
                          />
                          <span> {`${item?.firstName} ${item?.lastName}`}</span>
                        </div>
                      ),
                    }
                  })
                  const selectedFaculty = facultyResult?.data?.facultyList?.find(
                    (item) => item?.dbId === facultyId
                  )
                  setOptionData((prevData) => ({
                    ...prevData,
                    stream: streamOption,
                    class: classOption,
                    semester: semesterOption,
                    subject: subjectOption,
                    faculty: facultyOption,
                    selectedStream: {value: selectedStream?.dbId, label: selectedStream?.name},
                    selectedClass: {value: selectedClass?.dbId, label: selectedClass?.name},
                    selectedSemester: {
                      value: selectedSemester?.dbId,
                      label: selectedSemester?.name,
                    },
                    selectedSubject: {value: selectedSubject?.dbId, label: selectedSubject?.name},
                    selectedFaculty: {
                      value: selectedFaculty?.dbId,
                      label: (
                        <div className='d-flex align-items-center'>
                          <img
                            src={selectedFaculty?.facultyImg}
                            className='h-40px w-40px me-5'
                            alt={selectedFaculty?.firstName}
                          />
                          <span>
                            {' '}
                            {`${selectedFaculty?.firstName} ${selectedFaculty?.lastName}`}
                          </span>
                        </div>
                      ),
                    },
                    streamId,
                    classId,
                    semesterId,
                    subjectId,
                    facultyId,
                  }))
                }
              }
            }
          }
        }
      }
    }
  }, [
    isAssignment,
    isNot,
    assignmentResult?.data,
    facultyResult?.data?.facultyList,
    fResult?.data,
    id,
    isFSuccess,
    isFFetching,
  ])
  useEffect(() => {
    if (isSuccess && !isLoading) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: result?.data?.detail,
        showConfirmButton: false,
        timer: 1500,
      })
      navigate('/assignment/AllAssignment/all')
    }
  }, [isSuccess, isLoading, navigate, result?.data?.detail])
  useEffect(() => {
    let stream
    if (isFSuccess && !isFFetching && !id) {
      stream = fResult?.data?.stream?.map((item) => {
        return {value: item?.dbId, label: item?.name}
      })
      setOptionData((prevData) => {
        return {
          ...prevData,
          stream: stream,
        }
      })
    }
  }, [isFSuccess, isFFetching, fResult?.data, id])
  useEffect(() => {
    let faculty: any = []
    if (isFaculty && !isNotFaculty) {
      faculty = facultyResult?.data?.facultyList?.map((item) => {
        return {
          value: item?.dbId,
          label: (
            <div className='d-flex align-items-center'>
              <img src={item?.facultyImg} className='h-40px w-40px me-5' alt={item?.firstName} />
              <span> {`${item?.firstName} ${item?.lastName}`}</span>
            </div>
          ),
        }
      })
      setOptionData((prevData) => {
        return {...prevData, faculty: faculty}
      })
    }
  }, [isFaculty, isNotFaculty, facultyResult])

  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error?.error])

  // Formik configuration for handling form submission
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AssignmentSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          dbId: id ? Number(id) : null,
          assignmentName: values?.assignmentName,
          description: values?.description,
          assignmentDate: moment(values?.assignmentDate).format('YYYY-MM-DD'),
          assignmentDueDate: moment(values?.assignmentDueDate).format('YYYY-MM-DD'),

          facultyId: Number(optionsData?.facultyId),
          streamId: Number(optionsData?.streamId),
          classId: Number(optionsData?.classId),
          semesterId: Number(optionsData?.semesterId),
          subjectId: Number(optionsData?.subjectId),
          assignmentPDF: pdf,
        }
        Assignment(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  // onchange when stream
  const handleStreamSelect = (e) => {
    let classObj = []
    const classData = fResult?.data?.class[e.value]
    if (classData && classData.length > 0) {
      classObj = classData.map((item) => ({
        value: item.dbId,
        label: item.name,
      }))
      let data = {
        ...optionsData,
        streamId: e.value,
        selectedStream: {label: e.label, value: e.value},
        class: classObj,
        classId: 0,
        selectedClass: {value: '', label: ''},
        semester: [],
        semesterId: 0,
        selectedSemester: {value: '', label: ''},
        selectedSubject: {value: '', label: ''},
        subject: [],
        subjectId: 0,
      }
      setOptionData(data)
    } else {
      let data = {
        ...optionsData,
        streamId: e.value,
        selectedStream: {label: e.label, value: e.value},
        class: [],
        classId: 0,
        selectedClass: {value: '', label: ''},
        semester: [],
        semesterId: 0,
        selectedSemester: {value: '', label: ''},
        selectedSubject: {value: '', label: ''},
        subject: [],
        subjectId: 0,
      }
      setOptionData(data)
    }
  }
  // onchange of class
  const handleClassSelect = (e) => {
    const semesterData = fResult?.data?.semester[e.value]

    let semesterOptions = []

    if (semesterData && semesterData.length > 0) {
      semesterOptions = semesterData.map((item) => ({
        value: item.dbId,
        label: item.name,
      }))
    }

    const data = {
      ...optionsData,
      classId: e.value,
      selectedClass: {label: e.label, value: e.value},
      semester: semesterOptions,
      subject: [],
      subjectId: 0,
      semesterId: 0,
      selectedSemester: {value: '', label: ''},
      selectedSubject: {value: '', label: ''},
    }

    setOptionData(data)
  }
  // on change of semester
  const handleSemesterSelect = (e) => {
    let subject = []
    const subjectData = fResult?.data?.subject[e.value]
    if (subjectData && subjectData.length > 0) {
      subject = subjectData.map((item) => ({
        value: item.dbId,
        label: item.name,
      }))
      let data = {
        ...optionsData,
        semesterId: e.value,
        selectedSemester: {label: e.label, value: e.value},
        subject: subject,
        subjectId: 0,
        selectedSubject: {value: '', label: ''},
      }
      setOptionData(data)
    } else {
      let data = {
        ...optionsData,
        semesterId: e.value,
        selectedSemester: {label: e.label, value: e.value},
        subject: [],
        subjectId: 0,
        selectedSubject: {value: '', label: ''},
      }
      setOptionData(data)
    }
  }
  //  onchange of subject
  const handleSubjectSelect = (e: any) => {
    setOptionData({
      ...optionsData,
      subjectId: e.value,
      selectedSubject: {label: e.label, value: e.value},
    })
  }
  // Return the JSX for rendering the component
  const fileInputRef: any = useRef(null)

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }
  // call onchange of files
  const handleFileUpload = (event) => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.onload = (e: any) => {
        const base64Data = e.target.result
        setPdf(base64Data)
        setPreview(true)
      }

      reader.readAsDataURL(file)
    } else {
      alert('Please select a PDF file.')
    }
  }
  return (
    <>
      {isFSuccess && isFaculty ? (
        <div className='card'>
          <div className='card-header'>
            <h2 className='card-title fw-bold fs-2'>
              <Link to={'/assignment/AllAssignment/all'}>
                <button
                  className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  aria-label='Back'
                  data-bs-original-title='Back'
                  data-kt-initialized='1'
                >
                  <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </button>
              </Link>
              {id ? 'Update Assignment' : 'Add Assignment'}
            </h2>
            <div className='card-toolbar'></div>
          </div>
          <div className='card-body '>
            <div className='row'>
              <div className='col-md-6 mb-5'>
                <form className='form' onSubmit={formik.handleSubmit} noValidate>
                  <div className='row'>
                    <div className='col-12 mb-5'>
                      <h5 className='form-label'>Select Stream</h5>
                      <Select
                        value={
                          optionsData?.selectedStream?.label !== '' &&
                          optionsData?.selectedStream?.value !== ''
                            ? optionsData?.selectedStream
                            : null
                        }
                        options={optionsData?.stream}
                        styles={{
                          option: (base) => ({
                            ...base,
                            cursor: 'pointer',
                            margin: '0px',
                            color: '#7e8299',
                            fontWeight: 'bold',
                            background: 'white', // this was the mistake (I needed to remove this)
                            ':hover': {
                              color: '#009ef7',
                              backgroundColor: '#f1faff',
                            },
                          }),
                        }}
                        onChange={(e: any) => {
                          handleStreamSelect(e)
                        }}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <h5 className='form-label'>Select Class</h5>
                      <Select
                        value={
                          optionsData?.selectedClass?.label !== '' &&
                          optionsData?.selectedClass?.value !== ''
                            ? optionsData?.selectedClass
                            : null
                        }
                        options={optionsData?.class}
                        isDisabled={optionsData?.streamId === 0 ? true : false}
                        styles={{
                          option: (base) => ({
                            ...base,
                            cursor: 'pointer',
                            margin: '0px',
                            color: '#7e8299',
                            fontWeight: 'bold',
                            background: 'white', // this was the mistake (I needed to remove this)
                            ':hover': {
                              color: '#009ef7',
                              backgroundColor: '#f1faff',
                            },
                          }),
                        }}
                        onChange={(e: any) => {
                          handleClassSelect(e)
                        }}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <h5 className='form-label'>Select Semester</h5>
                      <Select
                        value={
                          optionsData?.selectedSemester?.label !== '' &&
                          optionsData?.selectedSemester?.value !== ''
                            ? optionsData?.selectedSemester
                            : null
                        }
                        isDisabled={optionsData?.classId === 0 ? true : false}
                        styles={{
                          option: (base) => ({
                            ...base,
                            cursor: 'pointer',
                            margin: '0px',
                            color: '#7e8299',
                            fontWeight: 'bold',
                            background: 'white', // this was the mistake (I needed to remove this)
                            ':hover': {
                              color: '#009ef7',
                              backgroundColor: '#f1faff',
                            },
                          }),
                        }}
                        options={optionsData?.semester}
                        onChange={(e: any) => {
                          handleSemesterSelect(e)
                        }}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <h5 className='form-label'>Select Subject</h5>
                      <Select
                        value={
                          optionsData?.selectedSubject?.label !== '' &&
                          optionsData?.selectedSubject?.value !== ''
                            ? optionsData?.selectedSubject
                            : null
                        }
                        isDisabled={optionsData?.semesterId === 0 ? true : false}
                        options={optionsData?.subject}
                        styles={{
                          option: (base) => ({
                            ...base,
                            cursor: 'pointer',
                            margin: '0px',
                            color: '#7e8299',
                            fontWeight: 'bold',
                            background: 'white', // this was the mistake (I needed to remove this)
                            ':hover': {
                              color: '#009ef7',
                              backgroundColor: '#f1faff',
                            },
                          }),
                        }}
                        onChange={(e: any) => {
                          handleSubjectSelect(e)
                        }}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <h5 className='form-label'>Faculty</h5>
                      <Select
                        value={
                          optionsData?.selectedFaculty?.label !== '' &&
                          optionsData?.selectedFaculty?.value !== ''
                            ? optionsData?.selectedFaculty
                            : null
                        }
                        options={optionsData?.faculty}
                        styles={{
                          option: (base) => ({
                            ...base,
                            cursor: 'pointer',
                            margin: '0px',
                            color: '#7e8299',
                            fontWeight: 'bold',
                            background: 'white', // this was the mistake (I needed to remove this)
                            ':hover': {
                              color: '#009ef7',
                              backgroundColor: '#f1faff',
                            },
                          }),
                        }}
                        filterOption={(option: any, inputValue) =>
                          option?.label?.props?.children[0]?.props?.alt
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                        onChange={(e: any) => {
                          setOptionData({
                            ...optionsData,
                            facultyId: e.value,
                            selectedFaculty: {label: e.label, value: e.value},
                          })
                        }}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>Assignment Name</label>
                      <input
                        type='text'
                        name='assignmentName'
                        {...formik.getFieldProps('assignmentName')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.assignmentName && formik.errors.assignmentName,
                          },
                          {
                            'is-valid':
                              formik.touched.assignmentName && !formik.errors.assignmentName,
                          }
                        )}
                        placeholder='Assignment Name'
                      />
                      {formik.touched.assignmentName && formik.errors.assignmentName ? (
                        <span className='text-danger'>{formik.errors.assignmentName}</span>
                      ) : null}
                    </div>

                    <div className='col-12 mb-5'>
                      <label className='form-label'>Assignment Description</label>
                      <textarea
                        {...formik.getFieldProps('description')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.description && formik.errors.description,
                          },
                          {
                            'is-valid': formik.touched.description && !formik.errors.description,
                          }
                        )}
                        placeholder='Assignment Description'
                        rows={3}
                      ></textarea>
                      {formik.touched.description && formik.errors.description ? (
                        <span className='text-danger'>{formik.errors.description}</span>
                      ) : null}
                    </div>
                    <div className='col-12 mb-5  position-relative'>
                      <input
                        type='date'
                        id='date1'
                        {...formik.getFieldProps('assignmentDate')}
                        className={clsx(
                          'form-control  bg-secondary p-0',
                          {
                            'is-invalid':
                              formik.touched.assignmentDate && formik.errors.assignmentDate,
                          },
                          {
                            'is-valid':
                              formik.touched.assignmentDate && !formik.errors.assignmentDate,
                          }
                        )}
                        min={today}
                      />
                      <div
                        className='position-absolute form-label'
                        style={{top: '25%', left: '40%', pointerEvents: 'none'}}
                      >
                        <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                        {formik.values.assignmentDate
                          ? formik.values.assignmentDate
                          : 'Select Upload Date..'}
                      </div>
                    </div>
                    <div className='col-12 mb-5 position-relative'>
                      <div
                        className='position-absolute form-label'
                        style={{top: '25%', left: '40%', pointerEvents: 'none'}}
                      >
                        <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                        {formik.values.assignmentDueDate
                          ? formik.values.assignmentDueDate
                          : 'Select Due Date..'}
                      </div>
                      <input
                        type='date'
                        id='date2'
                        {...formik.getFieldProps('assignmentDueDate')}
                        className={clsx(
                          'form-control  bg-secondary p-0',
                          {
                            'is-invalid':
                              formik.touched.assignmentDueDate && formik.errors.assignmentDueDate,
                          },
                          {
                            'is-valid':
                              formik.touched.assignmentDueDate && !formik.errors.assignmentDueDate,
                          }
                        )}
                        min={formik.values.assignmentDate}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className='col-md-6 text-center  mb-5'>
                {preview ? (
                  <div className='col-12 w-100 h-100'>
                    <iframe title='PDF Viewer' width='100%' height='100%' src={pdf} frameBorder='0'>
                      Your browser does not support iframes.
                    </iframe>
                  </div>
                ) : (
                  <div
                    className='p-5 w-100 h-100'
                    style={{cursor: 'pointer'}}
                    onClick={handleButtonClick}
                  >
                    <div className='w-100 border  bg-light-secondary border-secondary h-100 border-dashed border-2 rounded'>
                      <div className='d-flex h-100 align-items-center justify-content-center flex-column'>
                        <i
                          className='fa-solid fa-file-pdf text-danger '
                          style={{fontSize: '100px'}}
                        ></i>
                        <span>Click to Upload pdf</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row align-items-center justify-content-start gap-3'>
              <input
                type='file'
                accept='.pdf'
                style={{display: 'none'}}
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
              <Button
                label='Upload PDF'
                rounded
                outlined
                severity='info'
                icon={<UploadFileIcon />}
                style={{
                  borderRadius: '2rem',
                  width: '15%',
                }}
                className='align-self-center my-5'
                onClick={handleButtonClick}
              />
              <Button
                type='submit'
                label={loading ? 'Please Wait...' : id ? 'Update Assignment' : 'Add Assignment'}
                rounded
                severity='info'
                icon={
                  loading ? (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  ) : id ? (
                    <SaveIcon />
                  ) : (
                    <NoteAddIcon />
                  )
                }
                style={{
                  borderRadius: '2rem',
                  width: '15%',
                }}
                className='align-self-center my-5'
                onClick={() => {
                  formik.handleSubmit()
                }}
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  optionsData?.streamId === 0 ||
                  optionsData?.classId === 0 ||
                  optionsData?.subjectId === 0 ||
                  optionsData?.semesterId === 0 ||
                  optionsData?.facultyId === 0 ||
                  !pdf
                    ? true
                    : false
                }
              />
              {/* <div className='col-md-2 mb-5 pe-0'>
                <div className=''></div>
              </div>
              <div className='col-md-2 mb-5 px-0'></div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className='card p-4 pb-0'>
          <div className='row'>
            <div className='col-12 text-center'>
              <i
                className='fa-solid fa-circle-notch fa-spin p-10 text-primary'
                style={{fontSize: '30px', color: 'unset'}}
              ></i>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
