/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import 'rc-tooltip/assets/bootstrap.css'
import {Link} from 'react-router-dom'
import {useDeleteFacultyMutation, useLazyFacultyQuery} from '../../Api/allApi'
import {setFaculty} from '../../redux/FacultySlice'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import Swal from 'sweetalert2'
import {Button} from 'primereact/button'
import AddIcon from '@mui/icons-material/Add'
import {DataTable, DataTableFilterMeta} from 'primereact/datatable'
import {Column, ColumnFilterElementTemplateOptions} from 'primereact/column'
import {Tooltip} from 'primereact/tooltip'
import {FilterMatchMode} from 'primereact/api'
import {InputText} from 'primereact/inputtext'
import {IconField} from 'primereact/iconfield'
import {InputIcon} from 'primereact/inputicon'
import {Dropdown} from 'primereact/dropdown'
import AssignmentIcon from '@mui/icons-material/Assignment'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import CancelIcon from '@mui/icons-material/Cancel'
import {Dialog} from 'primereact/dialog'
import AddFaculty from './AddFaculty'

export default function Faculty() {
  const dispatch = useDispatch()
  const {faculty} = useSelector((state: any) => state.FacultySlice)
  console.log(faculty)
  // all faculty trigger
  const [facultyTrigger, result] = useLazyFacultyQuery()
  const {isSuccess, isFetching} = result
  console.log(result)
  // all faculty trigger
  const [deleteFaculty, deleteResult] = useDeleteFacultyMutation()
  const {isSuccess: isDSuccess, isLoading, isError, error} = deleteResult
  useEffect(() => {
    facultyTrigger({})
  }, [facultyTrigger])

  // call when api calling done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      dispatch(setFaculty(result?.data?.facultyList))
    }
  }, [isSuccess, isFetching, result, dispatch])
  useEffect(() => {
    if (isDSuccess && !isLoading) {
      facultyTrigger({})
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDSuccess, isLoading, facultyTrigger])
  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error?.error])

  const [tableFilters, setTableFilters] = useState<DataTableFilterMeta>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  })

  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [showFacultyDialog, setShowFacultyDialog] = useState(false)
  const [facultyId, setFacultyId] = useState(null)
  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = {...tableFilters}

    // @ts-ignore
    _filters['global'].value = value

    setTableFilters(_filters)
    setGlobalFilterValue(value)
  }
  const tableFields = faculty.map((fac) => {
    return {
      id: fac.dbId,
      info: {
        name: `${fac.firstName} ${fac.lastName}`,
        image: fac.facultyImg,
      },
      title: fac.title,
      education: fac.highestEducation,
      experience: fac.yearsOfExperience,
    }
  })

  const renderHeader = () => {
    return (
      <div className='d-flex justify-content-end'>
        <IconField iconPosition='left'>
          <InputIcon className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </IconField>
      </div>
    )
  }
  const header = renderHeader()
  const facultyBodyTemplate = (value) => {
    const trimmedData = {name: value.info.name, image: value.info.image}
    return (
      <div className='d-flex align-items-center gap-2'>
        <img alt={trimmedData.name} src={trimmedData.image} width='32' />
        <span>{trimmedData.name}</span>
      </div>
    )
  }

  const actionBodyTemplate = (row) => {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <Tooltip target={`.view-faculty${row.id}`} position='top' />
        <Link
          className={`me-5 view-faculty${row.id}`}
          to={`/faculty/View?id=${row?.id}`}
          data-pr-tooltip='View Faculty'
        >
          <AssignmentIcon />
        </Link>

        <Tooltip target={`.edit-faculty${row.id}`} position='top' />
        <Button
          className={`btn btn-sm p-0 me-5 edit-faculty${row.id}`}
          onClick={() => {
            setFacultyId(row.id)
            setShowFacultyDialog(true)
          }}
          // to={`/faculty/View?id=${row?.id}`}
          data-pr-tooltip='Edit Faculty'
        >
          <NoteAltIcon />
        </Button>

        <Tooltip target={`.delete-faculty${row.id}`} position='top' />
        <Button
          className={`btn btn-sm delete-faculty${row.id} p-0`}
          data-pr-tooltip='Delete Faculty'
          onClick={() => {
            Swal.fire({
              title: 'Confirm deletion?',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText:
                "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
              confirmButtonColor: '#009EF7',
              cancelButtonText:
                '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                deleteFaculty(row?.id)
              } else if (result.isDenied) {
                Swal.fire('Record is not deleted', '', 'error')
              }
            })
          }}
        >
          <CancelIcon />
        </Button>
      </div>
    )
  }
  const tableColumns = [
    <Column
      header='Faculty Name'
      field='info'
      sortField='info.name'
      filterField='info.name'
      // filterField='info'
      body={facultyBodyTemplate}
      sortable
      key={'facultyName'}
    />,
    <Column header='Title' field='title' key='title' />,
    <Column header='Highest Education' field='education' key='education' />,
    <Column header='Experience' field='experience' key='experience' />,
    <Column
      header='Actions'
      body={actionBodyTemplate}
      exportable={false}
      style={{minWidth: '12rem'}}
      key='actions'
    />,
  ]

  return (
    <>
      <div className='card align-items-end mb-6 py-5'>
        {/* <Link to='/faculty/Add' className='me-5'>
          <Button
            label='Add Faculty'
            rounded
            severity='info'
            icon={<AddIcon />}
            style={{
              borderRadius: '2rem',
            }}
          />
        </Link> */}
        <Button
          label='Add Faculty'
          rounded
          severity='info'
          icon={<AddIcon />}
          className='me-5'
          style={{
            borderRadius: '2rem',
          }}
          onClick={() => {
            setFacultyId(null)
            setShowFacultyDialog(true)
          }}
        />
      </div>
      <div className='card'>
        <div className='card-body py-3'>
          <DataTable
            value={tableFields}
            header={header}
            showGridlines
            paginator
            rows={20}
            rowsPerPageOptions={[1, 2, 5, 10, 20, 25, 50, 100]}
            size='normal'
            tableStyle={{minWidth: '50rem'}}
            dataKey='id'
            removableSort
            filters={tableFilters}
            paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} assignments'
            scrollable
            globalFilterFields={['info.name', 'title', 'education']}
          >
            {tableColumns}
          </DataTable>
        </div>
      </div>
      <Dialog
        visible={showFacultyDialog}
        header='Add Faculty'
        position='top'
        onHide={() => {
          if (!showFacultyDialog) return
          setShowFacultyDialog(false)
        }}
        draggable={false}
        resizable={false}
        style={{width: '75vw'}}
      >
        <AddFaculty id={facultyId} />
      </Dialog>
    </>
  )
}
