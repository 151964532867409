/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useLazyDashboardQuery} from '../../Api/allApi'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'

const DashboardWrapper = () => {
  // dashboard api
  const [dashboardTrigger, result] = useLazyDashboardQuery()

  const {isSuccess, isFetching, isError, error} = result
  useEffect(() => {
    dashboardTrigger({})
  }, [dashboardTrigger])

  // for data
  const [dashboardData, setDashboardData] = useState({})
  const [alerts, setAlerts] = useState([])
  // when api is fetching data successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      setDashboardData(result?.data)
      if (result?.data?.alerts) {
        let data = result?.data?.alerts
        var hidden = localStorage.getItem('alerts')
        var hidAlert = JSON.parse(hidden)
        var abc = []
        if (hidAlert) {
          data?.map((element) => {
            if (hidAlert.includes(element?.message) === false) {
              abc.push(element)
            }
            return element
          })
          setAlerts(abc)
        } else {
          setAlerts(data)
        }
      }
    }
  }, [isSuccess, isFetching, result?.data])
  // when api returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error?.error])
  const intl = useIntl()

  function hideAlert(data) {
    var students = []
    var stored = JSON.parse(localStorage.getItem('alerts'))
    if (stored) {
      students.push(...stored)
    }
    students.push(data)
    localStorage.setItem('alerts', JSON.stringify(students))
    setAlerts((current) => current.filter((item) => item?.message !== data))
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        {dashboardData?.banner ? (
          <div className='col-12'>
            <div
              className='bg-light-dark  '
              style={{borderRadius: '30px', border: 'solid 2px #BB6166'}}
            >
              <div className='d-flex'>
                <div
                  className='px-5 py-3 text-white'
                  style={{borderRadius: '30px', backgroundColor: '#BB6166'}}
                >
                  Notice
                </div>
                <marquee behavior='scroll' className='px-5 py-3' direction='left'>
                  {dashboardData?.banner}
                </marquee>
              </div>
            </div>
          </div>
        ) : null}
        {alerts.length !== 0 ? (
          <div className='col-12'>
            {alerts === 0 ? (
              <div className='card p-4 pb-0' style={{height: '300px'}}>
                <i
                  className='fa-solid fa-circle-notch fa-spin  text-primary '
                  style={{fontSize: '30px', color: 'unset'}}
                ></i>
              </div>
            ) : (
              <div
                className='card p-4 pb-0'
                style={{height: 'auto', maxHeight: '300px', overflowX: 'auto'}}
              >
                {alerts?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`alert alert-${item?.type === 'info' ? 'primary' : item?.type}`}
                      role='alert'
                    >
                      <div className='d-flex align-items-center justify-content-between'>
                        {item?.message}
                        <h4
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            hideAlert(item?.message)
                          }}
                        >
                          X
                        </h4>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className='row'>
        <div className='col-lg-3 col-md-6 col-12'>
          <div className='d-flex flex-column gap-2'>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Assignment</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/assignment/AllAssignment/all'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    All Assignment
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/assignment/AllAssignment/pending'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Active Assignment
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/assignment/AllAssignment/submitted'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Closed Assignment
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/assignment/AllAssignment/add'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Add Assignment
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Assignment Submission</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/assignment/Submission/all'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    All Assignment
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/assignment/Submission/pending'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Pending Assignment
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/assignment/Submission/submitted'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Submitted Assignment
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/assignment/Submission/graded'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Graded Assignment
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/assignment/Submission/rejected'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Rejected Assignment
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Faculty</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link to='/faculty' className='text-primary fw-semibold fs-6 me-2'>
                    Faculty List
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/faculty/Add' className='text-primary fw-semibold fs-6 me-2'>
                    Add Faculty
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3 col-md-6 col-12'>
          <div className='d-flex flex-column gap-2'>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Academic</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link to='/academics/allAcademics' className='text-primary fw-semibold fs-6 me-2'>
                    All Academics
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/academics/notices' className='text-primary fw-semibold fs-6 me-2'>
                    Notice List
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/academics/notices/notice'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Add Notice
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Blogs</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link to='/blogs/blog/all' className='text-primary fw-semibold fs-6 me-2'>
                    All Articles
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/blogs/blog/trendingArticles'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Trending Articles
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/blogs/blog/latestArticles'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Latest Articles
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/blogs/blog/Add' className='text-primary fw-semibold fs-6 me-2'>
                    Add Blog
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Setting</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/crafted/account/accountInfo'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Account Setting
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/crafted/account/generalSettings'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    General
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/crafted/account/security'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Login & Security
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Assessment</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link to='/assessments' className='text-primary fw-semibold fs-6 me-2'>
                    Assessment List
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/assessments/Add' className='text-primary fw-semibold fs-6 me-2'>
                    Add Assessment
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3 col-md-6 col-12'>
          <div className='d-flex flex-column gap-2'>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Jobs</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link to='/jobs/all' className='text-primary fw-semibold fs-6 me-2'>
                    Jobs List
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/jobs/addJob' className='text-primary fw-semibold fs-6 me-2'>
                    Add Jobs
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Events</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link to='/events/Add' className='text-primary fw-semibold fs-6 me-2'>
                    Add Events
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/events/Active' className='text-primary fw-semibold fs-6 me-2'>
                    Active Events
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/events/past' className='text-primary fw-semibold fs-6 me-2'>
                    Past Events
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/events/Speaker' className='text-primary fw-semibold fs-6 me-2'>
                    Speaker list
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/events/addSpeaker' className='text-primary fw-semibold fs-6 me-2'>
                    Add Speaker
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/events/Organizer' className='text-primary fw-semibold fs-6 me-2'>
                    Organizer List
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Academic Courses</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link to='/Courses/Academic/View' className='text-primary fw-semibold fs-6 me-2'>
                    Academic Course List
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/Courses/Academic/Add' className='text-primary fw-semibold fs-6 me-2'>
                    Add Academic Course
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>

            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Admission</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link to='/Admission/fess' className='text-primary fw-semibold fs-6 me-2'>
                    Admission List
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/Admission/Add' className='text-primary fw-semibold fs-6 me-2'>
                    Add Admission
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3 col-md-6 col-12'>
          <div className='d-flex flex-column gap-2'>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Achievements</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link to='/achievements/Learner' className='text-primary fw-semibold fs-6 me-2'>
                    Learner
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/achievements/Skills' className='text-primary fw-semibold fs-6 me-2'>
                    Skills
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/achievements/Organizations'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Organisation
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/achievements/Publisher' className='text-primary fw-semibold fs-6 me-2'>
                    Publisher
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/achievements/Certification'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Certification
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Attendance</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link to='/attendance/Learner' className='text-primary fw-semibold fs-6 me-2'>
                    Learner
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/attendance/Holiday' className='text-primary fw-semibold fs-6 me-2'>
                    Holiday
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/attendance/Kpis' className='text-primary fw-semibold fs-6 me-2'>
                    Kpis
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/attendance/Leave' className='text-primary fw-semibold fs-6 me-2'>
                    Leave
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/attendance/timeTable' className='text-primary fw-semibold fs-6 me-2'>
                    Time Table
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/attendance/logs' className='text-primary fw-semibold fs-6 me-2'>
                    Attendance Log
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className='card card-flush h-lg-auto mb-6'>
              <div className='card-header pt-5'>
                <h3 className='card-title text-gray-800 fw-bold'>Other Courses</h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-body pt-5'>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/Courses/other/ManageCourse/View'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Course List
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link
                    to='/Courses/other/ManageCourse/Add'
                    className='text-primary fw-semibold fs-6 me-2'
                  >
                    Add Course
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/Courses/other/Author' className='text-primary fw-semibold fs-6 me-2'>
                    Course Author
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex flex-stack'>
                  <Link to='/Courses/other/Category' className='text-primary fw-semibold fs-6 me-2'>
                    Course Category
                  </Link>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                  >
                    <i className='ki-duotone ki-exit-right-corner fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
