import Swal from 'sweetalert2'
import DataTables from '../../DataTable/DataTables'
import {useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {useDeleteSubjectDetailsMutation, useGetSubjectListMutation} from '../../../Api/allApi'
import {useDispatch} from 'react-redux'
import {setAcademic} from '../../../redux/CourseSlice'
import {Link} from 'react-router-dom'
import {Button} from 'primereact/button'
import AddIcon from '@mui/icons-material/Add'
import {DataTable, DataTableFilterMeta} from 'primereact/datatable'
import {Column, ColumnFilterElementTemplateOptions} from 'primereact/column'
import {Tooltip} from 'primereact/tooltip'
import {FilterMatchMode} from 'primereact/api'
import {InputText} from 'primereact/inputtext'
import {IconField} from 'primereact/iconfield'
import {InputIcon} from 'primereact/inputicon'
import {Dropdown} from 'primereact/dropdown'
import AssignmentIcon from '@mui/icons-material/Assignment'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import CancelIcon from '@mui/icons-material/Cancel'
import {Dialog} from 'primereact/dialog'
import AddAcademicsCourse from './AddAcademicsCourse'

const courseBodyTemplate = (value) => {
  return (
    <div className='d-flex justify-content-center align-items-center gap-2'>
      <img alt={value.subjectName} src={value.courseImg} width='32' />
      <span>{value.subjectName}</span>
    </div>
  )
}

export default function AcademicCourse() {
  const {subjectFilter} = useSelector((state: any) => state.CourseSlice)

  const dispatch = useDispatch()
  // get subject list
  const [subjectList, result] = useGetSubjectListMutation()
  const {isSuccess, isLoading} = result
  // Delete Subject list
  const [deleteSubject, deleteResult] = useDeleteSubjectDetailsMutation()
  const {isSuccess: isDelete, isLoading: isNotDelete} = deleteResult
  const [visible, setVisible] = useState(false)
  const [modalHeader, setModalHeader] = useState('Edit Course')
  const [courseId, setCourseId] = useState(null)

  const show = () => {
    setVisible(true)
  }
  useEffect(() => {
    if (subjectFilter?.length !== 0) subjectList(subjectFilter)
  }, [subjectList, subjectFilter])
  useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(setAcademic(result?.data?.subjectList))
      setTotalRows(result?.data?.subjectList?.length)
    }
  }, [isSuccess, isLoading, result?.data?.subjectList, dispatch])

  useEffect(() => {
    if (isDelete && !isNotDelete) {
      if (subjectFilter?.length !== 0) subjectList(subjectFilter)

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDelete, isNotDelete, deleteResult?.data?.detail, subjectList, subjectFilter])
  const {academic} = useSelector((state: any) => state.CourseSlice)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)
  console.log(academic)
  const [tableFilters, setTableFilters] = useState<DataTableFilterMeta>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  })

  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = {...tableFilters}

    // @ts-ignore
    _filters['global'].value = value

    setTableFilters(_filters)
    setGlobalFilterValue(value)
  }

  const actionBodyTemplate = (row) => {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <Tooltip target={`.view-course${row.dbId}`} position='top' />
        <Link to={`/Courses/Academic/ViewDetails?subjectId=${row?.dbId}`}>
          <Button
            data-pr-tooltip='View Course'
            className={`btn btn-sm m-0 me-5 p-0 view-course${row.dbId}`}
            type='button'
            style={{height: 'unset', width: 'unset'}}
          >
            <AssignmentIcon />
          </Button>
        </Link>
        <Tooltip target={`.edit-course${row.dbId}`} position='top' />
        {/* <Link to={`/Courses/Academic/Add?id=${row?.dbId}`}> */}
        <Button
          data-pr-tooltip='Edit Course'
          className={`btn btn-sm m-0 me-5 p-0 edit-course${row.dbId}`}
          type='button'
          style={{height: 'unset', width: 'unset'}}
          onClick={() => {
            // setModalHeader('Add Notice')
            setCourseId(row?.dbId)
            show()
          }}
        >
          <NoteAltIcon />
        </Button>
        {/* </Link> */}
        <Tooltip target={`.delete-course${row.dbId}`} position='top' />
        <Button
          className={`btn btn-sm m-0 p-0 delete-course${row.dbId}`}
          data-pr-tooltip='Delete Course'
          type='button'
          onClick={() => {
            Swal.fire({
              title: 'Confirm deletion?',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText:
                '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
              confirmButtonColor: '#009EF7',
              cancelButtonText:
                '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                deleteSubject(row?.dbId)
              } else if (result.isDenied) {
                Swal.fire('Record is not deleted', '', 'error')
              }
            })
          }}
        >
          <CancelIcon />
        </Button>
      </div>
    )
  }

  const tableColumns = [
    <Column
      field='subjectName'
      sortable
      header='Course Name'
      key='courseName'
      body={courseBodyTemplate}
      style={{minWidth: '12rem'}}
      frozen
      align='center'
    />,
    <Column
      header='Actions'
      body={actionBodyTemplate}
      exportable={false}
      style={{minWidth: '6rem !important'}}
      key='actions'
      align='center'
    ></Column>,
  ]

  const renderHeader = () => {
    return (
      <div className='d-flex justify-content-end'>
        <IconField iconPosition='left'>
          <InputIcon className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </IconField>
      </div>
    )
  }
  const header = renderHeader()

  return (
    <>
      {/* <div className='col-12 mb-5'> */}
      <div className=' card card-custom rounded-top-0'>
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {/* <DataTables
            columns={columns}
            data={academic}
            loading={isLoading}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            page={page}
            perPage={perPage}
          /> */}

          {academic.length > 0 && (
            <DataTable
              value={academic}
              header={header}
              showGridlines
              paginator
              rows={20}
              rowsPerPageOptions={[1, 2, 5, 10, 20, 25, 50, 100]}
              size='normal'
              tableStyle={{minWidth: '50rem'}}
              dataKey='dbId'
              removableSort
              paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
              currentPageReportTemplate='Showing {first} to {last} of {totalRecords} courses'
              scrollable
              globalFilterFields={['subjectName']}
              filters={tableFilters}
            >
              {tableColumns}
            </DataTable>
          )}
          {/* end::Table container */}
        </div>
      </div>
      <Dialog
        header={modalHeader}
        visible={visible}
        position='top'
        style={{width: '50vw'}}
        onHide={() => {
          if (!visible) return
          setVisible(false)
        }}
        draggable={false}
        resizable={false}
      >
        <AddAcademicsCourse id={courseId} />
      </Dialog>
      {/* </div> */}
    </>
  )
}
