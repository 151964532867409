import React, {useEffect, useState} from 'react'
import {Link, useSearchParams} from 'react-router-dom'
import moment from 'moment'
import {WithContext as ReactTags} from 'react-tag-input'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  useAddJobMutation,
  useGetJobsMutation,
  useLazyAdminOrganizationQuery,
} from '../../Api/allApi'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {setOrganization} from '../../redux/achivementSlice'
import ReactSelect from 'react-select'
import Swal from 'sweetalert2'
import {setJob} from '../../redux/JobSlice'
const Job = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  salaryMin: Yup.number()
    .required('Minimum Salary is required')
    .typeError('Minimum Salary must be a number')
    .min(0, 'Minimum Salary must be greater than or equal to 0'),
  salaryMax: Yup.number()
    .required('Maximum Salary is required')
    .typeError('Maximum Salary must be a number')
    .min(0, 'Maximum Salary must be greater than or equal to 0')
    .when('salaryMin', (salaryMin: any, schema) => {
      return schema.test({
        test: function (salaryMax: any) {
          return (
            salaryMax >= salaryMin ||
            this.createError({
              message: 'Maximum Salary must be greater than or equal to Minimum Salary',
              path: 'salaryMax',
            })
          )
        },
      })
    }),
  publishedAt: Yup.string().required('publishedAt is required'),
  currency: Yup.string().required('currency is required'),
  location: Yup.string().required('location is required'),
  contractType: Yup.string().required('contractType is required'),
  experienceLevel: Yup.string().required('experienceLevel is required'),
  summary: Yup.string().required('summary is required'),
})

export default function AddJob() {
  // for data table
  const {job} = useSelector((state: any) => state.JobSlice)
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const {organization} = useSelector((state: any) => state?.achievementSlice)
  const id = searchParams.get('jobId')
  const [array, setArray]: any = useState({
    skills: [],
  })
  // Options for dropdown selects
  const [optionsData, setOptionData] = useState({
    companies: [],
    CompanyId: 0,
    companyName: '',
    selectedCompony: {label: '', value: ''},
  })
  // Add jobApi
  const [addJob, result] = useAddJobMutation()
  const {isSuccess, isLoading} = result
  // get job list api
  const [getJobList, jobResult] = useGetJobsMutation()
  const {isSuccess: isList, isLoading: isNotList, data: jobResultData}: any = jobResult
  //useGetJobsMutation organization api
  const [organizationTrigger, organizationResult] = useLazyAdminOrganizationQuery()
  const {isSuccess: isOrganization, isLoading: isNotOrganization} = organizationResult
  // get organization
  useEffect(() => {
    organizationTrigger({})
    getJobList({})
  }, [organizationTrigger, getJobList])
  // when api calling done successfully
  useEffect(() => {
    if (isOrganization && !isNotOrganization) {
      dispatch(setOrganization(organizationResult?.data?.organisationList))
    }
    if (isList && !isNotList) {
      dispatch(setJob(jobResultData?.jobList))
    }
  }, [
    isOrganization,
    isNotOrganization,
    organizationResult?.data?.organisationList,
    jobResultData?.jobList,
    isList,
    isNotList,
    dispatch,
  ])
  useEffect(() => {
    if (isSuccess && !isLoading) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: result?.data?.detail ? result?.data?.detail : 'Your record has been Added',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isSuccess, isLoading, result?.data?.detail])
  const [initialValues, setInitialValues] = useState({
    publishedAt: '',
    salaryMin: '',
    salaryMax: '',
    title: '',
    contractType: '',
    currency: '',
    location: '',
    experienceLevel: '',
    industry: '',
    summary: '',
  })
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Job,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let skills: any = []
        array?.skills?.forEach((tag) => {
          skills.push(tag.text)
        })
        let payload = {
          dbId: id ? id : null,
          publishedAt: values?.publishedAt,
          title: values?.title,
          salaryMin: values?.salaryMin,
          salaryMax: values?.salaryMax,
          currency: values?.currency,
          organisationId: optionsData?.CompanyId,
          location: values?.location,
          contractType: values?.contractType,
          experienceLevel: values?.experienceLevel,
          skills: skills,
          summary: values?.summary,
        }
        addJob(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
      }
    },
  })
  // skills
  const KeyCodes = {
    comma: 188,
    enter: 13,
  }
  const delimiters = [KeyCodes?.comma, KeyCodes?.enter]
  const handleTagDelete = (i) => {
    setArray({
      ...array,
      skills: array?.skills.filter((tag, index) => index !== i),
    })
  }
  const handleAddition = (tag) => {
    let newValues: any = []
    array?.skills?.forEach((tag) => {
      newValues.push(tag.text)
    })
    setArray({
      ...array,
      skills: [...array?.skills, tag],
    })
    document.getElementById('metaValue')!.style.border = '1px solid rgb(238, 238, 238)'
  }
  // organization
  useEffect(() => {
    if (organization?.length > 0) {
      let arrayList: any = []
      organization.map((item) => {
        arrayList.push({
          value: item?.dbId,
          label: (
            <div className='d-flex align-items-center'>
              <img
                src={item?.organisationImg}
                className='h-40px w-40px me-5'
                alt={item?.organisationName}
              />
              <span>{item?.organisationName}</span>
            </div>
          ),
        })
        return item
      })
      setOptionData((prevOptionsData) => ({
        ...prevOptionsData,
        companies: arrayList,
      }))
    }
  }, [organization])
  useEffect(() => {
    if (id && job?.length > 0) {
      const findData = job?.find((item) => item?.dbId === Number(id))
      setInitialValues(findData)
    }
  }, [id, job])
  return (
    <>
      <div className='card'>
        <div className='card-header align-items-center'>
          <h2 className='card-title fw-bold fs-2'>
            <Link to={'/jobs/all'}>
              <button
                className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                aria-label='Back'
                data-bs-original-title='Back'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </button>
            </Link>
            {`${!id ? 'Add' : 'Edit'} Job`}
          </h2>
        </div>
        <div className='card-body '>
          <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
            <div className='row'>
              <div className='col-12 mb-5'>
                <label className='form-label'>
                  Job Title<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  {...formik.getFieldProps('title')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.title && formik.errors.title},
                    {
                      'is-valid': formik.touched.title && !formik.errors.title,
                    }
                  )}
                  placeholder='Data Scientist'
                />
              </div>
              <div className='col-12 mb-5'>
                <label className='form-label'>
                  Organisation<span className='text-danger'>*</span>
                </label>
                <ReactSelect
                  value={
                    optionsData?.selectedCompony?.label !== '' &&
                    optionsData?.selectedCompony?.value !== ''
                      ? optionsData?.selectedCompony
                      : null
                  }
                  options={optionsData?.companies}
                  styles={{
                    option: (base) => ({
                      ...base,
                      cursor: 'pointer',
                      margin: '0px',
                      color: '#7e8299',
                      fontWeight: 'bold',
                      background: 'white', // this was the mistake (I needed to remove this)
                      ':hover': {
                        color: '#009ef7',
                        backgroundColor: '#f1faff',
                      },
                    }),
                  }}
                  filterOption={(option: any, inputValue) =>
                    option?.label?.props?.children[0]?.props?.alt
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={(e: any) => {
                    setOptionData({
                      ...optionsData,
                      CompanyId: e.value,
                      companyName: e.label?.props?.children[0]?.props?.alt,
                      selectedCompony: {label: e.label, value: e.value},
                    })
                  }}
                />
              </div>
              <div className='col-12 mb-5'>
                <label className='form-label'>
                  Job Description<span className='text-danger'>*</span>
                </label>
                <textarea
                  rows={3}
                  {...formik.getFieldProps('summary')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.summary && formik.errors.summary},
                    {
                      'is-valid': formik.touched.summary && !formik.errors.summary,
                    }
                  )}
                  placeholder='Mention the role overview'
                ></textarea>
              </div>
              <div className='col-md-6 mb-5 position-relative'>
                <label className='form-label'>Publish Date</label>
                <input
                  name='publishedAt'
                  id='publishedAt'
                  type='date'
                  onChange={(e) => {
                    const selectedDate = moment(e.target.value).format('YYYY-MM-DD')
                    formik.setFieldValue('publishedAt', selectedDate)
                  }}
                  className={clsx(
                    'form-control bg-transparent p-1',
                    {
                      'is-invalid': formik.touched.publishedAt && formik.errors.publishedAt,
                    },
                    {
                      'is-valid': formik.touched.publishedAt && !formik.errors.publishedAt,
                    }
                  )}
                />
                <div
                  className='position-absolute form-label'
                  style={{top: '55%', left: '25%', pointerEvents: 'none'}}
                >
                  <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                  {formik?.values?.publishedAt ? formik?.values?.publishedAt : 'Select Start Date'}
                </div>
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>
                  Currency<span className='text-danger'>*</span>
                </label>
                <ReactSelect
                  options={[
                    {value: 'USD', label: 'USD'},
                    {value: 'EUR', label: 'EUR'},
                  ]} // Example options, replace with your currency list
                  styles={{
                    option: (base) => ({
                      ...base,
                      cursor: 'pointer',
                      margin: '0px',
                      color: '#7e8299',
                      fontWeight: 'bold',
                      background: 'white', // this was the mistake (I needed to remove this)
                      ':hover': {
                        color: '#009ef7',
                        backgroundColor: '#f1faff',
                      },
                    }),
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('currency', e.value)
                  }}
                />
                {/* <input
                  type='text'
                  {...formik.getFieldProps('currency')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.currency && formik.errors.currency},
                    {
                      'is-valid': formik.touched.currency && !formik.errors.currency,
                    }
                  )}
                  placeholder='Enter currency'
                /> */}
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>
                  Minimum Salary <span className='text-danger'>*</span>
                </label>
                <input
                  type='number'
                  {...formik.getFieldProps('salaryMin')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.salaryMin && formik.errors.salaryMin},
                    {
                      'is-valid': formik.touched.salaryMin && !formik.errors.salaryMin,
                    }
                  )}
                  placeholder='Enter minimum salary in LPA'
                />
                {formik.touched.salaryMin && formik.errors.salaryMin ? (
                  <span className='text-danger fs-6'>{formik.errors.salaryMin}</span>
                ) : null}
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>
                  Maximum Salary<span className='text-danger'>*</span>
                </label>
                <input
                  type='number'
                  {...formik.getFieldProps('salaryMax')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.salaryMax && formik.errors.salaryMax},
                    {
                      'is-valid': formik.touched.salaryMax && !formik.errors.salaryMax,
                    }
                  )}
                  placeholder='Enter maximum salary in LPA'
                />
                {formik.touched.salaryMax && formik.errors.salaryMax ? (
                  <span className='text-danger fs-6'>{formik.errors.salaryMax}</span>
                ) : null}
              </div>
              <div className='col-md-6 mb-5'>
                <label htmlFor='' className='form-label'>
                  Required Skills<span className='text-danger'>*</span>
                </label>
                <ReactTags
                  tags={array?.skills}
                  id='metaValue'
                  name='skills'
                  classNames={{
                    tagInput: 'mb-3',
                    tagInputField: 'form-control',
                    tag: 'tagsClass fs-6',
                    remove: 'bg-transparent text-primary px-2 remove border-0 fs-4',
                  }}
                  placeholder='Add a skill and press Enter'
                  delimiters={delimiters}
                  handleDelete={handleTagDelete}
                  handleAddition={handleAddition}
                  allowDragDrop={false}
                  handleInputChange={formik.handleChange}
                  inputFieldPosition='top'
                  autocomplete
                />
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>
                  Contract Type<span className='text-danger'>*</span>
                </label>
                <ReactSelect
                  options={[
                    {value: 'Full Time', label: 'Full Time'},
                    {value: 'Part Time', label: 'Part Time'},
                    {value: 'Freelance', label: 'Freelance'},
                  ]} // Example options, replace with your contract types
                  styles={{
                    option: (base) => ({
                      ...base,
                      cursor: 'pointer',
                      margin: '0px',
                      color: '#7e8299',
                      fontWeight: 'bold',
                      background: 'white', // this was the mistake (I needed to remove this)
                      ':hover': {
                        color: '#009ef7',
                        backgroundColor: '#f1faff',
                      },
                    }),
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('contractType', e.value)
                  }}
                />
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>
                  Location<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  {...formik.getFieldProps('location')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.location && formik.errors.location},
                    {
                      'is-valid': formik.touched.location && !formik.errors.location,
                    }
                  )}
                  placeholder='Enter Location'
                />
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>
                  Experience Level<span className='text-danger'>*</span>
                </label>
                <ReactSelect
                  options={[
                    {value: 'Entry Level', label: 'Entry Level'},
                    {value: 'Mid Level', label: 'Mid Level'},
                    {value: 'Mid-Senior Level', label: 'Mid-Senior Level'},
                    {value: 'Senior Level', label: 'Senior Level'},
                    {value: 'Managerial/Supervisory', label: 'Managerial/Supervisory'},
                    {value: 'Executive/Leadership', label: 'Executive/Leadership'},
                  ]} // Example options, replace with your experience levels
                  styles={{
                    option: (base) => ({
                      ...base,
                      cursor: 'pointer',
                      margin: '0px',
                      color: '#7e8299',
                      fontWeight: 'bold',
                      background: 'white', // this was the mistake (I needed to remove this)
                      ':hover': {
                        color: '#009ef7',
                        backgroundColor: '#f1faff',
                      },
                    }),
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('experienceLevel', e.value)
                  }}
                />
              </div>
              <div className='col-12 border-top text-end'>
                <button
                  type='submit'
                  className='btn mt-2 btn-primary'
                  // disabled={formik.isSubmitting || !formik.isValid}
                >
                  <span className='indicator-label d-flex align-items-center gap-2'>
                    <i className={`ki-duotone ki-message-${id ? 'edit' : 'add'} fs-1`}>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                    {id ? 'Update' : 'Save '} Job
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
