/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useMemo} from 'react'
import {useAssignmentListMutation, useDeleteAssignmentMutation} from '../../../../../Api/allApi'
import {useDispatch} from 'react-redux'
import {setAllAssignment} from '../../../../../redux/AssignmentSlice'
import {useSelector} from 'react-redux'
import '../style.css'
import 'rc-tooltip/assets/bootstrap.css'
import {Link, useMatch} from 'react-router-dom'
import Swal from 'sweetalert2'
import {DataTable, DataTableFilterMeta} from 'primereact/datatable'
import {Column, ColumnFilterElementTemplateOptions} from 'primereact/column'
import {MultiSelect, MultiSelectChangeEvent} from 'primereact/multiselect'
import {ProgressBar} from 'primereact/progressbar'
import {FilterMatchMode} from 'primereact/api'
import {InputText} from 'primereact/inputtext'
import {IconField} from 'primereact/iconfield'
import {InputIcon} from 'primereact/inputicon'
import {Tag} from 'primereact/tag'
import {Dropdown} from 'primereact/dropdown'
import AssignmentIcon from '@mui/icons-material/Assignment'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import CancelIcon from '@mui/icons-material/Cancel'
import SourceIcon from '@mui/icons-material/Source'
import {Button} from 'primereact/button'
import {Tooltip} from 'primereact/tooltip'
type Props = {
  className?: string
}

function getAssignmentStatus(assignmentDueDate: string) {
  const today = new Date()
  const dueDate = new Date(assignmentDueDate)

  if (today > dueDate) {
    return 'closed'
  } else {
    return 'active'
  }
}

const subjectBodyTemplate = (value) => {
  return (
    <div className='d-flex align-items-center gap-2'>
      <img
        alt={value.subjectName}
        src={`https://ggstudyabroad.com/wp-content/uploads/2019/09/subject-icon-5.png`}
        width='32'
      />
      <span>{value.subjectName}</span>
    </div>
  )
}

const facultyBodyTemplate = (value) => {
  const trimmedData = {name: value.uploadedBy.name, image: value.uploadedBy.image}
  return (
    <div className='d-flex align-items-center gap-2'>
      <img alt={trimmedData.name} src={trimmedData.image} width='32' />
      <span>{trimmedData.name}</span>
    </div>
  )
}

const submissionProgressBar = (value) => {
  return (
    <ProgressBar
      value={value.currentSubmissions}
      aria-valuemax={value.totalSubmissions}
      displayValueTemplate={() => (
        <>
          {value.currentSubmissions}/<b>{value.totalSubmissions}</b>
        </>
      )}
    ></ProgressBar>
  )
}

const facultyFilterItemTemplate = (option) => {
  const trimmedData = {name: option.name, image: option.image}
  return (
    <div className='d-flex align-items-center gap-2'>
      <img alt={trimmedData.name} src={trimmedData.image} width='32' />
      <span>{trimmedData.name}</span>
    </div>
  )
}

const statusBodyTemplate = (rowData) => {
  return (
    <Tag
      value={rowData.assignmentStatus}
      severity={rowData.assignmentStatus === 'closed' ? 'danger' : 'success'}
    />
  )
}

const statusItemTemplate = (option) => {
  return <Tag value={option} severity={option === 'closed' ? 'danger' : 'success'} />
}
const AllAssignment: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()
  const [tableLoading, setTableLoading] = useState(true)

  // pagination
  const [totalRows, setTotalRows]: any = useState(0)
  const [perPage, setPerPage]: any = useState(20)
  const [page, setPage]: any = useState(1)

  // get data from redux
  const {allAssignment} = useSelector((state: any) => state.assignmentSlice)
  const {filter} = useSelector((state: any) => state.assignmentSlice)
  const {academic} = useSelector((state: any) => state.AcademicSlice)
  // useSelector((state:any)=>state.)
  console.log(academic)
  console.log(filter)
  // const columns: any = [
  //   {
  //     name: 'Assignment Name',
  //     selector: (row: any, index) => {
  //       return (
  //         <div className='  '>
  //           {row?.assignmentName}
  //           <button
  //             className='btn btn-secondary btn-sm px-3 py-1 rounded-circle'
  //             onClick={() => handleToggleDescription(index)}
  //           >
  //             <i className='fa-solid fa-info p-0 fs-7'></i>
  //           </button>
  //           <div
  //             className={`tooltip ${clickedIndex === index ? 'd-block' : 'd-none'}`}
  //             style={{visibility: `${clickedIndex === index ? 'visible' : 'hidden'}`}}
  //           >
  //             {row?.assignmentName} - {row?.description}
  //           </div>
  //         </div>
  //       )
  //     },
  //     center: true,
  //     sortable: true,
  //     width: '400px',
  //   },
  //   {
  //     name: 'Upload Date',
  //     selector: (row: any) => (row?.assignmentDate ? row?.assignmentDate : '-'),
  //     center: true,
  //     sortable: true,
  //     width: '200px',
  //   },
  //   {
  //     name: 'Due Date',
  //     selector: (row: any) => (row?.assignmentDueDate ? row?.assignmentDueDate : '-'),
  //     center: true,
  //     sortable: true,
  //     width: '200px',
  //   },
  //   {
  //     name: 'Maximum Grade ',
  //     selector: (row: any) => (row?.maximumGrade ? row?.maximumGrade : '-'),
  //     center: true,
  //     sortable: true,
  //     width: '200px',
  //   },
  //   {
  //     name: 'Status',
  //     center: true,
  //     sortable: true,
  //     selector: (row: any) => {
  //       return (
  //         <span
  //           className={`badge badge-light-${
  //             row?.assignmentStatus === 'active'
  //               ? 'success'
  //               : row?.assignmentStatus === 'closed'
  //               ? 'danger'
  //               : ''
  //           }`}
  //         >
  //           {row?.assignmentStatus}
  //         </span>
  //       )
  //     },
  //     width: '200px',
  //   },
  //   {
  //     name: '',
  //     style: {
  //       justifyContent: 'end',
  //     },
  //     selector: (row: any) => {
  //       return (
  //         <div className=''>
  //           <a
  //             href={row?.assignmentLink}
  //             target='_blank'
  //             className='me-5'
  //             rel='noopener noreferrer'
  //           >
  //             <button
  //               className='btn btn-sm btn-light-primary'
  //               type='button'
  //               style={{height: 'unset', width: 'unset'}}
  //             >
  //               <i className='fa-solid fa-eye'></i> View
  //             </button>
  //           </a>
  //           <Link to={`/assignment/AllAssignment/add?assignmentId=${row?.dbId}`} className='me-5'>
  //             <button
  //               className='btn btn-sm btn-light-warning'
  //               type='button'
  //               style={{height: 'unset', width: 'unset'}}
  //             >
  //               <i className='ki-duotone ki-message-edit fs-1'>
  //                 <span className='path1'></span>
  //                 <span className='path2'></span>
  //                 <span className='path3'></span>
  //               </i>{' '}
  //               Edit
  //             </button>
  //           </Link>
  //           <button
  //             className='btn btn-sm btn-light-danger '
  //             type='button'
  //             style={{height: 'unset', width: 'unset'}}
  //             onClick={() => {
  //               Swal.fire({
  //                 title: 'Confirm deletion?',
  //                 showDenyButton: false,
  //                 showCancelButton: true,
  //                 confirmButtonText:
  //                   '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
  //                 confirmButtonColor: '#009EF7',
  //                 cancelButtonText:
  //                   '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
  //               }).then((result) => {
  //                 /* Read more about isConfirmed, isDenied below */
  //                 if (result.isConfirmed) {
  //                   deleteAssignment(row?.dbId)
  //                 } else if (result.isDenied) {
  //                   Swal.fire('Record is not deleted', '', 'error')
  //                 }
  //               })
  //             }}
  //           >
  //             <i className='ki-duotone ki-trash-square fs-1'>
  //               <span className='path1'></span>
  //               <span className='path2'></span>
  //               <span className='path3'></span>
  //             </i>{' '}
  //             Delete
  //           </button>
  //         </div>
  //       )
  //     },
  //     sortable: true,
  //   },
  // ]

  // assignment api
  const [assignmentTrigger, result] = useAssignmentListMutation()
  const {isSuccess, isLoading} = result

  // assignment Delete api
  const [deleteAssignment, deleteResult] = useDeleteAssignmentMutation()
  const {isSuccess: isDSuccess, isLoading: isDLoading, isError, error} = deleteResult
  const [statuses] = useState(['closed', 'active'])
  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      if (filter?.streamId > 0 && filter?.classId > 0) {
        let payload = {
          ...filter,
          division: ['A', 'B', 'C', 'D', 'E'],
          assignmentStatus: 'all',
          itemsPerPage: perPage,
          page: page,
        }
        assignmentTrigger(payload)
      }
    }
  }, [assignmentTrigger, filter, perPage, page])

  // when api calling done successfully
  useEffect(() => {
    if (isSuccess && !isLoading) {
      console.log(result.data)
      dispatch(setAllAssignment(result?.data?.assignmentList))
      setTotalRows(result?.data?.payload?.pagination?.total)
      setTableLoading(false)
    }
  }, [isSuccess, isLoading, result, dispatch])
  console.log(allAssignment)
  useEffect(() => {
    if (isDSuccess && !isDLoading) {
      let payload = {
        ...filter,
        assignmentStatus: 'all',
        itemsPerPage: perPage,
        page: page,
      }
      assignmentTrigger(payload)
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDSuccess, isDLoading, deleteResult?.data?.detail, filter, page, perPage, assignmentTrigger])
  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error])

  const [tableFilters, setTableFilters] = useState<DataTableFilterMeta>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    assignmentName: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    subjectName: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    uploadedBy: {value: null, matchMode: FilterMatchMode.IN},
    assignmentStatus: {value: null, matchMode: FilterMatchMode.EQUALS},
  })
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = {...tableFilters}

    // @ts-ignore
    _filters['global'].value = value

    setTableFilters(_filters)
    setGlobalFilterValue(value)
  }

  const facultyListObj = useMemo(() => {
    if (!allAssignment) return {}
    const tempObj = {}
    allAssignment.forEach((assignment) => {
      if (!tempObj[assignment.facultyDetails.facultyId])
        tempObj[assignment.facultyDetails.facultyId] = {
          name: `${assignment.facultyDetails.firstName} ${assignment.facultyDetails.lastName}`,
          image: assignment.facultyDetails.facultyImg,
        }
    })
    return tempObj
  }, [allAssignment])

  const facultyList = Object.values(facultyListObj)
  console.log(facultyList)

  const facultyFilterRowTemplate = (options: ColumnFilterElementTemplateOptions) => {
    console.log(options.value)
    return (
      <MultiSelect
        value={options.value}
        options={facultyList}
        itemTemplate={facultyFilterItemTemplate}
        onChange={(e: MultiSelectChangeEvent) => {
          console.log(e.value)
          options.filterApplyCallback(e.value)
        }}
        optionLabel='name'
        placeholder='Any'
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{minWidth: '14rem'}}
      />
    )
  }
  const renderHeader = () => {
    return (
      <div className='d-flex justify-content-end'>
        <IconField iconPosition='left'>
          <InputIcon className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </IconField>
      </div>
    )
  }

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder='Select One'
        className='p-column-filter'
        showClear
        style={{minWidth: '12rem'}}
      />
    )
  }

  const header = renderHeader()
  const tableValues = allAssignment?.map((assignment) => {
    return {
      ...assignment,
      uploadedBy: {
        name: `${assignment.facultyDetails.firstName} ${assignment.facultyDetails.lastName}`,
        image:
          assignment.facultyDetails.facultyImg &&
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s',
      },
      subjectName: filter?.selectedSubject?.find((sub) => sub.code === assignment.subjectId)?.name,
      assignmentStatus: getAssignmentStatus(assignment.assignmentDueDate),
    }
  })

  const actionBodyTemplate = (row) => {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <Tooltip target={`.view-assignment${row.dbId}`} position='top' />
        <a
          href={row?.assignmentLink}
          target='_blank'
          className={`me-5 view-assignment${row.dbId}`}
          rel='noopener noreferrer'
          data-pr-tooltip='View Assignment'
        >
          {/* <button
            className='btn btn-sm btn-light-primary'
            type='button'
            style={{height: 'unset', width: 'unset'}}
          >
            <i className='fa-solid fa-eye'></i> View
          </button> */}
          <AssignmentIcon />
        </a>
        <Tooltip target={`.edit-assignment${row.dbId}`} position='top' />
        <Link
          to={`/assignment/AllAssignment/add?assignmentId=${row?.dbId}`}
          className={`me-5 edit-assignment${row.dbId}`}
          data-pr-tooltip='Edit Assignment'
        >
          {/* <button
            className='btn btn-sm btn-light-warning'
            type='button'
            style={{height: 'unset', width: 'unset'}}
          >
            <i className='ki-duotone ki-message-edit fs-1'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
            </i>{' '}
            Edit
          </button> */}
          <NoteAltIcon />
        </Link>
        {/* <button
          className='btn btn-sm btn-light-danger '
          type='button'
          style={{height: 'unset', width: 'unset'}}
          onClick={() => {
            Swal.fire({
              title: 'Confirm deletion?',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText:
                '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
              confirmButtonColor: '#009EF7',
              cancelButtonText:
                '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
            }).then((result) => {
        
              if (result.isConfirmed) {
                deleteAssignment(row?.dbId)
              } else if (result.isDenied) {
                Swal.fire('Record is not deleted', '', 'error')
              }
            })
          }}
        >
          <i className='ki-duotone ki-trash-square fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>{' '}
          Delete
        </button> */}
        <Tooltip target={`.view-submissions${row.dbId}`} position='top' />
        <SourceIcon
          className={`me-5 view-submissions${row.dbId}`}
          data-pr-tooltip='View Submissions'
        />
        <Tooltip target={`.delete-assignment${row.dbId}`} position='top' />
        <CancelIcon
          className={`delete-assignment${row.dbId}`}
          onClick={() => {
            Swal.fire({
              title: 'Confirm deletion?',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText:
                '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
              confirmButtonColor: '#009EF7',
              cancelButtonText:
                '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                deleteAssignment(row?.dbId)
              } else if (result.isDenied) {
                Swal.fire('Record is not deleted', '', 'error')
              }
            })
          }}
          data-pr-tooltip='Delete Assignment'
        />
      </div>
    )
  }
  const columns = [
    <Column
      field='assignmentName'
      filterPlaceholder='Search by Assignment name'
      showFilterMenu={false}
      filter
      sortable
      frozen
      header='Assignment Name'
      key='assignmentName'
    />,
    <Column
      field='subjectName'
      sortable
      header='Subject Name'
      body={subjectBodyTemplate}
      key='subjectName'
    />,
    <Column
      filterField='uploadedBy'
      sortable
      sortField='uploadedBy.name'
      showFilterMenu={false}
      filter
      filterElement={facultyFilterRowTemplate}
      header='Uploaded By'
      body={facultyBodyTemplate}
      key='uploadedBy'
    />,
    <Column field='assignmentDate' sortable header='Upload Date' key='assignmentDate' />,
    <Column field='assignmentDueDate' sortable header='Due Date' key='assignmentDueDate' />,
    <Column
      field='currentSubmissions'
      header='Submissions'
      body={submissionProgressBar}
      key='currentSubmissions'
    />,
    <Column
      field='assignmentStatus'
      sortable
      filter
      showClearButton={false}
      header='Status'
      key='assignmentStatus'
      showFilterMenu={false}
      body={statusBodyTemplate}
      filterElement={statusRowFilterTemplate}
    />,
    <Column
      header='Actions'
      body={actionBodyTemplate}
      exportable={false}
      style={{minWidth: '12rem'}}
      key='actions'
    ></Column>,
  ]

  return (
    <>
      <div className={`card ${className} rounded-top-0`}>
        {/* begin::Body */}
        <div className='card-body tooltip-container py-3'>
          {/* begin::Table container */}
          {(filter.streamId && filter.classId && filter.subjectId.length > 0 && (
            <DataTable
              value={tableValues}
              loading={tableLoading}
              showGridlines
              paginator
              rows={20}
              rowsPerPageOptions={[1, 2, 5, 10, 20, 25, 50, 100]}
              size='normal'
              tableStyle={{minWidth: '50rem'}}
              filters={tableFilters}
              filterDisplay='row'
              header={header}
              removableSort
              globalFilterFields={[
                'assignmentName',
                'subjectName',
                'uploadedBy',
                'assignmentStatus',
              ]}
              emptyMessage='No assignments have been added for the selected filters.'
              paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
              currentPageReportTemplate='Showing {first} to {last} of {totalRecords} assignments'
              scrollable
            >
              {columns}
            </DataTable>
          )) ||
            null}
          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}

export {AllAssignment}
