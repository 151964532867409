import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {useLocation} from 'react-router-dom'
// import {MenuInnerWithSub} from './MenuInnerWithSub'
// import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  const {pathname} = useLocation()
  // console.log(pathname)
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
    </>
  )
}
