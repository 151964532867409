import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Admission from '../modules/Admission/Admission'
import Faculty from '../modules/Faculty/Faculty'
import FacultyView from '../modules/Faculty/FacultyView'
import AddFaculty from '../modules/Faculty/AddFaculty'
import AddCompony from '../modules/achievements/components/AddCompony'
import ViewEvents from '../modules/events/components/ViewEvents'
import ViewLearnerAchievement from '../modules/achievements/components/ViewLearnerAchievement'
import ViewCompony from '../modules/achievements/components/ViewCompony'
import CoursePage from '../modules/Courses/ManageCourse/CoursePage'
import LListAssignmentPage from '../modules/assignment/list/ListAssignmentPage'
import Author from '../modules/author/Author'
import CourseAuthor from '../modules/Courses/Author/CourseAuthor'
import CourseCategory from '../modules/Courses/Category/CourseCategory'
import AcademicCoursePage from '../modules/Courses/Academic/AcademicCoursePage'
import AddAssessment from '../modules/assessment/components/AddAssessment'
import ViewAssessments from '../modules/assessment/components/ViewAssessments'
import JobPage from '../modules/jobs/JobPage'
import Academics from '../modules/academics/Academics'
import NoticePage from '../modules/academics/notice/NoticePage'
import AddNotice from '../modules/academics/notice/AddNotice'
import StudentList from '../modules/academics/student/StudentList'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const MyProfilePage = lazy(() => import('../modules/MyProfile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const AssignmentPage = lazy(() => import('../modules/assignment/Subbmision/AssignmentPage'))
  const AssessmentPage = lazy(() => import('../modules/assessment/AssessmentPage'))
  const EventsPage = lazy(() => import('../modules/events/EventsPage'))
  const AchievementsPage = lazy(() => import('../modules/achievements/AchievementsPage'))
  const AttendancePage = lazy(() => import('../modules/attendance/AttendancePage'))
  const BlogPage = lazy(() => import('../modules/Blog/BlogPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />z{/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='/crafted/account/myProfile'
          element={
            <SuspensedView>
              <MyProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='/Admission/*'
          element={
            <SuspensedView>
              <Admission />
            </SuspensedView>
          }
        />
        <Route
          path='/Courses/other/ManageCourse/*'
          element={
            <SuspensedView>
              <CoursePage />
            </SuspensedView>
          }
        />
        <Route
          path='/Courses/other/Author'
          element={
            <SuspensedView>
              <CourseAuthor />
            </SuspensedView>
          }
        />
        <Route
          path='/Courses/other/Category'
          element={
            <SuspensedView>
              <CourseCategory />
            </SuspensedView>
          }
        />
        <Route
          path='/Courses/Academic/*'
          element={
            <SuspensedView>
              <AcademicCoursePage />
            </SuspensedView>
          }
        />
        <Route
          path='/academics/allAcademics'
          element={
            <SuspensedView>
              <Academics />
            </SuspensedView>
          }
        />
        <Route
          path='/academics/notices'
          element={
            <SuspensedView>
              <NoticePage />
            </SuspensedView>
          }
        />
        <Route
          path='/academics/student'
          element={
            <SuspensedView>
              <StudentList />
            </SuspensedView>
          }
        />
        <Route
          path='/assignment/Submission/*'
          element={
            <SuspensedView>
              <AssignmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='/assignment/AllAssignment/*'
          element={
            <SuspensedView>
              <LListAssignmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='events/*'
          element={
            <SuspensedView>
              <EventsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/events/Active/View'
          element={
            <SuspensedView>
              <ViewEvents />
            </SuspensedView>
          }
        />
        <Route
          path='assessments/'
          element={
            <SuspensedView>
              <AssessmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='assessments/add'
          element={
            <SuspensedView>
              <AddAssessment />
            </SuspensedView>
          }
        />
        <Route
          path='/assessments/ViewAssessments'
          element={
            <SuspensedView>
              <ViewAssessments />
            </SuspensedView>
          }
        />
        <Route
          path='achievements/*'
          element={
            <SuspensedView>
              <AchievementsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/achievements/Learner/view'
          element={
            <SuspensedView>
              <ViewLearnerAchievement />
            </SuspensedView>
          }
        />
        <Route
          path='/achievements/Companies/Add'
          element={
            <SuspensedView>
              <AddCompony />
            </SuspensedView>
          }
        />{' '}
        <Route
          path='/achievements/Companies/View'
          element={
            <SuspensedView>
              <ViewCompony />
            </SuspensedView>
          }
        />
        <Route
          path='/jobs/*'
          element={
            <SuspensedView>
              <JobPage />
            </SuspensedView>
          }
        />
        <Route
          path='/blogs/blog/*'
          element={
            <SuspensedView>
              <BlogPage />
            </SuspensedView>
          }
        />
        <Route
          path='/blogs/author'
          element={
            <SuspensedView>
              <Author />
            </SuspensedView>
          }
        />
        <Route
          path='attendance/*'
          element={
            <SuspensedView>
              <AttendancePage />
            </SuspensedView>
          }
        />
        <Route
          path='faculty'
          element={
            <SuspensedView>
              <Faculty />
            </SuspensedView>
          }
        />
        <Route
          path='/faculty/View'
          element={
            <SuspensedView>
              <FacultyView />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/faculty/Add'
          element={
            <SuspensedView>
              <AddFaculty />
            </SuspensedView>
          }
        /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='blog'
          element={
            <SuspensedView>
              <Blog />
            </SuspensedView>
          }
        />
        <Route
          path='blog/viewBlog'
          element={
            <SuspensedView>
              <ViewBlog />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='blog/createBlog'
          element={
            <SuspensedView>
              <CreateBlog />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/Setting'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
