import React, {useEffect, useMemo, useState, useRef} from 'react'
import {AddModal, DiviSionModal} from './modal/AddModal'
import Swal from 'sweetalert2'
import {
  useAcademicCsvUploadMutation,
  useDeleteClassMutation,
  useDeleteSemesterMutation,
  useDeleteStreamMutation,
  useDeleteSubjectMutation,
  useLazyAcademicFilterQuery,
  useLazyAcademicSampleDownloadQuery,
} from '../../Api/allApi'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {setAcademic, setDivision} from '../../redux/AcademicSlice'

import {Accordion, AccordionTab} from 'primereact/accordion'
import {Splitter, SplitterPanel} from 'primereact/splitter'

import {Button} from 'primereact/button'
// import 'primeflex/primeflex.css'
// import 'primeflex/primeflex.css'
import 'primereact/resources/primereact.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import AddIcon from '@mui/icons-material/Add'
import EditNoteIcon from '@mui/icons-material/EditNote'
import DeleteIcon from '@mui/icons-material/Delete'
import MenuIcon from '@mui/icons-material/Menu'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {Panel} from 'primereact/panel'
import {Avatar} from 'primereact/avatar'
import {Message} from 'primereact/message'
import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'

import {cl} from '@fullcalendar/core/internal-common'
import {Dialog} from 'primereact/dialog'

const Academics = () => {
  const dispatch = useDispatch()
  const ref = useRef<Panel>(null)
  // get data from redux
  const {academic, division} = useSelector((state: any) => state.AcademicSlice)
  // delete stream
  const [deleteStream, streamResult] = useDeleteStreamMutation()
  const {isSuccess: isStream, isLoading: isNotStream} = streamResult
  // delete class
  const [deleteClass, classResult] = useDeleteClassMutation()
  const {isSuccess: isClass, isLoading: isNotClass} = classResult
  // delete semester
  const [deleteSemester, semesterResult] = useDeleteSemesterMutation()
  const {isSuccess: isSemester, isLoading: isNotSemester} = semesterResult
  // delete subjects
  const [deleteSubject, subjectResult] = useDeleteSubjectMutation()
  const {isSuccess: isSubject, isLoading: isNotSubject} = subjectResult
  // get sample download
  const [sampleDownload, downloadResult] = useLazyAcademicSampleDownloadQuery()
  const {isSuccess: isDownload, isFetching: isNotDownload} = downloadResult
  // Cas Upload
  const [uploadCsv, uploadResult] = useAcademicCsvUploadMutation()
  const {isSuccess: isUpload, isLoading: isNotUpload, isError} = uploadResult
  // this state stores treeData with children count
  const [updatedTreeData, setUpdatedTreeData] = useState([])
  const [divisionData, setDivisionData]: any = useState([])
  const [selected, setSelected]: any = useState({
    streamId: 0,
    classId: 0,
    semesterId: 0,
    subjectId: 0,
    name: '',
    abbr: '',
  })
  const [showStreamContent, setShowStreamContent] = useState({})
  // this state is for render in jsx element
  const [tableData, setTableData]: any = useState([])

  const [showModal, setShowModal] = useState(false)
  const [modalFlag, setModalFlag] = useState('Add')
  const [modalName, setModalName] = useState('Stream')
  // filter trigger
  const [getData, result] = useLazyAcademicFilterQuery()
  const {isSuccess, isFetching} = result
  useEffect(() => {
    getData({})
  }, [getData])
  // call when api returns 200
  useEffect(() => {
    if (isSuccess && !isFetching) {
      let originalData = result?.data
      console.log(originalData)
      const transformedData = originalData.stream.map((stream) => ({
        id: stream.dbId,
        name: stream.name,
        abbr: stream.abbr,
        children: (originalData.class[stream.dbId] || []).map((classItem) => ({
          id: classItem.dbId,
          name: classItem.name,
          abbr: classItem.abbr,
          children: (originalData.semester[classItem.dbId] || []).map((semester) => ({
            id: semester.dbId,
            name: semester.name,
            abbr: semester.abbr,
            children: (originalData.subject[semester.dbId] || []).map((subject) => ({
              id: subject.dbId,
              name: subject.name,
              abbr: subject.abbr,
              about: subject.about,
            })),
          })),
        })),
      }))
      dispatch(setAcademic(transformedData))
      dispatch(setDivision(originalData.division))
    }
  }, [isSuccess, isFetching, result?.data, dispatch])
  useEffect(() => {
    if (isDownload && !isNotDownload) {
      // console.log(downloadResult)
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Sample Csv Downloaded',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDownload, isNotDownload])
  useEffect(() => {
    if (isUpload && !isNotUpload) {
      // Show success message
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'CSV Uploaded Successfully',
        showConfirmButton: false,
        timer: 1500,
      })
    }
    if (isError) {
      Swal.fire('Error Uploading CSV', '', 'error')
    }
  }, [isUpload, isNotUpload, isError])
  const handleDelete = (nodeId) => {
    Swal.fire({
      title: 'Confirm deletion?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText:
        '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
      confirmButtonColor: '#009EF7',
      cancelButtonText:
        '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteStream(nodeId)
      } else if (result.isDenied) {
        Swal.fire('Record is not deleted', '', 'error')
      }
    })
  }
  const handleDeleteClass = (classId) => {
    Swal.fire({
      title: 'Confirm deletion?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText:
        '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
      confirmButtonColor: '#009EF7',
      cancelButtonText:
        '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteClass(classId)
      } else if (result.isDenied) {
        Swal.fire('Record is not deleted', '', 'error')
      }
    })
  }
  const handleDeleteSemester = (semesterId) => {
    Swal.fire({
      title: 'Confirm deletion?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText:
        '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
      confirmButtonColor: '#009EF7',
      cancelButtonText:
        '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteSemester(semesterId)
      } else if (result.isDenied) {
        Swal.fire('Record is not deleted', '', 'error')
      }
    })
  }
  const handleDeleteSubject = (subjectId) => {
    Swal.fire({
      title: 'Confirm deletion?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText:
        '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
      confirmButtonColor: '#009EF7',
      cancelButtonText:
        '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteSubject(subjectId)
      } else if (result.isDenied) {
        Swal.fire('Record is not deleted', '', 'error')
      }
    })
  }
  const updateCounts = (node) => {
    if (node.children) {
      node.count = node.children.reduce((count, child) => updateCounts(child) + count, 0)
    } else {
      node.count = 0
    }
    return node.count + 1
  }
  useEffect(() => {
    const updatedData = JSON.parse(JSON.stringify(academic)) // Create a deep copy of academic
    updatedData.forEach((stream) => {
      stream.children.forEach((classItem) => {
        classItem.children.forEach((semester) => {
          semester.children.forEach((subject) => {
            updateCounts(semester) // Modify the counts as needed
          })
          // Update the semester count
          const semcount = semester.children.reduce((total, subject) => total + 1, 0)
          semester.count = semcount !== 0 ? semcount : 1
        })
        // Update the class count
        const classcount = classItem.children.reduce((total, semester) => total + semester.count, 0)
        classItem.count = classcount !== 0 ? classcount : 1
      })
      // Update the stream count
      const streamcount = stream.children.reduce((total, classItem) => total + classItem.count, 0)
      stream.count = streamcount !== 0 ? streamcount : 1
    })
    console.log(updatedData)

    // Check if there are no streams, and if so, set the count to 1
    if (updatedData.length === 0) {
      const newStream = {
        id: Date.now(),
        name: 'New Stream',
        children: [],
      }
      updatedData.push(newStream)
    }
    // Update the state with the modified data
    setUpdatedTreeData(updatedData)
  }, [academic])
  console.log(updatedTreeData)
  const streamData = useMemo(() => {
    let tempData = updatedTreeData.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        count: item.count,
        class: item.children,
        abbr: item.abbr,
      }
    })
    tempData = tempData.map((stream: any) => {
      return {
        ...stream,
        class: stream.class.map((cl: any) => {
          return {
            id: cl.id,
            name: cl.name,
            abbr: cl.abbr,
            count: cl.count,
            streamId: stream.id,
            semester: cl.children.map((sem: any) => {
              return {
                id: sem.id,
                name: sem.name,
                count: sem.count,
                abbr: sem.abbr,
                subject: sem.children.map((sub: any) => {
                  return {
                    id: sub.id,
                    name: sub.name,
                    streamId: stream.id,
                    classId: cl.id,
                    semesterId: sem.id,
                    abbr: sub.abbr,
                    about: sub.about,
                  }
                }),
                streamId: stream.id,
                classId: cl.id,
              }
            }),
          }
        }),
      }
    })

    return tempData
  }, [updatedTreeData])

  useEffect(() => {
    if (
      (isStream && !isNotStream) ||
      (isClass && !isNotClass) ||
      (isSemester && !isNotSemester) ||
      (isSubject && !isNotSubject)
    ) {
      getData({})
    }
  }, [
    isStream,
    isNotStream,
    isClass,
    isNotClass,
    isSemester,
    isNotSemester,
    isSubject,
    isNotSubject,
    getData,
  ])
  // function to handle CSV upload
  const handleUploadCsv = (file: any) => {
    console.log(file[0])
    const formData = new FormData()
    // formData.append('upload_file', file)
    formData.append('upload_file', file[0])
    // Send the FormData to the API
    uploadCsv(formData)
  }

  useEffect(() => {
    if (division) {
      // Update the state with colored division data
      setDivisionData(division)
    }
  }, [division])
  console.log(showStreamContent)
  // Function to toggle showing or hiding content for a specific stream
  // const toggleStreamContent = (streamId) => {
  //   setShowStreamContent((prevState) => ({
  //     ...prevState,
  //     [streamId]: !prevState[streamId], // Toggle the state for the specific stream
  //   }))
  //   console.log(tableData[streamId], 'dasdsa')
  // }
  console.log(tableData)

  console.log(streamData)
  console.log(selected)

  const headerTemplate = (options) => {
    const className = `justify-content-space-between`

    return (
      <div className={className}>
        <div className='d-flex align-items-center gap-2'>
          <span className='font-bold'>{options.name}</span>
        </div>
      </div>
    )
  }

  const footerTemplate = (options) => {
    const className = `${options.className} flex flex-wrap align-items-center justify-content-between gap-3`

    return (
      <div className={className}>
        <div className='flex align-items-center gap-2'>
          <Button icon='pi pi-user' rounded text></Button>
          <Button icon='pi pi-bookmark' severity='secondary' rounded text></Button>
        </div>
      </div>
    )
  }
  return isFetching ? (
    <div className='card p-4 pb-0' style={{height: '300px'}}>
      <div className='row h-100 align-items-center justify-content-center'>
        <div className='col-12 text-center'>
          <i
            className='fa-solid fa-circle-notch fa-spin text-primary '
            style={{fontSize: '30px', color: 'unset'}}
          ></i>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className='card mb-5'>
        <div className='card-header justify-content-end'>
          {/* <div className='card-title'>Academics</div> */}
          <div className='card-toolbar'>
            <Button
              label='Upload CSV'
              rounded
              severity='info'
              icon={<UploadIcon />}
              style={{
                borderRadius: '2rem',
              }}
              onClick={() => {
                document?.getElementById('fileInput')?.click()
              }}
              className='align-self-center my-5 me-2'
            />
            <input
              id='fileInput'
              type='file'
              style={{display: 'none'}}
              accept='.csv,.xlsx,.xls'
              onChange={(e) => handleUploadCsv(e?.target?.files)}
            />
            <Button
              label='Sample CSV'
              rounded
              severity='info'
              icon={<DownloadIcon />}
              style={{
                borderRadius: '2rem',
              }}
              onClick={() => {
                sampleDownload({})
              }}
              className='align-self-center my-5 me-2'
            />
          </div>
        </div>
      </div>
      <Accordion>
        {streamData &&
          streamData?.map((item: any, i, arr) => (
            <AccordionTab
              className='bg-white'
              header={
                <span className='d-flex align-items-center justify-content-between w-full'>
                  <span className='text-nowrap fw-bold'>{item.name}</span>
                  <span>
                    <Button
                      rounded
                      text
                      severity='success'
                      icon={<EditNoteIcon fontSize='large' />}
                      className='rounded-circle'
                      // data-kt-menu-trigger='click'
                      // data-kt-menu-placement='bottom-end'
                      // data-kt-menu-flip='top-end'
                      // data-bs-toggle='modal'
                      // data-bs-target='#EditStream'
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setModalFlag('Save')
                        setModalName('Stream')
                        setSelected({
                          streamId: item.id,
                          classId: 0,
                          semesterId: 0,
                          subjectId: 0,
                          name: item?.name,
                          abbr: item?.abbr,
                        })
                        setShowModal(true)
                      }}
                    />
                    <Button
                      rounded
                      text
                      severity='danger'
                      icon={<DeleteIcon />}
                      className='rounded-circle'
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        // Call handleDelete for Stream
                        handleDelete(item.id)
                      }}
                    />
                  </span>
                  {/* <div className='modal fade' id='EditStream' aria-hidden='true'>
                    <AddModal flag='Save' name='Stream' data={selected} />
                  </div> */}
                </span>
              }
              key={item.id}
            >
              <div className='d-flex flex-column'>
                {item.class.length > 0 ? (
                  item.class.map((cl) => {
                    return (
                      <Splitter className='mb-5' key={cl.id}>
                        <SplitterPanel
                          className='d-flex flex-column align-items-center justify-content-center'
                          size={20}
                          minSize={10}
                        >
                          <div>{cl.name}</div>
                          <div>
                            <Button
                              rounded
                              text
                              severity='success'
                              icon={<MenuIcon />}
                              className='rounded-circle'
                              // data-kt-menu-trigger='click'
                              // data-kt-menu-placement='bottom-end'
                              // data-kt-menu-flip='top-end'
                              // data-bs-toggle='modal'
                              // data-bs-target='#EditClass'
                              onClick={() => {
                                setModalFlag('Save')
                                setModalName('Class')
                                setSelected({
                                  streamId: item.id,
                                  classId: cl.id,
                                  semesterId: 0,
                                  subjectId: 0,
                                  name: cl.name,
                                  abbr: cl.abbr,
                                })
                                setShowModal(true)
                              }}
                            />
                            <Button
                              rounded
                              text
                              severity='danger'
                              icon={<DeleteIcon />}
                              className='rounded-circle'
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                // Call handleDeleteChild for Class
                                handleDeleteClass(cl.id)
                              }}
                            />
                          </div>
                          {/* <div className='modal fade' id='EditClass' aria-hidden='true'>
                            <AddModal flag='Save' name='Class' data={selected} />
                          </div> */}
                        </SplitterPanel>
                        <SplitterPanel className='d-flex flex-column'>
                          {cl.semester.length > 0 ? (
                            cl.semester.map((sem, i, arr) => {
                              return (
                                <Splitter
                                  key={sem.id}
                                  style={{
                                    height: '100%',
                                    borderBottom: '1px solid #e5e7eb',
                                    borderRadius: '0',
                                  }}
                                >
                                  <SplitterPanel className='d-flex flex-column align-items-center justify-content-center'>
                                    <div>{sem.name}</div>
                                    <div>
                                      <Button
                                        rounded
                                        text
                                        severity='success'
                                        icon={<EditNoteIcon />}
                                        className='rounded-circle'
                                        // data-kt-menu-trigger='click'
                                        // data-kt-menu-placement='bottom-end'
                                        // data-kt-menu-flip='top-end'
                                        // data-bs-toggle='modal'
                                        // data-bs-target='#EditSemester'
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          setModalFlag('Save')
                                          setModalName('Semester')
                                          setSelected({
                                            streamId: item.id,
                                            classId: cl?.id,
                                            semesterId: sem?.id,
                                            subjectId: 0,
                                            name: sem?.name,
                                            abbr: sem?.abbr,
                                          })
                                          setShowModal(true)
                                        }}
                                      />
                                      <Button
                                        rounded
                                        text
                                        severity='danger'
                                        icon={<DeleteIcon />}
                                        className='rounded-circle'
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          // Call handleDeleteSemester for Semester
                                          handleDeleteSemester(sem.id)
                                        }}
                                      />
                                      {/* <div
                                        className='modal fade'
                                        id='EditSemester'
                                        aria-hidden='true'
                                      >
                                        <AddModal flag='Save' name='Semester' data={selected} />
                                      </div> */}
                                    </div>
                                  </SplitterPanel>
                                  <SplitterPanel className='d-flex flex-column justify-content-center px-3'>
                                    {sem.subject.length > 0 ? (
                                      sem.subject.map((sub, i) => (
                                        <Panel
                                          key={i}
                                          className='my-5'
                                          ref={ref}
                                          toggleable
                                          // header={sub.name}
                                          headerTemplate={(options) => {
                                            return (
                                              <div
                                                className={`${options.className} justify-content-between`}
                                              >
                                                <div className='d-flex align-items-center gap-2'>
                                                  <Avatar
                                                    image='https://ggstudyabroad.com/wp-content/uploads/2019/09/subject-icon-5.png'
                                                    size='large'
                                                    shape='circle'
                                                  />
                                                  <span className='font-bold'>{sub.name}</span>
                                                </div>

                                                {options.togglerElement}
                                              </div>
                                            )
                                          }}
                                          footerTemplate={(options) => (
                                            <div
                                              className={`${options.className} d-flex flex-wrap  justify-content-center gap-3`}
                                            >
                                              <div className='flex align-items-center gap-2'>
                                                <Button
                                                  rounded
                                                  text
                                                  severity='secondary'
                                                  icon={<VisibilityIcon />}
                                                  className='rounded-circle'
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                  }}
                                                />
                                                <Button
                                                  rounded
                                                  text
                                                  severity='success'
                                                  icon={<EditNoteIcon />}
                                                  className='rounded-circle'
                                                  // data-kt-menu-trigger='click'
                                                  // data-kt-menu-placement='bottom-end'
                                                  // data-kt-menu-flip='top-end'
                                                  // data-bs-toggle='modal'
                                                  // data-bs-target='#EditSubject'
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    setModalFlag('Save')
                                                    setModalName('Subject')
                                                    setSelected({
                                                      streamId: item.id,
                                                      classId: cl.id,
                                                      semesterId: sem.id,
                                                      subjectId: sub.id,
                                                      name: sub?.name,
                                                      abbr: sub?.abbr,
                                                    })
                                                    setShowModal(true)
                                                  }}
                                                />
                                                {/* <div
                                                  className='modal fade'
                                                  id='EditSubject'
                                                  aria-hidden='true'
                                                >
                                                  <AddModal
                                                    flag='Save'
                                                    name='Subject'
                                                    data={selected}
                                                  />
                                                </div> */}
                                                <Button
                                                  rounded
                                                  text
                                                  severity='danger'
                                                  icon={<DeleteIcon />}
                                                  className='rounded-circle'
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    handleDeleteSubject(sub.id)
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        >
                                          <p className='m-0'>{sub.about}</p>
                                        </Panel>
                                      ))
                                    ) : (
                                      <div className='align-self-center'>
                                        <Message
                                          text='There are no subjects listed for this semester or term. Use the plus button below to add one.'
                                          className='mx-3 my-4'
                                        />
                                      </div>
                                    )}

                                    {/* Panel 2 */}
                                    <Button
                                      rounded
                                      outlined
                                      severity='success'
                                      icon={<AddIcon />}
                                      className='align-self-center rounded-circle mb-5'
                                      // data-kt-menu-trigger='click'
                                      // data-kt-menu-placement='bottom-end'
                                      // data-kt-menu-flip='top-end'
                                      // data-bs-toggle='modal'
                                      // data-bs-target='#AddSubject'
                                      onClick={() => {
                                        setModalFlag('Add')
                                        setModalName('Subject')
                                        setSelected({
                                          streamId: item.id,
                                          classId: cl.id,
                                          semesterId: sem.id,
                                          name: '',
                                          abbr: '',
                                        })
                                        setShowModal(true)
                                      }}
                                    />
                                    {/* <div className='modal fade' id='AddSubject' aria-hidden='true'>
                                      <AddModal flag='Add' name='Subject' data={selected} />
                                    </div> */}
                                  </SplitterPanel>
                                </Splitter>
                              )
                            })
                          ) : (
                            <div className='align-self-center'>
                              <Message
                                text='The class does not have a semester or term listed, or it may not apply. Consider adding a default semester or term using the below button to enable adding subjects.'
                                className='mx-3 mt-5'
                              />
                            </div>
                          )}

                          <Button
                            label='Add Semester'
                            rounded
                            severity='success'
                            outlined
                            icon={<AddIcon />}
                            style={{
                              borderRadius: '2rem',
                            }}
                            className='align-self-center my-5'
                            // data-kt-menu-trigger='click'
                            // data-kt-menu-placement='bottom-end'
                            // data-kt-menu-flip='top-end'
                            // data-bs-toggle='modal'
                            // data-bs-target='#AddSemester'
                            onClick={() => {
                              setModalFlag('Add')
                              setModalName('Semester')
                              setSelected({
                                streamId: item?.id,
                                classId: cl?.id,
                                semesterId: 0,
                                subjectId: 0,
                                name: '',
                                abbr: '',
                              })
                              setShowModal(true)
                            }}
                          />
                          {/* <div className='modal fade' id='AddSemester' aria-hidden='true'>
                            <AddModal
                              flag='Add'
                              name='Semester'
                              data={{streamId: selected.streamId, classId: selected.classId}}
                            />
                          </div> */}
                        </SplitterPanel>
                      </Splitter>
                    )
                  })
                ) : (
                  <div className='align-self-center'>
                    <Message
                      text='There are no classes available in this stream right now. Consider adding a new class using the below button.'
                      className='mb-4'
                    />
                  </div>
                )}

                <Button
                  label='Add Class'
                  rounded
                  severity='success'
                  icon={<AddIcon />}
                  style={{
                    borderRadius: '2rem',
                  }}
                  className='align-self-center'
                  // data-kt-menu-trigger='click'
                  // data-kt-menu-placement='bottom-end'
                  // data-kt-menu-flip='top-end'
                  // data-bs-toggle='modal'
                  // data-bs-target='#AddClass'
                  onClick={() => {
                    setModalFlag('Add')
                    setModalName('Class')
                    setSelected({
                      streamId: item?.id,
                      classId: 0,
                      semesterId: 0,
                      subjectId: 0,
                      name: '',
                      abbr: '',
                    })
                    setShowModal(true)
                  }}
                />
                {/* <div className='modal fade' id='AddClass' aria-hidden='true'>
                  <AddModal flag='Add' name='Class' data={selected?.streamId} />
                </div> */}
              </div>
            </AccordionTab>
          ))}
      </Accordion>
      <div className='d-flex justify-content-center'>
        <Button
          label='Add Stream'
          rounded
          severity='info'
          icon={<AddIcon />}
          style={{
            borderRadius: '2rem',
          }}
          // data-bs-toggle='modal'
          // data-bs-target='#AddStream'
          // data-kt-menu-trigger='click'
          // data-kt-menu-placement='bottom-end'
          // data-kt-menu-flip='top-end'
          className='align-self-center my-5'
          onClick={() => {
            setModalFlag('Add')
            setModalName('Stream')
            setSelected({
              streamId: 0,
              classId: 0,
              semesterId: 0,
              subjectId: 0,
              name: '',
              abbr: '',
            })
            setShowModal(true)
          }}
        />
      </div>
      {/* <div className='d-flex justify-content-center'>
        <Button
          label='Add Stream Demo'
          rounded
          severity='info'
          icon={<AddIcon />}
          style={{
            borderRadius: '2rem',
          }}
          onClick={() => setShowModal(true)}
          className='align-self-center my-5'
        />
      </div> */}
      {/* <div className='modal fade' id='AddStream' aria-hidden='true'>
        <AddModal flag='Add' name='Stream' data={''} />
      </div> */}
      <Dialog
        header={modalFlag === 'Add' ? `${modalFlag} ${modalName}` : selected.name}
        visible={showModal}
        position='top'
        style={{width: '50vw'}}
        draggable={false}
        resizable={false}
        onHide={() => {
          if (!showModal) return
          setShowModal(false)
        }}
      >
        <AddModal flag={modalFlag} name={modalName} data={selected} />
      </Dialog>
    </>
  )
}

export default Academics
